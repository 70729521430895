@import '../../shared/Vars.scss';

.eq-pf-board {
    background-color: $whiteColor;
    /* padding: 10px 35px 35px 35px;

    @media(max-width:$sm) {
        padding: 10px;
    }*/

    .ant-row {
        display: flex;
    }

    .eq-pf-heading-row {
        @include poppins-24-regular;
        color: $makoColor;
        padding: 0px 0px 0px 0px;
        margin: 10px 0px;
        border-bottom: 1px solid $orangeBorder;


        .eq-pf-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-pf-heading {
                @include poppins-24-regular;
                display: flex;
                flex-direction: row;
                padding: 10px 0px 0px 0px;
                color: $makoColor;

                @media(max-width:$sm) {
                    padding: 5px 0px 0px 0px;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }

    .eq-pb-left {
        padding: 10px 0px;

        @media (max-width:$sm) {
            padding: 0px;
        }


        .eq-pl-row {
            .eq-pl-heading {
                @include poppins-16-semibold;
            }
        }
    }

    .eq-pb-right {
        padding: 25px;

        @media (max-width:$sm) {
            padding: 0px;
        }

        .eq-pb-search-heading {
            @include poppins-16-semibold;
            
            color: $blackColor;
            margin-bottom: 10px;

            @media(max-width:$sm) {
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-pb-invite {
            @include poppins-16-semibold;

            
            color: $blackColor;
            margin-bottom: 10px;

            @media(max-width:$sm) {
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-pb-invite-info {
            @include Inter-16-regular;
            color: $makoColor;
            margin-bottom: 10px;

            @media(max-width:$sm) {
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-pb-search {
            margin: 9px 20px 0px 20px;
        }

        .eq-pb-btn {
            margin: 20px 20px 40px 20px;
            height: 40px;
        }
    }
}