
@import "../../../src/components/shared/Vars.scss";

.eq-public-event-list {
    padding: 15px; // Adds padding to the entire component
  
    .top-row {
    background-color: $whiteBackground;
    min-height: 60px;
    border-radius: 6px;
    padding: 0px 15px;
    margin-bottom: 10px;// Margin for the first row (title and filter dropdown)
    }
    .title {
        margin-bottom: 0.1em;
        font-weight: bold;
        color: #333; // Customize title color
      }
  
    // .event-card-list {
    //   .ant-row {
    //     margin-left: -8px;   // Adjust row margins for better spacing
    //     margin-right: -8px;  // Ant Design's grid uses gutter for spacing, adjust as needed
    //   }
  
    //   .ant-col {
    //     padding-left: 8px;   // Adjust padding to match the row margin
    //     padding-right: 8px;
    //   }
    // }
  
    // Adjust the filter dropdown and title spacing for better alignment
    .filter-select {
      min-width: 300px;
      .ant-select-selector {
        border-radius: $inputBorderRadius;
    }

}
  
   
  }
  