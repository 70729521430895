@import '../../shared/Vars.scss';
.eq-pl-row {
    margin: 0px 20px 20px 0px;

    @media(max-width:$sm) {
        margin: 0px 0px 20px 0px;
    }

    .eq-pl-heading {
        @include poppins-16-semibold;
        // font-family: Poppins;
        // font-style: normal;
        // font-weight: 600;
        // font-size: 16px;
        // line-height: 31px;
        
        color: $blackColor;

        @media(max-width:$sm) {
            }
        }

        .eq-pl-icons {
            .eq-pl-icon {
                width: 24px;
                height: 24px;

                @media(max-width:$sm) {
                    text-align: right;
                }
            }
        }
    }
