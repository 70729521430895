@import "../../shared/Vars.scss";

.eqTrRequest{
    background-color: #FFFFFF; 
    min-height: 100vh; 
    padding: 10px 0px;


    .eq-heading-row {
        padding: 4px 0px 0px 0px;
        margin: 0px 0px 15px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-heading {
                @include poppins-24-regular;
                padding: 0px 0px 0px 0px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

    
    }

    .tab-badge {

      .ant-badge-count {
        background-color: #52575757;
        scale: 0.9;
  
      }
    }
    .ab-tabs {
        // box-shadow: 2px 4px 7px #c6c6c6
      }
    
      .ant-tabs {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding-top: 11px;
            transition: color 0.3s;
    
            &:hover {
              color: #FF8838;
              .ant-badge-count {
                background-color: #FF8838;
              }
            }
          }
          .ant-tabs-tab-btn {
            @include poppins-14-regular-btn;
            &:hover {
              color: #FF8838;
              .ant-badge-count {
                background-color: #FF8838;
              }
            }
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #FF8838;
    
            }
    
            .ant-badge-count {
              background-color: #FF8838;
            }
          }
    
          .ant-tabs-ink-bar {
            background: #FF8838;
          }
        }
      }
}