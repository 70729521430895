@import './../shared/Vars.scss';

.eq-Register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 70%;
    margin: auto;

    .back-btn {
        color: $eqBgColor;
        cursor: pointer;
        font-size: 22px;
    }

    .register-heading {
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 25px;

        .heading {
            @include poppins-36-semibold;
            // text-transform: capitalize;
            color: $eqBlackColor;

        }

        .text {
            @include Inter-18-regular;
            color: $blackMenu;
        }
    }

    .register-steps {
        text-align: center;
        display: flex;
        align-items: center;
        margin: auto;
        width: 100%;

        .ant-steps {
            margin-left: 13%;
            margin-bottom: 75px;

            @media(max-width:$md) {
                margin-left:-22%;
            }
            @media(max-width:$md) {
                display: none;
            }
        }

        .ant-steps-item-icon {
            display: none;
        }

        .ant-steps-item-title {
            display: none;
        }

        .ant-steps-item-tail {
            top: 0;
            width: 100%;
        }

        .ant-steps-item {
            padding: 0;
        }

        .ant-steps-horizontal .ant-steps-item-tail {
            margin-left: 0;
        }


        // .ant-steps-item-finish .ant-steps-item-icon,
        // .ant-steps-item-finish .ant-steps-item-tail::after {
        //     background-color: gray !important;
        //     border-color: gray !important;
        // }

        .ant-steps-item-tail::after {
            height: 9px;
            border-radius: 20px;
            background-color: #afafaf;
            width: 100%;
        }

    }

    .steps-content {
        display: flex;
        justify-content: center;
    }

    .error-show {
        width: 75%;
        margin-left: auto;

        .show {
            display: block
        }

        .invalid {
            color: $red;
        }

        .valid {
            color: $whiteColor;
        }
    }

    .steps-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 15px;

        .eqButton {
            display: flex;
    justify-content: center;
            max-width: 50%;
            width: 50%;

            .ant-btn {
                padding-left: 25px;
                padding-right: 25px;
                // max-width: 100%;
                // width: 80%;

            }
        }
    }

    .eq-signIn {
        .eq-anchor-signIn {
            @include Inter-14-regular;
            color: $eqBgColor;
            .eq-signIn {
                color: $eqBgColor;
                cursor: pointer;
                border-bottom: 1px solid $eqBgColor;
            }
        }
    }


}

.eq-Register .ant-steps-item-finish .ant-steps-item-icon,
.eq-Register .ant-steps-item-finish .ant-steps-item-tail::after {
    background-color: $eqBgColor !important;
    border-color: $eqBgColor !important;
}

.eq-Register .ant-steps-item-process .ant-steps-item-icon,
.eq-Register .ant-steps-item-process .ant-steps-item-tail::after {
    background-color: $eqBgColor !important;
    border-color: $eqBgColor !important;
}

.eq-Register .ant-steps-item-wait .ant-steps-item-icon,
.eq-Register .ant-steps-item-wait .ant-steps-item-tail::after {
    background-color: gray !important;
    border-color: gray !important;
}