@import "../../../shared/Vars.scss";

.create-edit-training {
  .training-tags {
    // margin: 1.5rem 0;
    margin: -1.9rem -0.4rem;
    padding-bottom: 1rem;

    @include responsive(xs) {
      margin: 0;
    }

    img {
      padding: 0px 6px;
    }

    .eq-tag {
      &.ant-tag {
        padding: 5px 10px;
        // border-radius: $tagBorderRadius;
      }
    }
  }
  .section {
    margin: 20px 0px 10px 0px;
  }
  .section-heading {
    // flex:auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    
    color: #000000;
    margin-bottom: 5px;
    border-bottom: 1px dashed #c4c4c4;
  }
  .eq-create-training-container {
    margin: 0px;
    background-color: $whiteColor;
    /*padding: 35px 35px 35px 35px;*/
    padding: 16px 16px 16px 16px;

    @media (max-width: $sm) {
      padding: 10px;
    }

    .create-title {
      padding-bottom: 9px;
      border-bottom: 1px solid $navTxtColor;

      .title-text {
        @include Inter-14-regular;
        font-weight: 300;
        color: $darkBrownBar;
      }

      .action-group {
        display: flex;

        > div {
          :first-child {
            margin-right: 6px;
          }
        }

        @media (max-width: $sm) {
          justify-content: space-between;
          margin: 5px 0px 0px 0px;
        }
      }
    }

    .event-info-input {
      // .ant-col {
      //     margin: 0px;
      //     padding: 0px;
      // }

      .event-type {
      }

      .label {
        margin-top: 25px;
        @include poppins-16-semibold;
        color: $darkBlackZero;
        margin-bottom: 16px;
        
      }

      .classLabel {
        @include poppins-16-semibold;
        color: $darkBlackZero;
        // margin-bottom: 16px;
        
      }
      .description {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 15px;
      }
      .date-time-inputs {
        .eq-input-group {
          // margin-bottom:0;
        }
      }
      .eq-input-group {
        margin-bottom: 9px;

        .input-label {
          @include Inter-14-regular;
          color: $darkBrownText;
          margin-bottom: 0.1rem;
          display: block;
        }

        .name-input {
          min-height: 40px;
          border-radius: $inputBorderRadius;
          padding-left: 18px;
        }

        .switch-input {
          margin-top: 8px;
        }
      }

      /*.event-date-time {
            @media(min-width:$sm) {
                padding-right: 30px;
            }*/

      .divider {
        margin: 20px 0px !important;
        border-bottom: 1px solid #c4c4c4;
      }

      .eq-create-add-card {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }

      .col-2 {
        @media (min-width: $sm) {
          padding-left: 25px;
        }

        .eq-cg-members-header {
          padding: 0px 0px 0px 0px;
          // margin-bottom: 10px;
          margin-bottom: 0px;

          @media (max-width: $sm) {
            .ant-row-end {
              justify-content: center;
            }
          }

          .eq-cg-search {
            margin-bottom: 10px;
          }

          .eq-cg-right {
            justify-content: space-between;
            max-height: 40px;

            .eq-cg-sort {
            }

            @media (max-width: $sm) {
              justify-content: space-between;

              .eq-cg-sort {
                margin-left: 0px;
                padding-left: 0px;

                .ant-select-selector {
                  margin-left: 0px;
                  padding-left: 0px;

                  .ant-select-selection-item {
                    margin-left: 0px;
                    padding-left: 0px;
                  }
                }
              }
            }

            .eq-cg-add {
              margin: 0px;
              padding: 0px;
            }
          }
        }

        .total-price {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          color: #626262;
        }

        .total-price-value {
          text-align: right;
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          align-items: center;
          text-indent: 20px;
          text-transform: capitalize;
          color: #000000;
          opacity: 0.4;

          .value {
            font-weight: normal;
          }
        }

        .paid-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.85);
          text-transform: capitalize;
        }
      }

      .event-payment {
        @media (min-width: $sm) {
          padding-left: 50px;
        }

        .eq-radio-group {
          &.ant-radio-group {
            width: 100%;
          }
        }
      }

      .eq-is-input-heading {
        @include Inter-14-regular;
        // font-weight: normal;
        // font-size: 14px;
        // line-height: 163.19%;
        margin: 10px 0px 10px 0px;
        color: $darkBrownText;
        padding-left: 5px;
      }

      .eq-is-textarea {
        margin-top: 10px;
        padding: 16px;
        min-height: 142px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: $inputBorderRadius;
      }

      .eq-is-textarea:focus {
        box-shadow: none;
      }

      .repeat-time {
        display: flex;
        align-content: center;

        button {
          align-self: center;
          margin-right: 6px;
        }
      }

      .eq-cg-search-header {
        padding: 0px 0px 10px 0px;
      }

      .eq-attendees-list-container {
        margin: 0%;
        padding: 0%;

        .eq-list-header {
          background-color: #fafafa;
        }
      }

      .eq-cg-btn {
        margin: 50px 0px 10px 0px;
        font-size: 18px;
        @media (max-width: $sm) {
          display: block;
          margin: 60px 0px 20px 0px;
        }
        .eq-cg-buttons {
          margin-left: 8px;
          padding: 2px;
          .ant-btn {
            @media (max-width: $sm) {
              width: 100%;
            }
          }
          .eq-save-btn {
            background-color: $purpleBtnClr;
            border-color: $purpleBtnClr;
          }
        }
      }

      // .training-tags{
      //     margin: 2rem;
      //     @include responsive(xs){
      //         margin: 1rem;

      //     }
      //     .eq-tag {
      //         &.ant-tag {
      //             padding: 5px 10px;
      //             border-radius: $tagBorderRadius;
      //         }
      // }}
    }

    .attendee-card-box-student {
    }
    .attendee-card-section {
      padding-top: 15px;
      .attendee-card-box {
        position: relative;

        .empty-card {
          padding: 4rem;
          background-color: lightgray;
          color: gray;
          text-align: center;
          justify-content: center;
        }

        .add-btn-icon {
          // position: absolute;
          // bottom: -65px;
          // right: -50px;
          width: 2.8rem;
          display: flex;
          flex-direction: column;
          z-index: 9;
          overflow: visible;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
          border-radius: $btnBorderRadius;
          background-color: $greenBtnClr;
          padding: 12px;
          margin: auto;
          margin-bottom: 20px;

          @media (max-width: $sm) {
            bottom: -60px;
            right: -17px;
            padding: 10px;
            margin-bottom: 25px;
            float: right;
          }

          img {
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
            cursor: pointer;
            height: 20px;
            width: 20px;
            padding: 5px;
            border-radius: 50%;
            transition: all ease-out 0.3s;

            @media (max-width: $sm) {
              height: 25px;
              width: 25px;
              // padding: 6px;
            }
          }

          &:hover {
            transform: scale(1.03);
          }
        }

        .ant-row {
          &:first-child {
            .eq-btn-icon {
              display: none;
            }
          }
        }
      }
    }

    .eq-card-header {
      .header-label {
        text-transform: capitalize;
      }

      .eq-tag {
        &.ant-tag {
          padding: 5px 10px;
          // border-radius: $tagBorderRadius;
        }
      }
    }
  }


  .dynamic-q-container{

    .add-buttons {
      margin-bottom: 16px;
    }
    
   .question-list {
  .table-header {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    gap: 16px;
    padding: 8px 0;
    font-weight: bold;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 16px;

    .action-cell {
      text-align: center;
    }
  }

  .question-item {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    gap: 16px;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #d9d9d9;

    .question-cell {
      font-weight: bold;
    }

    .options-cell {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .option-tag {
        margin: 2px;
      }
    }

    .actions-cell {
      display: flex;
      justify-content: center;
      align-items: center;

      .delete-icon {
        cursor: pointer;
      }
    }
  }
}

    
  }
}
