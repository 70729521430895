@import '../../../../src/components/shared/Vars.scss';


.eq-event-alternative{
 
 .eq-button-link{
    padding: 0;
    @include poppins-14-semibold-btn;
    color: $orangeColor;

 }   


 .multiple-class-Row{
   align-items: center;
  //  justify-content: space-between;

 }

 .switch-container {
   display: flex;
   align-items: center;
   gap: 10px; /* Adjust the gap value as needed */
 }

 .question-card{
   border-left: 5px solid $orangeColor;
   width: 95%;
   border-radius: 6px;
 
   .ant-card-head{
    padding: 0px 7px 0px 0px;
      min-height: 25px;
      border-bottom: none;
      .ant-card-extra{
         padding: 7px 0px 0px;
      }
   }
   .ant-card-body{
      padding: 5px 20px 20px;
   }
 }

 .bordered-col {
   border-left: 2px solid #d9d9d9; /* Left border */
   padding-left: 15px; /* Add some padding to separate the content from the border */
 }

 .alternative-classes{
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
}

.refund-confirm-modal{
  .ant-modal-close-x {
    width: 30px;
    height: auto;
  }



}