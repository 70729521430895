@import '../shared/Vars.scss';


.eq-forgot-password {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;

    .error-message {
        color: red;
    }
    
    .success-message {
        color: green;
    }

    .ant-row {
        display: flex;
    }

    @media (max-width: $md) {
        left: 10%;
    }

    .password-criteria {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    
        .valid {
            color: green;
    
            // Style for the check mark icon
            .anticon-check {
                color: green;
            }
        }
    
        .invalid {
            color:$red;
    
            .dot {
                color: $red;
            }
        }
    }
    .eq-form-wrapper {
        width: 50%;
        // overflow: auto;

        @media (max-width: $sm) {
            width: 100%;
        }
        @media (min-width: $xl) {
            width: 45%;
        }

        .label {
            @include Inter-14-regular;
            color: $blackMenu;
            margin-bottom: 4px;
        }

        .ant-form {
            padding: 0 11% 2% 11%;
        }

        .eq-form-item-wrapper {
            margin-bottom: 14px;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum', "tnum";
            margin-bottom: 24px;
            vertical-align: top;
            flex-direction: column;

            
        }

        .remember-text {
            display: flex;
            justify-content: space-between;
            margin-top: -12px;

            .check-box {
                @include Inter-12-regular;
                color: $blackMenu;
                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: $eqBgColor;
                    border-color: $eqBgColor;
                }
                .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
                    border-color: $eqBgColor;
                }

            }
            .eq-anchor{
                @include Inter-12-regular;
                color: $eqBgColor;
            }
        }
        .eq-anchor-register {
            @include Inter-14-regular;
            color: $eqBgColor;
            .eq-register {
                color: $eqBgColor;
                cursor: pointer;
                border-bottom: 1px solid $eqBgColor;
            }
        }

        .ant-image {
            display: flex;
            justify-content: center;
            padding-left: 22px;

            .ant-image-img {
                width: 230px;
                align-content: center;
            }
        }

        .ant-space a {
            color: $eqWhiteColor;
        }

        .full-width {
            width: 100%;
        }

        .text-padded-center {
            padding: 1% 8%;
            display: flex;
            justify-content: center;
        }

        .eq-logo {
            width: 333px !important;
            height: 59px;
        }

        .custom-title {
            @include poppins-36-semibold;
            // text-transform: capitalize;
            color: $eqBlackColor;
            margin-left: 10%;
            padding-top: 40px;
            padding-bottom: 25px;
        }

        .eq-sign-btn {
            padding-left: 25px;
            padding-right: 25px;
            .ant-btn {
                // width: 100%;
                // border-radius: 0
            }
        }
    }


    .login-error {
        color: $redColor;
        font-weight: 500;
        font-size: 16px;
    }

    .resend-email {
        color: $CTALightColor;
        font-weight: 700;
        font-size: 16px;
        padding-left: 6px;
        cursor: pointer;
        text-decoration: underline;
    }

}

@include placeholder {
    color: $eqWhiteColor;
    font-size: 12px;
    font-weight: 400;
}

.register-popup {
    background: transparent;
    .ant-modal-content {
        background: transparent;

        .ant-modal-close {
            color: #FF8838;
        }
        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 20px;
            background: rgb(112 112 112 / 90%);
            .icon {
                font-size: 40px;
                color: #FF8838;
                margin-right: 7px;

            }

            .popup-heading {
                font-size: large;
                font-weight: 600;
                letter-spacing: 1.4px;
                line-height: 2;
                border-bottom: 1px #FF8838 solid;
                margin-bottom: 17px;
                color: white;
            }

            .register-popup-text {
                text-align: center;
                line-height: 2;
                color: white;
            }
        }

        .ant-modal-footer {
            display: none;
        }
    }

}
