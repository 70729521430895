@import '../../shared/Vars.scss';

.student-product-List {
    width: 100%;

    .product-status {
        .unpaid-product {
            color: $unPaid;
        }

        .paid-product {
            color: $paid;
        }
    }

    .product-action {
        .action {
            color: $action;
            @include Inter-14-500;
            text-decoration: underline;
            cursor: pointer;
            border: none;
            box-shadow: none;

            .action-btn-text {
                text-decoration: underline;
            }
        }
    }
}