@import "../../shared/Vars.scss";

.eq-calendar {
  scroll-behavior: smooth;
  scrollbar-color: var(--fc-today-bg-color);

  .ant-picker-cell {
    .ant-picker-cell-inner {
      display: inline-flex;
      margin-left: 10px;
      align-items: center;
    }
  }

  .ant-badge {
    display: flex;
    margin: 0px -15px 0px 0px;
    padding: 0px 0px 0px 5px;
  }

  .ant-picker-calendar-date-value {
    margin-left: 3px;
  }

  .ant-picker-cell-in-view {
    &.ant-picker-cell-today {
      .ant-picker-cell-inner::before {
        border: none;
      }
    }
  }

  .ant-picker-cell-in-view {
    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        color: black;
        // border: 1px solid #0ea5e9;
        box-sizing: border-box;
        border-radius: 2px;
        background: #fff;
      }
    }
  }
}

:root {
  --fc-today-bg-color: #f0f4f9;
  --fc-event-bg-color: none;
  /* --fc-event-border-color: #3788d8; */
  --fc-event-text-color: none;
  --fc-border-color: #f8f8f9 !important;
}

.fc-media-screen {
  background: white;
  padding-top: 15px;
}

.fc {
  scroll-behavior: smooth;
  scrollbar-color: var(--fc-today-bg-color);


  .fc-scrollgrid-section {

    /* width */
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  .fc-popover {
    .training-info {
      color: #525757;
    }

    .icons {
      margin-top: -1px;
      // width: 50%;
      height: 30px;
      max-height: 90px;
    }
  }

  .fc-event {
    border: none;
    color: #525757;
  }

  // background-color: #f2f2f2;
  .fc-v-event {
    // border: 1px solid #0ea5e9;
    border: none;
  }

  /* Change the font size of the calendar title */
  .fc-header-toolbar h2 {
    font-size: 24px;
  }

  /* Change the background color of the calendar events */
  .fc-timegrid-col-events {
    width: 99%;

    .fc-event {
      // background-color: rgba(14, 165, 233, 0.1);
      background-color: transparent;
      // border-left: 5px solid #0ea5e9;
      border-left: none;

      .my-event {
        color: black;
        display: flex;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-attachment: inherit;
        // overflow: scroll;
        overflow: overlay;

        .img-container {
          // margin-left: -1px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          border-radius: 7px 0px 0px 7px;
          // width: 100%;
          height: 100%;
          // .icons {
          //   height: 100%;
          //   width: auto;
          //   display: flex;
          //   position: absolute;
          //   opacity: 0.2;
          //   right: 10%;
          //   max-height: 69px;
          //   // // width: 67px;
          //   // margin-right: 5px;
          //   // // margin-top: -1px;
          //   // // width: 50%;
          //   // height: 100%;
          //   // max-height: 69px;
          //   // // border-radius: 50px;
          // }

          .image-size {
            // width: auto;
            height: 100%;
            display: flex;
            left: 3px;
            top: 2px;
            max-height: 69px;
            max-width: 69px;
            overflow: hidden;
            justify-content: center;
            border-radius: 7px 0px 0px 7px;

            .imge-training {
              border-radius: 7px 0px 0px 7px;
            }
          }
        }

        .training-info {
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          margin-left: 25px;
          

          .training-row {
            width: 100%;
            padding: 0;

            @media (max-width: 768px) {
              display: flex;
              
              flex-wrap: nowrap;
              height: inherit;
              overflow: scroll;
              scroll-behavior: smooth;
              .training-col {
                padding-right: 5px;

                .content-row {}

              }
            }

            .icon {
              margin-right: 3px;
            }

            .training-content {
              
              text-transform: capitalize;
              .info {              
              }
            }

          }
        }

       
        .book-btn {
          display: flex; //none
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .book-btn-joined {
            display: flex;
            align-items: center;
          }
          .eqButton {
            .ant-btn {
              background: #ff8838;
              border: none;
              color: white;
              border-radius: 22px;
              margin-right: 9px;
            }

            :hover {
              background: #ff8838;
              border: 1px solid #ff8838;
              color: #fff;
            }
          }

          // @media screen and(max-width: 480px) {
          //   justify-content: flex-end;
          //   margin-right: 0px;
          // }
        }

        .book-btn-half-hr {
          display: flex; //none
          flex-direction: column;
          justify-content: center;
          scale: 0.8;

          .eqButton {
            scale: 0.8;

            .ant-btn {
              background: none;
              border: none;
              color: #ff8838;
              border-radius: 22px;
              margin-right: 9px;
            }

            :hover {
              background: #ff8838;
              border: 1px solid #ff8838;
              color: #fff;
            }
          }

          // @media screen and(max-width: 480px) {
          //   justify-content: flex-end;
          //   margin-right: 0px;
          // }
        }
      }

      .timeGridDay-event {
        border-radius: 8px;
      }

      .my-event:hover {
        .book-btn {
          display: flex;
        }

        .book-btn-half-hr {
          display: flex;
        }
      }

      .timeGridWeek-event {
        display: flex;
        height: fit-content;
        align-content: center;
        border-radius: 12px;
        padding: 4px 7px;

        .timeGridWeek-info {
          margin-left: 0;
          width: max-content;
          
          overflow: visible;

          .timeGridWeek-time {
            color: #525757;
            font-weight: 500;
            
          }

          .timeGridWeek-title {
            color: #525757;
            font-weight: 600;
          }
        }

        .icon {
          height: fit-content;
          margin-right: 8px;
          height: 15px;
          // width: auto;
          // display: flex;
          // position: absolute;
          // opacity: 0.2;
          // left: 22%;
          // max-height: 69px;
        }

        .icnons-l {
          top: 25%;
        }

        .icons-h {
          left: 44%;
        }
      }
    }

    @media screen and (max-width: 600px) {
      .img-container {
        width: 34px;
        height: 34px;

        .image-size {
          border-radius: 50%;
          max-height: 34px !important;
          max-width: 34px;
        }
      }
    }
  }

  /* Change the background color of the selected calendar events */
  .fc-highlight {
    background-color: #ffc107;
  }

  .fc-button {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    // display: flex;
    align-items: center;
    background-color: white;
    color: #636363;
    border: #fff;
  }

  .fc-header-toolbar {
    margin-bottom: 15px !important;
    background-color: white;
    padding: 0 5px;

    .fc-toolbar-chunk {
      display: flex;
      background-color: transparent;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      // border: 2px solid #F5F6F7;
      /* Shadows/Gray Blue/3%/5b */
      // box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
      border-radius: 20px;

      .fc-button {
        font-weight: 400;
        line-height: 16px;
        background: white;
        // font-size: 12px;
      }

      .fc-today-button {
        border: 1px solid $navIconGrey;
        padding: 4px 20px;
        box-shadow: none;
        text-transform: capitalize;
        color: $CalendarFontDarkGrey;
        border-radius: 20px;
        background-color: white !important;
        opacity: 1;
        cursor: pointer;
      }

      .fc-today-button:hover {
        border-color: $orangeColor;
        color: $orangeColor;
      }

      .fc-toolbar-title {
        color: #333;
        @include poppins-16-semibold;
      }

      .fc-prev-button,
      .fc-next-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 50%;
        color: $navIconGrey;
        width: 28px;
        height: 28px;
        padding: 14px;
        border: 1px solid $navIconGrey;
        box-shadow: none;

        &:hover {
          box-shadow: none;
          color: $orangeColor;
          border-color: $orangeColor;
        }

        /*box-shadow: 0 5px 7px 2px rgb(38 51 77 / 10%);*/
      }

      .fc-button-group {
        background: transparent;

        .fc-button {
          text-transform: capitalize;
          background: white;
          border: 1px solid $navIconGrey;
          color: $CalendarFontDarkGrey;
        }

        .fc-dayGridMonth-button {
          border-radius: 30px 0px 0px 30px;
        }

        .fc-timeGridWeek-button {}

        .fc-timeGridDay-button {
          border-radius: 0px 30px 30px 0px;
        }

        .fc-button-active {
          color: $whiteColor;
          border: 1px solid #ff8838;
          background-color: #ff8838;
          box-shadow: none;
        }
      }

      .fc-button-primary:disabled {
        color: #4e4e56;
        background: transparent;
      }
    }

    @media screen and (max-width: 760px) {
      display: block;

      .fc-toolbar-chunk {
        margin-bottom: 24px;
      }
    }
  }

  .fc-view {
    .fc-scrollgrid-liquid {
      .fc-scrollgrid-section {
        .fc-scroller-harness {
          margin-bottom: 2px;

          .fc-col-header {
            height: 45px;
            border: 1px solid #f8f8f9;
            background: #fff0e5;

            .fc-timegrid-axis {
              border: 1px solid #f8f8f9;
            }

            .fc-col-header-cell {
              // display: inline;
              border: 1px solid #f8f8f9;
              position: relative;

              .fc-scrollgrid-sync-inner {
                width: 100%;
                position: absolute;
                height: 100%;

                .fc-col-header-cell-cushion {
                  color: $CalendarFontDarkGrey;
                  font-size: 14px;
                  font-weight: 600;
                  height: 45px;
                  display: inline-flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }

      .fc-scrollgrid-section .fc-scrollgrid-section-body {
        .fc-scroller-harness {
          .fc-scroller {
            .fc-daygrid-body {
              .fc-scrollgrid-sync-table {
                .fc-day {
                  border: 1px solid #f8f8f9;
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .fc-col-header-cell-cushion,
  .fc-daygrid-day-number {
    color: #000000;
  }

  .fc-scrollgrid {
    // border: none;
    

    .fc-timegrid-slot-label {
      border: none;
      
      padding-top: -2px;
      // height:  34px;
    }

    .fc-timegrid-slot-lane {
      height: 45px;

      @media screen and (max-width: 760px) {
        height: 25px;
      }

      @media screen and (max-width: 500px) {
        height: 35px;
      }
    }
  }

  .fc-timegrid-col-events {
    height: fit-content;
  }

  .fc-timegrid-event-harness {
    height: auto;
  }

  .fc-timegrid-slot {
    width: fit-content;
    max-height: fit-content;
    height: auto;
  }

  // @media screen and (max-width: 1200px) {
  // .fc-view-harness {
  //     width: 800px;
  //     overflow: scroll;
  // }
  // }

  .fc-timegrid-now-indicator-line {
    display: none;
  }

  .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    .fc-timeGridDay-view {
      .fc-now-indicator-line {
        display: block;
      }

      .fc-timegrid-now-indicator-line {
        display: block;
        border-color: #0ea5e9;
      }

      .fc-timegrid-now-indicator-arrow {
        display: block;
        border-color: #0ea5e9;
      }
    }

    .fc-view-harness {
      overflow: scroll;
    }

    .fc-timeGridWeek-view {
      .fc-scrollgrid {
        width: auto;
        overflow: scroll;
      }
    }

    .fc-timeGridWeek-view {
      .fc-scrollgrid {
        width: auto;
        overflow: scroll;
      }
    }
  }

  .fc-event-month {
    // border-radius: 10px;
    text-transform: capitalize;
    display: flex;
    padding: 4px 5px 4px 5px;
    border-radius: 22px;
    justify-content: space-between;

    .month-title {
      margin-right: 5px;
    }

    div {
      text-transform: capitalize;
    }

    .trin-info {
      display: flex;
      margin-right: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .events-month {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 22px 4px 5px;
  }

  :hover+.hide {
    display: inline-block;
    position: absolute;
    background: #E5E5E5;
    text-transform: capitalize;
    margin-left: 10px;
    padding: 0 5px;
    border: 0.5px solid #000;
    border-top-left-radius: 0px;
    border-radius: 0 7px 7px 7px;
    top: 14px;
    left: 10px;
    z-index: 10000;
  }

  .hide {
    display: none;
    position: relative;
  }
}

.fc-time-grid-event {
  /* Set a fixed width for the events in timeGridWeek */
  width: 150px;
  /* Allow text to wrap within the event */
  white-space: normal !important;
  /* Add some padding to the event */
  padding: 5px;
  /* Add a border */
  border: 1px solid #ddd;
}

/* Add a scroll bar to the calendar container when screen size is smaller */
@media (max-width: 768px) {
  .fc-scroller.fc-scroller-clip {
    overflow: auto !important;
  }
}

// .ant-modal-content {
//   .ant-modal-footer {
//     .custom-cancel-button {
//       border-radius: $btnBorderRadius;
//       color: #000000;
//       border-color: #000;

//       &:hover {
//         background-color: white;
//         color: #000;
//         border-color: #000;
//         box-shadow: 0 2px #999;
//       }

//       &:active {
//         background-color: white;
//         color: #000;
//         border-color: #000;
//         transform: translateY(4px);
//       }
//     }

//     .custom-ok-button {
//       border-radius: $btnBorderRadius;
//       /*line-height: 2.2715;*/
//       background-color: #ff8838;
//       border-color: #ff8838;
//       font-style: normal;
//       font-weight: normal;
//       font-size: 14px;
//       line-height: 22px;
//       color: #fff;
//       /*margin: auto 5px;*/

//       &:hover {
//         background-color: #ff9558;
//         box-shadow: 0 2px #999;
//       }

//       &:active {
//         background-color: #ff9558;
//         transform: translateY(4px);
//       }
//     }
//   }
// }

.fc-time-grid .fc-slats td {
  width: 500px;
  /* Change this value as needed */
}

.my-event-list {
  color: black;
  display: flex;
  // gap: 25px;
  width: 100%;
  height: 100%;
  margin-top: 22px;
  background-color: #eff6ff;

  .day-zone {
    display: flex;

    .day-content {
      display: flex;
      flex-direction: column;
      margin: auto 7px;

      .date {
        font-size: 25px;
      }
    }

    .time-content {
      align-items: center;
      display: flex;
      margin: auto 10px;
    }
  }

  .img-container {
    margin-left: -1px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    min-width: 70px;

    .imge-training {
      width: 64px;
      height: 64px;
      display: flex;
      left: 3px;
      top: 2px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .training-info {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    margin-left: 25px;
    

    .training-row {
      width: 100%;
      padding: 0;

      @media (max-width: 768px) {
        display: flex;
        // flex-direction: row;
        flex-wrap: nowrap;
        height: inherit;
        overflow: scroll;
        scroll-behavior: smooth;
        .training-col {
          padding-right: 5px;

          .content-row {}

        }
      }

      .icon {
        margin-right: 3px;
      }

      .training-content {
        
        text-transform: capitalize;
        .info {              
        }
      }

    }
  }
  // .training-info {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-around;
  //   color: #525757;
  //   margin-left: 25px;

  //   .training-content {
  //     // font-style: normal;
  //     font-size: 12px;
  //     line-height: 16px;
  //     margin-right: 8px;
  //     color: #525757;
  //     /* identical to box height, or 133% */
  //     align-items: center;
  //     text-transform: capitalize;

  //     .heading {
  //       font-weight: 400;
  //       color: #525757;
  //       margin-right: 15px;
  //     }

  //     .info {
  //       font-weight: 700;
  //       color: #525757;
  //       margin-right: 65px;
  //     }

  //     .attende-name {
  //       margin-right: 8px;
  //     }
  //   }

  //   .attende-content {
  //     display: flex;
  //     justify-content: space-between;
  //   }
  // }
}

.eq-collapse-panel {
  margin-top: 16px;
}

.section-divider {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
  margin-top: 20px;
}

.fc-theme-standard td {
  border-color: #e7e7e7;
}

.custom-modal {
  .custom-modal-title {
    .event-type {
      text-transform: capitalize;
    }
  }

  .img-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .imge-training {
      width: 64px;
      height: 64px;
      display: flex;
      border-radius: 15px;
    }

    .imge-icon {
      height: 40px;
      // background: #0ea5e9;
      border-radius: 50%;
      padding: 5px;
      margin: 2px;
    }
  }

  .heading {
    font-weight: bold;
    margin-right: 10px;
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: space-around;

    .ant-btn {
      border-radius: 20px;
      /* line-height: 2.2715; */
      background-color: #ff8838;
      border-color: #ff8838;
      @include poppins-14-regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
      /* margin: auto 5px; */
    }
  }
}

.fc-daygrid-day-frame {
  .fc-daygrid-day-top {
    position: absolute;
    width: 100%;
    height: 100%;

    .fc-daygrid-day-number {
      width: inherit;
      height: inherit;

      .custom-day-cell {
        width: 100%;
      }
    }
  }

  .fc-daygrid-day-events {
    position: absolute;
    margin-top: 25px;
    margin-left: 5px;
  }
}

.fc .fc-timegrid-slot-label-cushion {
  font-size: 10px;
  font-weight: 400;
  color: $calendarTimeLightGrey;
}

/*.fc-day-today {
    background-color: #ffffff !important;
}*/

table.fc-col-header,
.fc-scroller>div,
.fc-scroller>div>table {
  width: 100% !important;
}

.content {
  height: 100% !important;
}

.fc-col-header,
.fc-timegrid-body {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-daygrid-body {
  width: 100% !important;
}

.fc-daygrid-body-balanced {
  width: 100% !important;
}

.fc table {
  width: 100% !important;
}