﻿$orangeColor: #FF8838;
$lightOrange: #ffe0c7;
$lightgray: #ffe0c7;

// btn colors
$btnBgDisable:#999;

$mainColor: #4fd3c6;
$mainColorHover: #3bcebf;
$redColor: #e66666;
$redColorHover: #e44444;
$yellowColor: #FABA64;
$seashellColor: #FFF2E8;
$makoColor: #525757;
$blackColor: #000000;
$whiteColor: #FFFFFF;
$textColor: black;
$textLightColor: #474e51;
$btnTextBlack: #5B5B5B;
$transparentBackground: rgba(255, 255, 255, 0.8);

$buttonTextColor: white;
$buttonColorDisabled: #c4c4c4;
$buttonTextColorDisabled: #999;

$secondaryColor: #2e4f4e;
$secondaryDarkColor: #1b3435;

$mainLightColor: #d6e8e5;
$CTADarkColor: #0fbea9;
$CTALightColor: #74d1c7;

$secondaryLightColor: #D7EDE9;
$secondaryFontColor: #22504E;
$secondaryBackgroundIcon: #00514f;
$secondaryIconColor: #d0eeea;
$navBg: rgba(255, 138, 42, 0.22);
$navTxtColor: #FF8838;
$eqFooterBg: #333333;
$darkbg: #525757;

$group1BackgroundColor: #F6FFED;
$group1BorderColor: #B7EB8F;
$group1Color: #52C41A;
$group2BackgroundColor: #F0F5FF;
$group2BorderColor: #ADC6FF;
$group2Color: #2F54EB;
$group3BackgroundColor: #FFF1F0;
$group3BorderColor: #FFA39E;
$group3Color: #F5222D;

$navIconGrey: #575f6a;
$CalendarFontDarkGrey: #3c4043;

$xl: 1140px;
$lg: 960px;
$md: 720px;
$sm: 540px;

$eqBgColor: #FF8A2B;
$eqBgGreyColor: #8B8B8B;
$eqWhiteColor: #FFFFFF;
$eqOrangeColor: #FF7300;
$eqBlackColor: #444444;
$blackMenu: #626262;
$borderRadiusBase: 20px;
$inputBorderRadius: 5px;
$btnBorderRadius: 20px;
$tagBorderRadius:37px;
$inputHeight:40px;
$textInputHeight: 35px;
$buttonToggleChecked: #FF8838;
$buttonToggleUnchecked: $eqWhiteColor;

/*colors from usman designs*/
$darkBlackZero: #000000;
$darkBlack: rgba(0, 0, 0, 0.85);
$darkBlackSecondary: rgba(0, 0, 0, 0.45);
$orangeBorder: #FF8838;
$darkBrownBar: #525757;
$darkBrownText: #626262;
$blueLink: #0081FE;
$GreyLightLine: #C5C5C5;
$GreyLightLine2: #C4C4C4;
$GreySiderBg: #E5E5E5;
$darkBlack: rgba(0, 0, 0, 0.85);
$darkBlackSecondary: rgba(0, 0, 0, 0.45);

$darkBorder: #003251;
$entityTableHeaderBackgroundColor: #2D9CDB;

/*Trainer Messages*/
$trainerMessageListHeaderBackground: #FAFAFA;
$borderColor: #D9D9D9;
/*Financial List*/
$grayHeading: #FAFAFA;
$graylightborder: #f1f1f1;
/*Select Status*/
$green: #43A047;
$purple: #CB38FF;
$red: #EA4335;
$orange: #FF8838;

/*Trainer Event*/
$blueDate: #0081FE;
$rowDashedBorder: #E5E5E5;
$rowLinedBorder: #F3F3F3;
$dividerBorderColor: #BDBDBD;
$rowBorderShadow: rgba(0, 0, 0, 0.15);
/*Pop up message*/
$awesomeFont: Font Awesome 5 Free;
$robotoFont: Roboto;
$whiteBackground: #fff;
$greenText: #43A047;
$blueButton: #1890FF;

$rowLinedBorder: #F3F3F3;
$blueDate: #0081FE;
$rowBorderShadow: rgba(0, 0, 0, 0.15);
$rowDashedBorder: #E5E5E5;
/*Student*/
$rowBottomBorder: #F0F0F0;
/*Student Invite*/
$grayColor: #E5E5E5;
/*ProfessionalsList*/
$dashedBorder: #979797;
$disciplineBorder: #ABD6FF;
/*Group List*/
$blueBorder: #1890FF;
$blueText: #1890FF;
/*Create Event*/
$purpleBtnClr: #6200EE;
$greenBtnClr: #03dac5;
$lightGreenBgClr: #dbffe4;

$lightTagGreenBG: #9cb9ca;
$lightTagGrey: #82898e;
$calendarTimeLightGrey: #82898e;

$paid: #19730A;
$unPaid: #EA4335;
$action: #0188E9;
// font style 

// logo
@mixin cinzel {
    font-family: "Cinzel", serif;
}

//Poppins - Sens Serif
@mixin poppins {              //banner
    font-family: 'Poppins', 'Sans Serif';
}
@mixin poppins-64-bold {              //banner
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 500;
    font-style: normal;
    font-size: 64px;
    line-height: 1.3;
}

@mixin poppins-52-bold {              //h1
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 52px;
    line-height: 56px;
}

@mixin poppins-52-semibold {              //h1
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 52px;
    line-height: 56px;
}
@mixin poppins-52-regular {              //h1
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 500;
    font-style: normal;
    font-size: 52px;
    line-height: 56px;
}


@mixin poppins-36-bold {              //h1
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 40px;
}

@mixin poppins-36-semibold {              //h1
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 36px;
    line-height: 40px;
}


@mixin poppins-32-bold {              //h2
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    line-height: 36px;
}

@mixin poppins-32-semibold {              //h2
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 32px;
    line-height: 36px;
}


@mixin poppins-24-bold {              //h3
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
}

@mixin poppins-24-semibold {              //h3
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
}

@mixin poppins-24-regular {              //h3
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
}


@mixin poppins-20-bold {              //h4
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
}

@mixin poppins-20-semibold {              //h4
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
}

@mixin poppins-20-regular {              //h4
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
}


@mixin poppins-18-bold {              //h5
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
}

@mixin poppins-18-semibold {              //h5
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
}

@mixin poppins-16-semibold {              //h5
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
}

@mixin poppins-18-regular {              //p
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
}

@mixin poppins-16-regular {              //p
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
}

@mixin poppins-14-regular {              //p
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
}

@mixin poppins-14-semibold {              //collapse-panel
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
}

@mixin poppins-12-semibold {              //collapse-panel
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    line-height: 150%;
}

// buttons

@mixin poppins-22-semibold-btn {
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 22px;
    line-height: 22px;
}
@mixin poppins-18-semibold-btn {
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
}

@mixin poppins-18-regular-btn {
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
}

@mixin poppins-14-semibold-btn {
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
}
@mixin poppins-14-regular-btn {
    font-family: 'Poppins', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    // line-height: 14px;
}


//Inter - Sens Serif 
@mixin Inter {
    font-family: 'Inter', 'Sans Serif';
}

@mixin Inter-36-bold {              //h1
    font-family: 'Inter', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 40px;
}

@mixin Inter-36-semibold {              //h1
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 36px;
    line-height: 40px;
}


@mixin Inter-32-bold {              //h2
    font-family: 'Inter', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    line-height: 36px;
}

@mixin Inter-32-semibold {              //h2
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 32px;
    line-height: 36px;
}


@mixin Inter-24-bold {              //h3
    font-family: 'Inter', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
}

@mixin Inter-24-semibold {              //h3
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
}

@mixin Inter-24-regular {              //h3
    font-family: 'Inter', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
}


@mixin Inter-20-bold {              //h4
    font-family: 'Inter', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
}

@mixin Inter-20-semibold {              //h4
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
}


@mixin Inter-18-bold {              //h5
    font-family: 'Inter', 'Sans Serif';
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
}

@mixin Inter-18-semibold {              //h5
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
}

@mixin Inter-18-regular {              //p
    font-family: 'Inter', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
}

@mixin Inter-16-regular {              //p
    font-family: 'Inter', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
}

@mixin Inter-14-regular {              //p  , input label
    font-family: 'Inter', 'Sans Serif';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
}
@mixin Inter-14-500 {              //list-heading
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
}
@mixin Inter-14-semibold {              //collapse-panel
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
}

@mixin Inter-12-regular {
    font-family: 'Inter', 'Sans Serif';
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
}

// buttons

@mixin Inter-18-semibold-btn {
    font-family: 'Inter', 'Sans Serif';
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
}

@mixin Inter-18-regular-btn {
    font-family: 'Inter', 'Sans Serif';
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
}

@mixin Inter-14-regular-btn {
    font-family: 'Inter', 'Sans Serif';
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
}


//


@mixin placeholder {
    ::-webkit-input-placeholder {
        @content
    }

    :-moz-placeholder {
        @content
    }

    ::-moz-placeholder {
        @content
    }

    :-ms-input-placeholder {
        @content
    }
}




.mt-16 {
    margin-top: 16px;
}

.mt-12 {
    margin-top: 12px;
}

.self-stretch {
    align-self: stretch;
}

@media (max-width:$sm) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width:$sm + 1px) {
    .hidden-md {
        display: none !important;
    }
}


.eq-container-fluid {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width: 476px) {
    .eq-container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width: 768px) {
    .eq-container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width: 992px) {
    .eq-container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width: 1200px) {
    .eq-container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width: 476px) {
    .eq-container-fluid {
        width: 100%
    }
}

@media (min-width: 768px) {
    .eq-container-fluid {
        width: 720px;
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .eq-container-fluid {
        width: 960px;
        max-width: 100%
    }
}

@media (min-width: 1200px) {
    .eq-container-fluid {
        width: 1140px;
        max-width: 100%
    }
}

@media (min-width: 1400px) {
    .eq-container-fluid {
        width: 1340px;
        max-width: 100%
    }
}

@media (min-width: 1600px) {
    .eq-container-fluid {
        width: 1540px;
        max-width: 100%
    }
}
@media (min-width: 1800px) {
    .eq-container-fluid {
        max-width: 1650px; // Limit the maximum width
    }
}
// Responsive


@mixin responsive($breakpoint) {

    @if $breakpoint ==xss {
        @media (max-width: 320px) {
            @content;
        }
    }

    @if $breakpoint ==xs {
        @media (max-width: 576px) {
            @content;
        }
    }

    @if $breakpoint ==sm {
        @media (min-width: 576px) {
            @content;
        }
    }

    @if $breakpoint ==md {
        @media (min-width: 768px) {
            @content;
        }
    }

    @if $breakpoint ==lg {
        @media (min-width: 992px) {
            @content;
        }
    }

    @if $breakpoint ==xl {
        @media (min-width: 1200px) {
            @content;
        }
    }

    // small-lptop
    @if $breakpoint ==sl {
        @media (min-width: 1366px) {
            @content;
        }
    }

    @if $breakpoint ==xxl {
        @media (min-width: 1400px) {
            @content;
        }
    }

    @if $breakpoint ==xxxl {
        @media (min-width: 1600px) {
            @content;
        }
    }
}