.eq-trainer-features {
    
    .page-banner {

        .hero-image {
            height: 100%;
            width: 100%;
            @media (max-width: 460px) {
                width: auto;
                height: 80vh;
            }
        }
     }

    
}