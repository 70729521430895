@import '../../shared/Vars.scss';

.eq-gb-container {
    background-color: white;
    margin: 0px;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .del-msg {
        color: red;
    }

    .eq-gb-heading-row {
        padding: 10px 0px 6px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-gb-col {
            padding: 0px;
            margin: 0px;

            .eq-gb-heading {
                @include poppins-24-regular;
                padding: 0px 0px 5px 0px;
                margin-bottom: 5px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }

                @media (min-width:$sm) {
                }
            }


            .eq-gb-student-header {
                margin: 0px 0px 0px 0px;
                padding: 0px 0px 0px 0px;
                @include Inter;
                font-style: normal;

                .eq-gb-student-sort {
                    font-weight: normal;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    padding: 0px;
                    margin: 0px;
                    margin-bottom: -9px;
                }

                .eq-gb-student-search {
                    padding: 0px;

                    .ant-input {
                        border: 1px solid $borderColor;
                        border-radius: $inputBorderRadius 0px 0px $inputBorderRadius;
                    }

                    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
                        border-radius: 0px $inputBorderRadius $inputBorderRadius 0px;
                        border: 1px solid $borderColor;
                    }

                    .anticon svg {
                        margin-top: -5px;
                    }
                }

                .eq-gb-student-btn {
                    margin-left: 5px;
                }
            }
        }
    }


// collapse

    .eq-cg-collapse {
        border: 1px solid $borderColor;
        border-radius: 2px;
        margin: 10px 0px;
        padding: 0px;
    
        .eq-cg-collapse-header {
            background: $grayHeading;
            border-bottom: 1px solid $borderColor;
            padding: 8px 16px;
            @include poppins-14-semibold;
            // font-family: Poppins;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 14px;
            // line-height: 22px;
            color: $darkBlack;
    
            @media (max-width:$sm) {
                .ant-row-end {
                    justify-content: center;
                }
            }
    
            .eq-cg-collapse-header-name {
                margin: 0px 8px;
                padding: 8px 0px;
    
                @media (max-width:$sm) {
                    text-align: center;
                }
            }
    
            .eq-btn-icon img {
                margin-right: 10px;
                margin-bottom: 3px;
            }
    
            .gh-icon {
                cursor: pointer;
    
                img {
                    margin-right: 5px;
                    margin-bottom: 3px;
                }
            }
    
            .eq-group-header-icons {
                margin: 0px 8px;
                background: $whiteBackground;
                border-radius: 16px;
                display: inline-flex;
                flex-direction: row;
                margin: 5px 16px;
                order: 0;
    
                .eq-gh-total-icon {
                    padding: 2px 16px;
                    border: 1px solid $blueBorder;
                    border-radius: 16px 0px 0px 16px;
                }
    
                .eq-gh-total {
                    font-size: 14px;
                    color: $blueText;
                }
    
                .eq-gh-edit-icon {
                    padding: 2px 16px;
                    box-shadow: inset -1px 0px 0px $borderColor, inset 0px 1px 0px $borderColor, inset 0px -1px 0px $borderColor;
                }
    
                .eq-gh-message-icon {
                    padding: 2px 16px;
                    box-shadow: inset -1px 0px 0px $borderColor, inset 0px 1px 0px $borderColor, inset 0px -1px 0px $borderColor;
                }
    
                .eq-gh-trash-icon {
                    padding: 2px 16px;
                    box-shadow: inset -1px 0px 0px $borderColor, inset 0px 1px 0px $borderColor, inset 0px -1px 0px $borderColor;
                    border-radius: 0px 16px 16px 0px;
                }
            }
    
    
            .group-header-session {
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 5px 16px;
                padding: 2px 16px;
                background: $whiteBackground;
                border: 1px solid $borderColor;
                border-radius: 16px;
            }
    
            .group-header-students {
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: $whiteBackground;
                border: 1px solid $orangeBorder;
                border-radius: 16px;
                margin: 5px 16px;
                padding: 2px 16px;
    
                .eq-gh-students {
                    display: flex;
                    flex-direction: row;
                    order: 1;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: center;
                    color: $orangeColor;
                }
            }
    
            .group-header-arrow {
                margin: 8px 0px;
                text-align: center;
                width: 5px;
    
                @media (max-width:$sm) {
                    text-align: center;
                }
            }
        }
    
        .eq-tab-container {
            padding: 16px;
    
            @media (max-width:$sm) {
                .hidden-sm-groups {
                    display: none !important;
                }
            }
        }
    
        .eq-action-tab {
            align-self: center;
    
            .ant-radio-button-wrapper {
                &:first-child {
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    border-left: $orangeBorder;
                }
    
                &:last-child {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
    
                &.ant-radio-button-wrapper-checked {
                    border-color: $orangeBorder;
                    color: $orangeBorder;
                }
    
                .ant-radio-button-checked {
                    &:hover {
                    }
                }
            }
    
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                color: $orangeBorder;
    
                &::before {
                    background-color: $orangeBorder;
                }
    
                &:hover {
    
                    &::before {
                        background-color: $orangeBorder;
                    }
                }
            }
    
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                box-shadow: none;
    
                &:first-child {
                    border-color: $orangeBorder;
                }
            }
    
            .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
                border-right-color: $orangeBorder;
            }
        }
    }

}