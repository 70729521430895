@import '../../shared/Vars.scss';

.subscriptions {

    .ant-steps {
        padding: 70px;

        .ant-steps-item {
            .ant-steps-item-icon {
                background-color: #FF8838;
                border-color: #FF8838;

                .ant-steps-icon {
                    color: #c7c7c7;
                }
            }

            &.ant-steps-item-finish {
                >.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
                    background-color: #FF8838;
                }

                .ant-steps-item-icon {
                    background-color: #e9c2a8;

                    .ant-steps-icon {
                        color: #FF8838;
                    }
                }

                &::before {
                    background-color: #e9c2a8;
                    border-color: #e9c2a8;
                }

                &::after {
                    background-color: #e9c2a8;
                }
            }

            &.ant-steps-item-process {
                .ant-steps-item-icon {
                    background-color: #FF8838;

                    .ant-steps-icon {
                        color: #FFFFFF;
                    }
                }
            }

        }
    }

    .subscriptions-section-heading {
        padding-left: 70px;
    }

    .subscriptions-plans {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;

        .subscriptions-plan {
            width: 350px;
            min-height: 460px;
            border-radius: 12px;
            border: 1px solid #F0F0F0;
            padding: 20px;
            margin-bottom: 22px;


            filter: drop-shadow(0px 4px 12px rgba(247, 234, 226, 0.10));

            .plan-details {
                flex-direction: column;

                .plan {
                    color: #000;
                    font-size: 16px;
                }

                .plan-date {
                    text-align: end;
                }

                .plan-price {
                    .unit {
                        font-size: 40px;
                        font-weight: 500;
                    }

                    .price {
                        font-size: 40px;
                        font-weight: 500;
                    }

                    .plan-span {
                        color: #71717A;
                    }


                }
            }


            .plan-dec {
                color: #A1A1AA;
                border-bottom: 1px solid #27272A;
                padding-bottom: 22px;
            }

            .subscription-benifits {
                color: #000;
                text-align: left;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                min-height: 160px;
                /* 150% */

                .plan-list {
                    margin-bottom: 15px;

                    .icon {
                        color: #71717A;
                        margin-right: 6px;

                    }
                }
            }


            .subscription-btn {
                width: 100%;

                .eqButton {
                    width: 100%;
                    margin-top: 35px;

                    .ant-btn {
                        width: 100%;
                        height: auto;
                        padding: 16px
                    }

                }

                .subscription-btn-paid {
                    margin-top: 35px;
                    width: 100%;
                        height: auto;
                        padding: 16px;
                        border: 1px solid $greenText;
                        border-radius: 20px;
                        text-align: center;
                        color: $greenText;
                }
            }

            .free-plan-btn {
                .eqButton {
                    .ant-btn {
                        color: #FF8838;
                        background: #fff;
                        border-color: #FF8838;
                    }

                }

            }

        }

        .stripe-powerBy {
            text-align: end;
            font-size: 11px;

            margin-top: 16px;
            margin-bottom: -20px;

            .stripe-img {
                width: 46px;
            }
        }
    }

    .subscription-more-info {
        padding-left: 70px;

        .subscription-cancel {
            .cancel-email {
                color: $blueLink;
                text-decoration: underline;
            }
        }
    }

    .Card-details {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 22px;

        .card-form {}
    }

    .Summary-details {
        border-radius: 4px;
        border: 1px solid #A3A3A3;
        background: #FFF;
        padding: 20px;
        margin-bottom: 22px;

        .subscribed-plan {

            width: 100%;

            .subscribed-plan-title {

                font-weight: 600;
                border-bottom: 1px solid #A3A3A3;
                padding-bottom: 26px;

            }

            .subscribed-plan-details {
                color: #000;
                text-align: left;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                border-bottom: 1px solid #A3A3A3;
                padding-bottom: 26px;

                .plan-list {
                    margin-bottom: 15px;
                    margin-right: 35px;

                    .icon {
                        color: #71717A;
                        margin-right: 6px;

                    }
                }
            }

            .subscribed-payment {
                .payment {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;

                    margin-right: 10px;
                }

                .plan-price {
                    .unit {
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .price {
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .plan-span {
                        color: #71717A;
                    }


                }
            }
        }


    }

    .subscription-btns {
        display: flex;
        justify-content: center;

        .back-btn {
            .eqButton {


                .ant-btn {
                    padding: 0 40px;
                    margin-right: 5px;
                    color: #FF8838;
                    background: #fff;
                    border-color: #FF8838;
                }
            }
        }

        .next-btn {
            .eqButton {

                .ant-btn {
                    padding: 0 40px;
                    margin-left: 5px;
                }
            }
        }
    }


}