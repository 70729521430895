@import '../../shared/Vars.scss';

.eq-compose-message-inline {
    margin: 0px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    padding: 30px 15px 25px 25px;
    @include Inter;
    font-style: normal;

    @media(max-width:$sm) {
        padding: 15px 5px 15px 5px;
    }

    .eq-message-in-subject-container {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin: 4px 0px;
        @media(max-width:$sm) {
            font-size: 10px;
        }
        .eq-message-in-subject {
            padding-left: 5px;
            font-weight: 700;
        }
    }

    .eq-message-in-time-container {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin: 4px 0px;
        @media(max-width:$sm) {
            font-size: 10px;
        }
        .eq-message-in-time {
            padding-left: 5px;
            font-weight: 700;
        }
    }

    .eq-message-in-message-text-container {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin: 4px 0px;
    }


    .eq-is-input {
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 20px;
        padding-left: 16px;
        margin: 15px 0px 0px 0px;
        @media(max-width:$sm) {
            margin: 10px 0px 0px 0px;

        }
    }

    .eq-is-input:focus {
        box-shadow: none;
    }

    .eq-is-input::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: $darkBrownText;
        opacity: 0.4;
    }

    .eq-is-btn {
        margin: 20px 0px 0px 0px;
        @media(max-width:$sm) {
            margin: 10px 0px 0px 0px;

        }
    }

    .hide {
        display: none;
    }

    .show {
        display: block
    }

    .invalid {
        color: $redColor;
    }

    .valid {
        color: $greenText;
    }
}
