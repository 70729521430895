@import '../../shared/Vars.scss';

.eq-all-student-trainings-board {
    background-color: $whiteColor;
    padding: 10px 0px 20px 0px;


    .eq-heading-row {
        padding: 4px 0px 0px 0px;
        margin: 0px 0px 15px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-heading {
                @include poppins-24-regular;
                padding: 0px 0px 0px 0px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        .eq-event-buttons {
            margin: 0px 0px 0px 5px;

            @media (max-width:$sm) {
                margin: 0px 0px 5px 5px;
            }
        }
    }

    
    .action-all-btn {
        .ant-float-btn {
            @include poppins-14-regular;
            right: 50px;
            bottom: 47%;
            border-radius: 20px;
            min-width: 100px;
            min-height: 30px;
            .ant-float-btn-body {
                background-color: #FF8838;
                border-radius: 20px;
                .ant-float-btn-content {
                    min-height: 30px;

                    .ant-float-btn-description{
            @include poppins-14-regular;

                        color: #fff;
                        padding: 7px 0;
                    }
                }
            }
        }
    }

    // @media (max-width:$sm) {
    //     padding: 10px;
    // }

    // .event-type {

    //     // padding-bottom: 35px ;
    //     .label {
    //         margin-top: 25px;
    //         @include poppins-16-semibold;
    //         color: $darkBlackZero;
    //         margin-bottom: 16px;
    //         
    //     }

    // }

    // .ant-row {
    //     display: flex;
    // }




    // .eq-event-header-icon {
    //     cursor: pointer;

    //     img {
    //         padding: 5px 0px 5px 10px;
    //     }
    // }



}

// .customCollapse {
//     overflow: hidden;
//     transition: 0.4s max-height;
// }

// .customCollapse>div {
//     overflow: auto;
// }

// .sample-content {
//     background: #ccc;
//     padding: 10px;

//     h1,
//     p {
//         margin: 0;
//         text-align: center;
//     }
// }


// .collapseHeader {
//     padding: 10px;
//     display: block;
//     background: #333;
//     color: white;
//     width: 100%;
//     border: 0;
//     cursor: pointer;
// }