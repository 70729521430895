@import '../../../shared/Vars.scss';

.payment-board {

    .ant-tabs {
        .ant-tabs-nav-list {
            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;
                
                // font-size: 12px;
                // font-weight: 500;
                color: $blackColor;
                transition: color 0.3s;

                &:hover {
                    color: #FF8838;
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;
                }
            }

            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }


    .eq-fl-row {
        margin-top: 22px;
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        border-bottom: 2px solid $graylightborder;

        .eq-fl-th {
            padding: 0px;
        }
    }

    .eq-list-checkAll {
        padding: 17px 16px;
        color: $darkBlack;
        @include Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
}