@import "../../../shared/Vars.scss";

.eq-MessageReply {
    border: 2px solid #f0f0f0;
    padding: 10px;

    .msg-title {
        @include poppins-18-semibold;
    }

    .row-one{

        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 6px;
        margin-bottom: 5px;
        box-shadow: 1px 1px 1px #c6c6c6

        // border-bottom: 2px solid #F0F0F0;
        // margin-bottom: 5px;
    }
    .action-row {
        border-top: 1px solid #f0f0f0; // Add top border
        border-bottom: 2px solid #f0f0f0;
    }

    .message-text {
        margin-top: 16px;
        padding-left: 4px;
        @include poppins-16-regular;
        color: $textLightColor;
    }

    .reply-text {
        padding-left: 12px;
        padding-bottom: 10px;
        @include poppins-16-regular;
        color: $textLightColor;
    }
    // EqMessageReply.scss

    .reply-box {
        padding: 16px 0px; // Adjust the padding as needed
    }

    .reply-box {
        .reply-input-container {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 8px;
            margin: 8px; // Add space between inputs

            box-shadow: 2px 4px 7px #c6c6c6
        }

        .reply-textarea {
            margin: 15px 0px;
        }
    }

    .overflow-hidden {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .email-body-receipients {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
    }

    .toggle-replies {
        padding: 16px 0px;
    }

    .msg-time {
        font-size: 10px;
        font-family: 'Inter', 'Sans Serif';
    }

    .msg-reply{
        margin-top: 9px;
    }

    .existing-replies{
        padding-left: 50px;
    }
}
