@import '../../shared/Vars.scss';

.eq-message-board {
    background-color: $whiteColor;
    padding: 2%;

    .ant-row {
        display: flex;
    }

    .eq-message-composer-container {
        /*border: 1px solid $borderColor;*/
        /*border-radius: $borderRadiusBase;*/
    }

    .eq-message-list-container {
        padding-top: 30px;
    }
}
