@import '../../shared/Vars.scss';

.eq-is-container {
    border: 1px solid $grayColor;
    box-sizing: border-box;
    border-radius: $borderRadiusBase;
    padding: 22px 32px 22px 32px;
    margin: 14px 0px 14px 0px;
    @include poppins;
    font-style: normal;

    .eq-is-heading {
        @include poppins-16-semibold;
        // font-weight: 600;
        // font-size: 14px;
        // line-height: 23px;
        margin: 5px 0px 5px 0px;
        color: $orangeColor;
    }

    .eq-is-error {
        color: red;
    }

    .eq-is-email {
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: $inputBorderRadius;
        padding-left: 16px;
    }

    .eq-is-email:focus {
        box-shadow: none;
    }

    .eq-is-email::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: $darkBrownText;
        opacity: 0.4;
    }

    .eq-email-tag {
        margin-top:5px;
        &.ant-tag {
            // border-radius: 12px;
        }
    }

    .eq-is-input-heading {
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        margin: 10px 0px 10px 0px;
        color: $darkBrownText;
        padding-left: 5px;
    }

    .eq-is-textarea {
        padding: 16px;
        min-height: 142px;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: $inputBorderRadius;
    }

    .eq-is-textarea:focus {
        box-shadow: none;
    }

    .eq-is-btn {
        margin: 20px 0px 5px 0px;

        .ant-btn-block {
            @media (min-width:$sm) {
                min-width: 102px;
            }
        }

        .eq-col-btn {
            padding-right: 8px;
        }
    }

    .hide {
        display: none;
    }

    .show {
        display: block
    }

    .invalid {
        color: $redColor;
    }

    .valid {
        color: $greenText;
    }
}
