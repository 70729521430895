@import '../../shared/Vars.scss';

.eq-jr-tr {
    padding: 17px 16px;
    @include Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $darkBlack;
    border-bottom: 2px solid $rowBottomBorder;


    .eq-jr-td {
        padding: 0px;
        margin: 0px;

        .ant-tag {
            // border-radius: 12px;
        }

        .eq-jr-group {
            background: #FFFFFF;
            border: 1px solid $orange;
            box-sizing: border-box;
            border-radius: 12px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $darkBrownText;
        }

        .eq-action-invite {
            cursor: pointer;
            margin: 5px;
        }

        .eq-action-delete {
            cursor: pointer;
            margin: 5px;
        }
    }
}
