@import "../../shared/Vars.scss";
// EqProductPaymentPopup.scss

.eq-product-payment-popup {
  // padding: 2%;
  .modal-title-container {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    .card-icon-container {
      align-items: center;

      .circle-outer {
        background-color: #edd3c2;
        border-radius: 50%;
        padding: 8px;
        margin-right: 10px;

        .circle-inner {
          background-color: #f4e8df;
          border-radius: 50%;
          padding: 7px;

          .card-icon {
            font-size: 20px;
          }
        }
      }
    }

    .payment-details {
      div {
        @include poppins-18-bold;
        margin-top: 2px;
      }

      p {
        @include poppins-14-regular;
        margin-top: 5px;
        color: $textLightColor;
      }
    }
  }

  .product-details-container {
    display: flex;

    .product-image {
      width: 110px;
      border-radius: 10px;
    }

    .product-details {
      div {
        h2 {
          @include poppins-18-bold;
          margin: 0;
        }
        div{
          @include poppins-14-regular;
        }

        p {
          @include poppins-14-semibold;
        }
      }
    }
  }

  .input-container {
    margin-top: 16px;

    .eqInput  {
      margin-bottom: 12px;
    }
  }

  .footer-buttons {
    margin-top: 16px;

    .eq-button {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .button-container {
    display: flex;
    margin: 20px 0px;
  
    .eq-button {
      flex: 1;
      margin-right: 10px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
}
