@import "../../shared/Vars.scss";

.st-shl-tabs {
    width: 100%;

    .ant-tabs-top>.ant-tabs-nav {
        margin: 0 0 1px 0;
    }

    .ant-tabs {
        .ant-tabs-nav-list {
            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;
                
                // font-size: 12px;
                // font-weight: 500;
                color: $blackColor;
                transition: color 0.3s;

                &:hover {
                    color: #FF8838;
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;
                }
            }

            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }


    .eq-schedule-calendar {
        justify-content: space-between;
        /*padding: 20px 5px 0px 5px;*/
        padding: 0px 0px 0px 0px;
        margin: 0 !important;

        .eq-Calendar {

            // padding-left: 10px;
            .cal-main {
                margin-left: 2px;
            }
        }

        .eq-schedule-range {
            background: white;
            padding-left: 15px;
            padding-right: 12px;
            padding-bottom: 10px;

            @media screen and (max-width: 500px) {
                padding-right: 0px;
                width: 100%;
                margin-bottom: 2px;
            }

            .range-caption {
                @media screen and (max-width: 500px) {
                    text-align: center;
                    margin: auto;
                }
            }



            .calendar-heading {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #333333;
                margin: 10px 0px;
                padding: 10px 0px;
            }

            .badge-sapce {
                display: contents;

                .ant-space-item {
                    margin-right: 8px;
                    margin-bottom: 15px;

                    .no-locations-found {
                        color: $navIconGrey;

                        @media screen and (max-width: 500px) {
                            text-align: center;
                            margin: auto;
                        }
                    }
                }

                .badge-item {
                    padding: 4px 13px;
                    border-radius: 21px;

                    .ant-badge-status-text {
                        // color: #11a433;
                    }
                }
            }

            .date-time-picker {
                min-height: 40px;
                border: 1px solid #c4c4c4;
                border-radius: $inputBorderRadius;
                @include Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #333333;
                background-color: white;
                border-radius: 30px;

                .date-time-picker-seperator {
                    border-left: 1px solid #bdbdbd;
                    min-height: 20px;
                    max-width: 1px;
                }
            }

            .loc-select {
                padding-right: 10px;
                max-height: 40px;

                .ant-select-selector {
                    border-radius: 30px;
                }
            }

            .loc-search {
                background: #333333;
                border-radius: 45px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 10px;
            }
        }
    }

    .eq-s-list {
        border-bottom: 1px dashed #c4c4c4;
        padding-bottom: 5px;
        padding-top: 15px;

        .eq-event-header-icon {
            margin-left: 5px;
        }

        .eq-sl-heading {
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 23px;
            
            color: #000000;
        }

        .eq-sl-date {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 23px;
            text-transform: capitalize;
            color: #0081fe;
        }

        .eq-sl-icon {
            margin: 0px 0px 0px 10px;
        }
    }

    .eq-schedule-range {}

    .eq-s-row {
        background-color: white;
        border-radius: 10px;
        margin-top: 16px;
    }

    .eq-sl-row {
        @include Inter-14-500;
        background: none;
    }

    .eq-s-divider {
        border-bottom: 1px dashed #e5e5e5;
    }

    .eq-db-joinProducts {
        align-items: center;
        justify-content: space-around;
        padding: 48px 0;
        width: 100%;
    }
}