@import '../../shared/Vars.scss';
.eq-training-filters {
    padding: 10px 0px 10px 0px;

    .filter-col {
        padding: 0px 10px 0px 10px;
        @media(max-width:$sm){
            padding:5px;
        }
    }
}
