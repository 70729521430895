
.overall-container {
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    //margin: 0;
    //padding: 0;
    //box-sizing: border-box; 
    //display: flex;
 

    .previous-course-board {
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: none;
        padding: 20px;
        // max-width: 90%;
        //max-height: 90%;
        // overflow: auto;
    }
}
