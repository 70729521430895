@import "../../../shared/Vars.scss";

.eq-activity-collapse {
  .eq-activity-row {
    padding: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: $blackColor;
    cursor: pointer;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

    .eq-status-row-border {
      justify-content: space-between;
      flex-wrap: nowrap;

      .action-col {
        overflow: initial;
        padding-right: 10px;
        .ant-row {
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
        }
        .eq-event-actions-trash {
          padding-left: 4px;
        }
      }
    }

    @include responsive(xs) {
      font-size: 10px;
    }

    .eq-status-row-border {
      /*margin: 5px 0px 8px 0px;*/
      margin: 0px;
      align-items: center;
      .content-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .ant-row {
          flex-flow: nowrap;

          .header-details {
            .ant-row {
              flex-flow: wrap;
            }
            @media (max-width: $sm) {
              flex: 1;
            }
            .ant-row {
              flex-flow: wrap;
            }
          }
        }
      }
      .ant-col {
      }

      .eq-event-type {
        font-weight: 600;
        text-transform: capitalize;

        @media (max-width: $sm) {
          line-height: 22px;
        }

        .ant-tag {
          @media (max-width: $sm) {
            line-height: 13px;
            font-size: 11px;
            vertical-align: super;
          }
        }
      }
      .ant-tag {
        background-color: $lightTagGreenBG;
      }
      .trainer-link {
        text-transform: capitalize;
        color: $lightTagGrey;
        text-decoration: underline;
        font-weight: 500;
      }

      .eq-event-price-col {
        display: flex;
        flex-direction: row;

        .eq-event-price {
          font-weight: bold;
          margin-left: 5px;
        }
      }

      .ant-tag {
        // border-radius: 12px;
      }

      .eq-group-color-1 {
        background: #f6ffed;
        border: 1px solid #b7eb8f;
        color: #52c41a;
      }

      .eq-group-color-2 {
        background: #f0f5ff;
        border: 1px solid #adc6ff;
        color: #2f54eb;
      }

      .eq-group-color-3 {
        background: #fff1f0;
        border: 1px solid #ffa39e;
        color: #f5222d;
      }

      .eq-group-color-4 {
        color: purple;
      }

      .eq-event-actions {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        img {
          object-fit: cover;
          transition: background 0.2s, color 0.2s;

          &:hover {
            transform: scale(1.2);
            box-shadow: 0.1em 0.1em 1.2em #bbb;
          }
        }

        .eq-event-actions-edit {
          padding: 0px 5px;
        }

        .eq-event-actions-trash {
          padding: 0px 5px 0 4px;
        }
      }

      .date-icon {
        color: $darkbg;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        position: relative;
        font-weight: 900;
        margin-right: 12px;

        .eq-cal-icon {
          width: 34px;
          height: 34px;
        }

        .day {
          font-size: 15px;
          line-height: 15px;
          position: absolute;
          left: 8px;
          top: 13.5px;
        }

        .month {
          font-size: 12px;
          line-height: 12px;
        }
      }

      .time {
        font-weight: 700;
        margin-right: 12px;
      }

      .location {
        color: $lightTagGrey;
        margin-right: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 75px;
        display: inline-block;

        svg {
          vertical-align: text-top;
        }
      }

      .title {
        @media (max-width: $sm) {
          font-weight: 500;
          font-size: 12px;
        }
      }

      .price {
        margin-right: 12px;
        color: $lightTagGrey;

        svg {
          vertical-align: middle;
        }
      }

      .col-trancated {
        /*white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: 1;*/
      }

      .trainer {
        margin-right: 12px;

        svg {
          vertical-align: middle;
        }

        @media (max-width: $sm) {
          vertical-align: super;
        }
      }

      .attendees {
        margin-right: 12px;
        color: $lightTagGrey;

        svg {
          vertical-align: middle;
        }
      }
    }

    .eq-group-color-1 {
      color: green;
    }

    .eq-group-color-2 {
      color: blue;
    }

    .eq-group-color-3 {
      color: red;
    }

    .eq-group-color-4 {
      color: purple;
    }

    &:hover {
      transform: translateY(0) scale(1.01);
    }
  }

  .eq-event-card {
    font-family: Poppins;

    .eq-event-card-header {
      line-height: 22px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      
      color: #000000;

      .more-icon {
        font-size: 1.1rem;
      }
    }

    .eq-event-card-body {
      .eq-card-row {
        img {
          object-fit: cover;

          &.event-image {
            width: 100%;
            height: 200px;
          }
        }

        .heading-col {
          color: $makoColor;
          font-weight: 600;
          font-size: 13px;
          
          color: $blackColor;
          margin-bottom: 10px;
        }

        .ant-tag {
          // border-radius: 12px;
        }

        .price-info {
          display: flex;
          flex-direction: row;
          order: 1;

          .eq-event-price {
            margin-left: 5px;
            font-weight: 600;
          }
        }

        .trainer-link {
          text-transform: capitalize;
          color: $blackColor;
        }

        .actions-btn {
          cursor: pointer;

          img {
            object-fit: cover;
            transition: background 0.2s, color 0.2s;

            &:hover {
              transform: scale(1.2);
              box-shadow: 0.1em 0.1em 1.2em #bbb;
            }
          }

          .eq-event-actions-edit {
            padding-right: 5px;
          }

          .eq-event-actions-trash {
            padding: 0px 5px;
          }
        }
      }

      .eq-card-divider {
        border-bottom: 1px solid $rowBottomBorder;
        margin: 0px 20px 0px 20px;
        padding: 0px;
      }
    }
  }

  &:hover {
    .eq-activity-row {
      .eq-status-row-border {
        .trainer-link {
          color: $orangeColor;
        }
      }
    }
  }
}
