// Center content in the middle of the page

.eq-user-management {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; // Full viewport height
        background-color: #f5f5f5; // Light background color


        .card {
            background-color: #fff;
            padding: 2rem;
            border-radius: 1rem;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            width: 90%; // Adjusts to screen size
            max-width: 500px; // Limits maximum size
            text-align: center;
          
            h1 {
              font-size: 1.5rem;
              margin-bottom: 1rem;
            }
          
            button {
              margin-top: 1rem;
            }
          }
          
    }


  // Card styling for user management UI
