@import '../../shared/Vars.scss';


.eq-select {
    flex: 1;

    .ant-select {
        width: 100%;
        height: auto;
        /*padding-left: 16px;*/
        &:not(.ant-select-customize-input) {

            .ant-select-selector {
                border-radius: $inputBorderRadius;
            }
        }

        &.ant-select-single {
            .ant-select-selector {
                .ant-select-selection-item {
                    .text-description {
                        display: none;
                    }
                }
            }

            &.ant-select-show-arrow {
                .ant-select-selection-item {
                    @include Inter-14-regular;
                    // font-size: 14px;
                    // font-style: normal;
                    // font-weight: normal;
                    letter-spacing: 0em;
                    text-align: left;
                    padding-left: 8px;
                }
            }

            .ant-select-selection-item {
                .text-icon {
                    vertical-align: middle;
                }
            }
        }

        .ant-select-arrow .anticon {
            vertical-align: sub;
        }
    }

    //border-color: #3333ff;

    .sharp-border {
        &.ant-select {
            .ant-select-selector {
                border-color: $buttonToggleChecked;
            }

            &.ant-select-focused {
                .ant-select-selector {
                    border-color: $buttonToggleChecked;
                    box-shadow: none;
                }
            }

            &:hover {
                .ant-select-selector {
                    border-color: $buttonToggleChecked;
                }
            }

            .ant-select-arrow .anticon {
                color: $buttonToggleChecked;
            }
        }
    }

    .eq-label {
        @include Inter-14-regular;
        color: $darkBrownText;
        margin-bottom: 3px;
    }
}

.eq-card {
    .ant-select{
        width: 90%;
        &:not(.ant-select-customize-input) {

            .ant-select-selector {
                border-radius: 0;
            }
        }
        .ant-select-selector {
            border-width: 0 0 1px;
            height: 40px;
            // padding-left: 16px;
            border-radius:0px
        }

        .ant-select-selector:hover{
            border-width: 0 0 1px !important;
        }
        .ant-select-selector-focused{
            border-width: 0 0 1px !important;
            box-shadow: 0px 2px 0 0px #1890ff33;
     
    }
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 5px;
}

.ant-select-disabled{
    background: #fff;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background: #fff;
    color: #333333;
}
}
.extra-height {

    .ant-select {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                min-height: $inputHeight;
                align-items: center;
            }
        }
    }

    &.ant-select {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                min-height: $inputHeight;
            }
        }

        &.ant-select-single {
            .ant-select-selector {
                .ant-select-selection-item {
                    line-height: $inputHeight;
                }
            }

            &.ant-select-show-arrow {
                .ant-select-selection-item {
                    line-height: $inputHeight;
                }
            }
        }

        .ant-select-arrow .anticon {
            vertical-align: text-bottom;
        }
    }
}
.text-input-height {

    .ant-select {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                min-height: $textInputHeight;
                align-items: center;
            }
        }
    }

    &.ant-select {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                min-height: $textInputHeight;
            }
        }

        &.ant-select-single {
            .ant-select-selector {
                .ant-select-selection-item {
                    line-height: $textInputHeight;
                }
            }

            &.ant-select-show-arrow {
                .ant-select-selection-item {
                    line-height: $textInputHeight;
                }
            }
        }

        .ant-select-arrow .anticon {
            vertical-align: text-bottom;
        }
    }
}
.eq-multiSelect {
    // display: flex;
    flex: 1;

    .ant-select-selector {
        margin-right: 20px;

        .ant-select-selection-overflow {
            display: flex;
            // flex-direction: row-reverse;

            .ant-select-selection-overflow-item {

                .ant-tag {
                    // border-radius: $tagBorderRadius;
                    padding: 1px 8px;
                    margin: 0px 3px;

                    @include Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    color: $darkBlack;

                    .ant-tag-close-icon svg {
                        color: $darkBlack;
                        margin-top: -4px;
                    }
                }

                .text-description {
                    display: none;
                    font-size: 12px;
                    @include Inter;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0em;
                    color: $darkBrownText;
                }
            }
        }
    }

    .ant-select-clear {
        margin-right: 20px;
    }
}

.eq-addSelect {
    .ant-tag {
        background: #FFFFFF;
        border: 1px solid #ABD6FF;
        box-sizing: border-box;
    }

    .ant-select-selector {
        margin: 0px;
        padding: 0px;

        .ant-select-selection-overflow {
            margin-right: 20px;
        }
    }

    .ant-select {
        &.ant-select-single {
            &.ant-select-show-arrow {
                .ant-select-selection-item {
                }
            }
        }
    }


    .ant-select-arrow {
        top: 45%;
    }
}

.eq-Bottom-select {
    .ant-tag {
        background: #FFFFFF;
        border: 1px solid #ABD6FF;
        box-sizing: border-box;
    }

    .ant-select-selector {
        margin: 0px;
        padding: 0px;

        .ant-select-selection-overflow {
            margin-right: 20px;
        }
    }

    .ant-select {
        &.ant-select-single {
            &.ant-select-show-arrow {
                .ant-select-selection-item {
                }
            }
        }
    }


    .ant-select-arrow {
        top: 45%;
    }
}
.eq-select-dropdown {
    .ant-select-item-option {
        .text-description {
            display: block;
            font-size: 11px;
            @include Inter;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0em;
            color: $darkBrownText;
        }
    }

    .ant-select-item-option-selected {

        &:not(.ant-select-item-option-disabled) {
            background-color: $whiteColor;
            font-weight: normal;
        }
    }

    .eq-opt-icon {
        padding-right: 5px;
    }

    .eq-opt-icon img {
        margin-top: -5px;
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $whiteColor;
    font-weight: normal;
}

.eq-select-sort {
    line-height: 40px;
    margin-bottom: 3px;


    .anticon svg {
        margin-top: -3px;
    }
}

.eq-searchSelect {
    .ant-select-arrow {
        top: 16%;
        right: 3%;
        width: 24px;
        min-height: 100%;
        // border-left: 1px solid #D9D9D9;
        padding-left: 12px;
    }

    .ant-select-selector {
        margin: 0px;
        padding: 0px;

        .ant-select-selection-overflow {
            padding-right: 30px;
        }
    }

    .eq-search-icon img {
        padding-top: 0px;
        padding-left: 8px;
    }

    .ant-select-clear {
        margin-right: 30px;
        margin-top: -5px;
    }

    .eq-opt-icon {
        padding-right: 5px;
    }

    .eq-opt-icon img {
        margin-top: -5px;
    }
}

.eq-email-search {
    .ant-select-selection-overflow-item {
        padding-left: 10px;

        .text-description {
            display: block;
            font-size: 11px;
            @include Inter;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0em;
            color: $darkBrownText;
        }

        &.ant-select-selection-overflow-item-suffix {
            padding-left: 10px;
        }
    }

    .ant-select-arrow {
        display: none;
    }

    .ant-tag {
        background: #F5F5F5;
        border: 1px solid #F0F0F0;
        // border-radius: $tagBorderRadius;
        padding: 1px 8px;
        margin: 0px 3px;
        @include Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $darkBlack;

        .ant-tag-close-icon svg {
            color: $darkBlack;
            margin-top: -4px;
        }
    }
}

.eq-bottom-border {
    .ant-select{
        &:not(.ant-select-customize-input) {

            .ant-select-selector {
                border-radius:0;
            }
        }
        .ant-select-selector{
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #d9d9d9; /* Set the desired bottom border color */
            transition: border-bottom 0.3s ease; /* Add a transition for a smooth effect */
            
        }
    }
  }