@import '../../shared/Vars.scss';
.eq-row {
    padding: 17px 16px;
    @include Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $darkBlack;
    border-bottom: 2px solid $rowBottomBorder;

    .eq-col-discipline {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: #626262;
        background: $whiteColor;
        border: 1px solid $disciplineBorder;
        box-sizing: border-box;
        border-radius: 37px;
        padding: 2px 10px;
        margin-right: 5px;
    }
}
.eq-card-header {

}
.eq-card-body {
    .eq-card-row {
        padding: 17px 16px;

        .eq-card-col-heading {
            line-height: 22px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            
            color: $blackColor;
        }

        .eq-col-discipline {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: $darkBrownText;
            background: $whiteColor;
            border: 1px solid $disciplineBorder;
            box-sizing: border-box;
            border-radius: 37px;
            padding: 2px 10px;
            margin: 5px 5px 5px 0px;
        }
    }

    .eq-card-divider {
        border-bottom: 1px solid $rowBottomBorder;
        margin: 0px 20px 0px 20px;
        padding:0px;
    }
}