@import '../../shared/Vars.scss';
.eq-date-pick {
    .ant-picker {
        width: 100%;
    }
    .eq-label {
        @include Inter-14-regular ;
        color: $darkBrownText;
        margin-bottom: 3px;
    }

    &.eq-event {

        .ant-picker {
            border-radius: $inputBorderRadius;
            height: 40px;
            padding-left: 16px;
        }
    }

    .eq-schedule {

        .ant-picker-input {
            > input {
                text-align: center;
            }
        }
    }
}
