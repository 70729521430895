@import '../shared/Vars.scss';

.confirm-email {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    flex: 1;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 60%;

    .confirm-email-error {
        color: $redColor;
        font-weight: 500;
        font-size: 16px;
    }

    .confirm-email-success {
        font-weight: 500;
        font-size: 16px;
        margin: 12px auto;
        color: $mainColor;
    }
}
