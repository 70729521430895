@import "../../shared/Vars.scss";


.eqProductInvitation {
    background-color: $whiteColor;
    padding: 10px 0px 20px 0px;

    .filters {
        background-color: #FAFAFA;
        padding: 16px;
        border-radius: 12px;
        margin-top: 20px;
        margin-bottom: 25px;

        .picker-label {
            @include Inter-14-regular-btn;
            font-weight: 500;
            padding: 35px 35px 35px 35px;
        }

        .filter-type {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .eq-input-info {
                width: 100%;
            }

            .eq-input-info-button {
                width: 100%;
                max-width: 130px;
                //min-width: 100px;
                margin-left: 10px;
                margin-top: 20px;
                margin-bottom: 10px;
            }

            .eq-filter-radius {
                border-radius: 5px;

                .ant-select-selector {
                    border-radius: 5px;
                }
            }

            .search-col {
                margin: auto;
            }
        }
    }

    .eq-fl-th {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }


    .eq-event-heading-row {
        padding: 4px 0px 0px 0px;
        margin: 0px 0px 15px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-event-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-event-heading {
                @include poppins-24-regular;
                padding: 0px 0px 0px 0px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        .eq-event-buttons {
            margin: 0px 0px 0px 5px;

            @media (max-width:$sm) {
                margin: 0px 0px 5px 5px;
            }
        }
    }

    .eq-db-joinProducts {
        justify-content: space-around;
        align-items: center;
        padding: 48px 0px;
        width: 100%;
    }

    .eq-fl-row {
        @include Inter-14-500;
        margin-top: 22px;
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        border-bottom: 2px solid $graylightborder;

        .eq-fl-th {
            padding: 0px;
        }
    }



    .action-all-btn {
        .ant-float-btn {
            @include poppins-14-regular;
            right: 50px;
            bottom: 47%;
            border-radius: 20px;
            min-width: 100px;
            min-height: 30px;

            .ant-float-btn-body {
                background-color: #FF8838;
                border-radius: 20px;

                .ant-float-btn-content {
                    min-height: 30px;

                    .ant-float-btn-description {
                        @include poppins-14-regular;
                        color: #fff;
                        padding: 7px 0;
                    }
                }
            }
        }
    }



    .product-invite {
        width: 100%;

        .product-action {
            .action {
                color: $action;
                @include Inter-14-500;
                text-decoration: underline;
                cursor: pointer;
                border: none;
                box-shadow: none;

                .action-btn-text {
                    text-decoration: underline;
                }
            }
        }
    }
}

 .EqInput-Course {
    width: 100%;
    Width: 600px;
    min-Width: 200px;
    //padding-Left: 30px;
    //padding-Bottom: 10px;
    //padding-top: 10px;
    margin-top: 10px;
    margin-left: 25px;
}