@import "./../../shared/Vars.scss";

.eq-userOption {
    .eq-toggle-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .radio-btn {
            @include poppins-18-semibold;
            width: 200px;
            height: 70px;
            margin: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center; // Centers the text horizontally
            border-radius: 5px; // Optional: to match the button style
        // Hover state
        &:hover {
            background-color: #e6e6e6; // Light gray background on hover
            color: $eqBgColor;
        }

        // Selected state styles
        &.ant-radio-button-wrapper-checked {
            background-color: $eqBgColor;
            color: white;
            border-color: $eqBgColor;
        }

        // Focus state styles (for accessibility)
        &:focus {
            border-color: $eqBgColor;
            box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.2); // Orange outline
        }
        }
    }

    .eq-toggle-group:not(.ant-radio-button-wrapper-disabled)::before {
        display: none;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        display: none;
    }
    
}
