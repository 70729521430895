@import '../../shared/Vars.scss';

.eq-tr-view {
    display: flex;
    //align-items: center;

    .eq-overview {
        // gap: 10px;
        display: flex;
        justify-content: space-between;

        .eq-col-view {
            width: 100%;

            .eq-training-overview {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                border-radius: 20px;
                background: #F8F8F8;
                //background: linear-gradient(45deg, rgba(248, 248, 248, 1) 0%, rgb(223 220 220) 100%);
                background: #F8F8F8;
                padding: 40px 15px 40px 20px;

                .overview-info {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .total-money-icon {
                        width: 52px;
                        height: 52px;
                        background-color: #fff;
                        border-radius: 50%;
                        padding: 10px;

                        @media screen and (max-width: 760px) {
                            width: 40px;
                            height: 40px;
                        }

                    }

                    .basic-icon {
                        width: 40px;
                        height: 40px;
                        background-color: #fff;
                        border-radius: 50%;
                        padding: 10px;
                    }

                    .heading {
                        @include poppins-20-semibold;
                        margin: 0;

                    }

                    .text {
                        @include Inter-14-regular;
                        margin: 0;
                        margin-bottom: 20px;
                        margin-right: 4px;
                    }
                }

                .dec-overview {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-top: 30px;

                    .status-unpaid {
                        color: #FF3C11;
                    }

                    .status-non-response {
                        color: #FFB800;
                    }

                    .status-earning {
                        color: #02B91F;
                    }


                    .overview-status {

                        .count-overview {
                            @include poppins-36-semibold;
                            font-size: 40px;

                        }

                        .status-std {
                            @include Inter-16-regular;
                        }
                    }

                    .overview-msg {
                        cursor: pointer;

                        .msg {
                            @include Inter-14-regular;
                            color: #ff8838;
                            font-size: 12px;
                            margin-left: 7px;
                            text-decoration-line: underline;
                        }
                    }
                }

            }
        }

        .training-overview {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 20px;
            border-radius: 20px;
            //background: linear-gradient(45deg, rgba(248, 248, 248, 1) 0%, rgb(223 220 220) 100%);
            background: #F8F8F8;

            .count-heading {
                @include poppins-20-semibold;
                color: #000;
            }

            .training-count {
                @include poppins-24-semibold;
                margin-top: 16px;
                font-size: 40px;
                color: #FF8838;
            }
        }

        .training-overview-cal {
            padding: 10px 0px;
            border-radius: 20px;
            //background: linear-gradient(45deg, rgba(248, 248, 248, 1) 0%, rgb(223 220 220) 100%);
            background: #F8F8F8;

            .ant-picker-calendar {
                background: transparent;

                .ant-picker-calendar-header {
                    justify-content: center;

                    .ant-select-open {
                        .ant-select-selector {
                            border-color: #ff8838;
                            box-shadow: 0 0 0 2px rgba(255, 136, 56, 0.2)
                        }
                    }

                    .ant-picker-calendar-mode-switch {

                        .ant-radio-button-wrapper:hover {
                            color: #ff8838;
                        }

                        .ant-radio-button-wrapper-checked {
                            border-color: #ff8838;
                            color: #ff8838;
                        }

                        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                            background-color: #ff8838;
                        }
                    }
                }
            }

            .ant-picker-panel {
                background: transparent;

                .ant-picker-cell-in-view.ant-picker-cell-selected {
                    .ant-picker-cell-inner {
                        background-color: #ff8838;
                    }

                    .ant-picker-cell-inner::before {
                        border: 1px solid #ff8838;

                    }
                }

                .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
                    border: 1px solid #ff8838;

                }

            }

            .calendar-event-list {
                // border-top: 2px solid #fff;
                margin-top: 2px;
                padding: 5px 15px 0 15px;

                .event-list {
                    // border-bottom: 4px solid #fff;

                    .date-event {
                        color: var(--light-secondary, #666);
                        @include Inter-14-regular;
                        font-size: 12px;
                    }

                    .name-event {
                        color: var(--light-primary, #333);
                        @include Inter-14-semibold
                    }
                }

                .overview-msg {
                    cursor: pointer;

                    .msg {
                        color: #ff8838;
                        font-size: 10px;
                        text-decoration-line: underline;
                    }

                }
            }
        }

        .no-training-avali {
            @include Inter-14-regular;
            margin-top: 2px;
            padding: 5px 15px 0 15px;

        }

    }

}

.send-reminder-modal {
    .ant-modal-content {
        .ant-modal-header{
            .ant-modal-title {
                @include poppins-14-semibold;
                
                color: #FF8838;
            }
        }
        .ant-modal-body {
            .send-mail-input {
                width: 100%;
                // white-space: pre-line;
                display: flex;
                flex-wrap: wrap;
                padding: 18px 12px;
                align-items: flex-start;
                gap: var(--4, 7px);
                border-radius: 2px;
                border: 1px solid #C4C4C4;
                background: #FFF;
            }
        }

        .ant-modal-footer {
            .ant-btn {
                border-radius: 22px;
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            }

            .ant-btn-primary {
                background: #FF8838;
                border-color: #FF8838;
            }
        }
    }
}