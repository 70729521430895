@import '../../../shared/Vars.scss';

.eq-create-attendee-card {
    margin-bottom: 10px;
    position: relative;
    @include Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $darkBrownText;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


    .at-card-header {
        .at-header-row {
            justify-content: space-between;
            flex-wrap: nowrap;
            // margin: 0px;
            align-items: center;


            .content-col {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .ant-row {
                    flex-flow: wrap;

                    //   .header-details {
                    //     .ant-row {
                    //       flex-flow: wrap;
                    //     }
                    //     @media (max-width: $sm) {
                    //       flex: 1;
                    //     }
                    //     .ant-row {
                    //       flex-flow: wrap;
                    //     }
                    //   }
                }
            }


            .action-col {
                overflow: initial;

                // padding-right: 10px;
                .ant-row {
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;

                }

                .trash-bin {
                    padding: 0px 4px 3px 4px;
                }

                .eq-event-actions-trash {
                    padding-left: 4px;
                }
            }
        }
    }


    .ant-tabs {

        .ant-tabs-nav {
            background-color: $trainerMessageListHeaderBackground;

            .ant-tabs-nav-list {
                .ant-tabs-tab-btn {
                    @include poppins-14-regular-btn;
                    transition: color 0.3s;

                    &:hover {
                        color: #FF8838;
                    }
                }

                .ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: #FF8838;
                    }
                }

                .ant-tabs-ink-bar {
                    background: #FF8838;
                }
            }
        }

    }




    .tab-container {
        padding: 0px 16px 16px 16px;

        @media(max-width:$sm) {
            padding: 0px;
        }
    }

    .eq-card-header {

        @include responsive(xs) {
            font-size: 11px;
            line-height: 13px;
        }
    }

    .name-number-input {
        .eqInput-number {
            width: 100%;

            .ant-input-number {
                padding-left: 3px;
            }
        }

        .eqInput {
            width: 100%;

        }
    }

    .eq-green-header {
        background-color: $lightGreenBgClr;
    }

    .eq-sel-date {
        &.ant-col {
            padding-right: 5px;
        }
    }

    .header-label {
        margin-right: 12px;
    }

    .card-body {
        padding: 10px;

        .ant-tabs {

            .ant-tabs-nav {
                background-color: $trainerMessageListHeaderBackground;
    
                .ant-tabs-nav-list {
                    .ant-tabs-tab-btn {
                        @include poppins-14-regular-btn;
                        transition: color 0.3s;
    
                        &:hover {
                            color: #FF8838;
                        }
                    }
    
                    .ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: #FF8838;
                        }
                    }
    
                    .ant-tabs-ink-bar {
                        background: #FF8838;
                    }
                }
            }
    
        }


        .eq-email-tag {
            margin-top: 5px;

            &.ant-tag {
                border-radius: $tagBorderRadius;
            }
        }

        .label-h1 {
            color: $darkBrownText;
            
            margin: 10px 0px;
            font-size: 13px;
        }

        .label-h2 {
            color: $darkBrownText;
            text-transform: capitalize;
            font-size: 12px;
            margin: 0px 0px 5px 9px;
        }

        @media(max-width:$sm) {
            padding: 0px 4px;
        }
    }


    .eq-std-row {
        @include Inter-14-regular;
        padding: 17px 16px;
        color: $darkBrownText;
        font-weight: 500;
        border-bottom: 2px solid $rowBottomBorder;
        align-items: center;

        .eq-std-th {
            padding: 0px;
        }

        .eq-std-td {
            font-size: 12px;
            font-weight: normal;
            padding: 0 5px 0 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;

            .eq-up-img {
                img {
                    height: 20px;
                    width: 20px;
                }
            }

            .eq-up-name {
                font-weight: 500;
                color: $blackColor;
            }
        }


      
        .attendeeStatus{
            text-align: center;
            border-radius: 50px;
            padding: 3px 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        
        }
        
        .action-col {
            .ant-row {
                justify-content: flex-end;
                flex-wrap: nowrap;

            }

            .trash-bin {
                padding: 0px 4px 3px 4px;
            }

            .eq-event-actions-trash {
                padding-left: 4px;
            }
        }

        .switch-input{
        
            width: 100%;
            height: 116%;
            .ant-switch-handle{
                top: 4px;
            }
        }
    }

    .eq-btn-icon {
        position: absolute;
        bottom: 0;
        right: -50px;
        display: flex;
        z-index: 9;
        overflow: visible;
        transition: all ease-out 0.3s;
        border-radius: 50%;
        background-color: #E5E5E5;
        padding: 12px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        @media(max-width:$sm) {
            bottom: 63px;
            right: -16px;
        }

        img {
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
            cursor: pointer;
            height: 20px;
            width: 20px;
            padding: 5px;


            @media(max-width:$sm) {
                height: 22px;
                width: 22px;
                // padding: 6.5px;
            }
        }

        &:hover {
            transform: scale(1.03);
        }
    }

    @media(max-width:$sm) {
        // margin-bottom: 45px;
    }

    /*    .tab-container {
        padding: 0px 16px 16px 16px;
    }*/
}