

.eq-create-or-edit-event{


.eq-create-event-container {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 15px;

    .eq-create-event-header {
        margin-bottom: 20px;
    }
    .eq-create-event-title {
        margin: 0;
    }
    .eq-create-event-divider {
        margin: 20px 0;
        border-top: 2px solid rgba(0, 0, 0, 0.06);
    }

    .eq-create-event-input {
        margin-bottom: 20px;

        .switch-container {
            display: flex;
            align-items: center;
            gap: 10px; /* Adjust the gap value as needed */
          }
      }


}

.evt-tooltip{
    background-color: #fff;
    color: #000;
    
}

.eq-create-event-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.eq-create-event-footer {
    margin-top: 20px;
    text-align: right;
}

.ql-container{
    min-height: 100px;
}
}