@import '../../shared/Vars.scss';

.payment-board {
    .filters {
        background: #FAFAFA;
        padding: 16px;
        border-radius: 12px;
        .picker-label {
            font-weight: 500;
            @include Inter-14-regular-btn;
        }
        .filter-type {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .eq-input-info {
                width: 100%;
            }
            .eq-filter-radius {
                border-radius: 5px;
                .ant-select-selector {
                    border-radius: 5px;
                }
            }
        }
    }
    .ant-tabs {
        .ant-tabs-nav-list {
            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;
                transition: color 0.3s;
                &:hover {
                    color: #FF8838;
                }
            }
            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;
                }
            }
            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }




    .eq-fl-row {
        @include Inter-14-500;
        margin-top: 22px;
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;

        border-bottom: 2px solid $graylightborder;

        .eq-fl-th {
            padding: 0px;
        }
    }

    .eq-list-checkAll {
        padding: 17px 16px;
        color: $darkBlack;
        @include Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
}