@import "../../shared/Vars.scss";

.training-request-drawer {
	.drawer-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  padding: 16px;
	//   background-color: #f5f5f5;
	}
  
	.drawer-content {
	  padding: 16px;
  
	  h3 {
		margin-bottom: 16px;
	  }
  
	  p {
		margin-bottom: 16px;
	  }
  
	  .request-row, .waiting-row {
		padding: 8px 0;
		border-bottom: 1px solid #f0f0f0;
		// justify-content: space-between;
		strong {
		  display: block;
		}
  
		// span {
		//   color: #888;
		// }
  
		.ant-col {
		//   display: flex;
		  align-items: center;
		}
	  }
	}
  }
  