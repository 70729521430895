@import "../../shared/Vars.scss";

.eq-stb-container {
    background-color: white;
    padding: 20px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width: $sm) {
        padding: 10px;
    }

    .eq-stb-heading-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-stb-heading {
            @include poppins-24-regular;
            margin-bottom: 10px;
            padding: 0px;
            color: $makoColor;

            @media (max-width: $sm) {
                /*font-size: 16px;
                line-height: 21px;*/
            }
        }
    }

    .eq-section {
        padding-top: 20px;
        padding-bottom: 20px;

        @media (max-width: $sm) {
            .hidden-sm-students {
                display: none !important;
            }
        }

        .eq-db-h2 {
            @include poppins-18-semibold;
            // font-family: Poppins;
            // font-style: normal;
            // font-weight: 600;
            // font-size: 16px;
            // line-height: 26px;

            color: $blackColor;
            margin-bottom: 10px;

            @media (max-width: $sm) {
                padding-left: 16px;
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-db-h2-collapse {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;

            color: $blackColor;
            margin-bottom: 10px;
            padding: 10px 0px 0 16px;

            @media (max-width: $sm) {
                // padding-left: 16px;
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-events-add {
            padding: 5px 20px;
            margin-right: 16px;
            background: #ffffff;
            border: 1px dashed #0081fe;
            box-sizing: border-box;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 22px;
            @include Inter;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.85);

            @media (max-width: $sm) {
                margin-left: 16px;
                margin-bottom: 10px;
            }

            .plus-icon {
                margin-right: 10px;
            }
        }

        .eq-el-editCal {
            margin-right: 16px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-transform: capitalize;
            color: $blueDate;

            @media (max-width: $sm) {
                padding: 10px 16px 10px 16px;
            }

            .eq-el-editCal-icon {
                margin-right: 10px;

                .eq-el-editCal-icon img {
                    width: 17px;
                    height: 24px;
                    padding-bottom: 5px;
                }
            }
        }

        .eq-event-header-icon img {
            padding: 5px 0px 5px 10px;
        }

        .eq-financials-header-right {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            text-align: right;
            text-transform: capitalize;
            display: flex;
            padding-bottom: 10px;

            @media (max-width: $sm) {
                padding-left: 16px;
            }

            .eq-financials-total {
                color: #000000;
            }

            .eq-financials-link {
                color: #0081fe;
            }
        }

        .eq-db-row {
            .eq-db-icon-row {
                @media (max-width: $sm) {
                    &.ant-row-bottom {
                        align-items: flex-start;
                    }
                }
            }

            .eq-db-icons {
                @media (max-width: $sm) {
                    .eq-db-icon {
                        padding-bottom: 12px;

                        img {
                            height: 32px;
                        }
                    }

                    .eq-db-icon-text {
                        padding-left: 2em;
                        padding-right: 2em;
                    }
                }

                .eq-db-icon {
                    text-align: center;

                    .eq-db-icon {
                        img {
                            width: 64px;
                        }
                    }
                }

                .eq-db-icon-text {
                    @include Inter-14-regular;
                    margin-top: 10px;
                    // font-family: "Poppins";
                    // font-style: normal;
                    // font-weight: 400;
                    // font-size: 13px;
                    // line-height: 20px;
                    text-align: center;
                    color: #000000;
                }
            }

            .eq-db-invite {
                @media (max-width: $sm) {
                    margin-top: 20px;
                }

                .eq-pb-invite-info {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: $makoColor;

                    @media (max-width: $sm) {
                        padding: 0px 16px;
                        font-size: 14px;
                        line-height: 23px;
                    }
                }

                .eq-pb-btn {
                    margin: 10px 0px;
                    height: 40px;

                    @media (max-width: $sm) {
                        padding: 0px 16px;
                    }
                }
            }
        }

        .eq-msg-header {
            padding: 0px 16px 10px 0px;

            @media (max-width: $sm) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            }

            .eq-msg-icons {
                padding: 5px;
                padding-bottom: 10px;
                max-width: 32px;
            }
        }

        .eq-events-header {
            padding: 0px 8px;

            @media (max-width: $sm) {
                padding: 10px 16px 10px 16px;
            }

            .eq-el-heading {
                @include poppins-14-regular;
                margin: 0px;
                padding-left: 8px;
                // font-family: "Poppins";
                // font-style: normal;
                // font-weight: 300;
                // font-size: 14px;
                // line-height: 23px;

                color: #000000;
            }

            .eq-el-dateHeading {
                display: flex;
                flex-direction: row-reverse;

                .eq-el-date {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: $blueDate;
                    line-height: 23px;
                    margin: 0px 10px 0px 0px;
                    padding-top: 5px;

                    @media (max-width: $sm) {
                        padding-top: 0px;
                        font-weight: 600;
                    }
                }

                .eq-el-date-icon {
                    padding: 0px 10px 0px 0px;
                }
            }
        }

        .eq-list-header {
            background: $trainerMessageListHeaderBackground;
            padding: 17px 16px;
            color: $darkBlack;
            @include Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            border-bottom: 2px solid $rowBottomBorder;
            margin: 0px;
        }

        .eq-event-th {
            padding: 10px 16px 10px 16px;
            margin: 0px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-transform: capitalize;
            color: $makoColor;
        }
    }

    .eq-section-divider {
        border-bottom: 1px solid #c4c4c4;
    }

    .eq-db-joinProducts {
        justify-content: space-around;
        align-items: center;
        padding: 48px 0px;
        width: 100%;
    }

    .eq-section-training {
        // margin-bottom: 40px;

        .eq-section-training-filter {
            padding: 15px 10px 0 10px;

            .eq-filter-date {
                width: 100%;
                width: 100%;
                height: 40px;
                border-radius: 5px;
            }

            .eq-filter-btn {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .eq-filter-btn-icon {
                    margin-right: 7px;
                }
            }
        }
    }
}

.unpaid-Orders-modal {
    .eq-unpaid-row {
        justify-content: space-between;
        flex-wrap: nowrap;
        border-radius: 2px;
        box-shadow: 1px 2px 4px #c6c6c6;
        padding: 8px;

        .content-col {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
        }
    }

    .eq-activity-row {
        border-radius: 2px;
        box-shadow: 1px 2px 4px #c6c6c6;
        background: #fafafa;
        border-bottom: 2px solid #f0f0f0;
        padding: 8px 16px;
        margin: 3px 0 0;
        @include poppins-14-regular;

        color: $blackColor;
        cursor: pointer;
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

        .eq-status-row-border {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            margin: 0px;
            align-items: center;

            .content-col {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                align-items: center;

                .Header-col {
                    flex: 1 1 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100%;
                    margin-right: 20px;
                    margin-top: 10px;
                    align-items: center;
                    overflow: hidden;
                }

                .date-icon {
                    color: $darkbg;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    justify-content: center;
                    position: relative;
                    font-weight: 900;
                    margin-right: 12px;

                    .eq-cal-icon {
                        width: 34px;
                        height: 34px;
                    }

                    .day {
                        font-size: 15px;
                        line-height: 15px;
                        position: absolute;
                        left: 8px;
                        top: 13.5px;
                    }

                    .month {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                .header-details {
                    .ant-row {
                        align-items: center;
                        flex-flow: wrap;
                    }
                    @media (max-width: $sm) {
                        flex: 1;
                    }
                }

                .time {
                    font-weight: 700;
                    margin-right: 12px;
                }
            }

            .title {
                color: #000000cc;
                @include poppins-14-semibold;
                @media (max-width: $sm) {
                    font-size: 12px;
                }
            }
        }
    }
}
