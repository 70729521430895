@import '../../../shared//Vars.scss';

.eq-message-list-item {
    .eq-message-container {


        .eq-message-item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .eq-message-read {
            @include Inter-14-regular;
            padding: 17px 16px;
            color: $darkBlack;
            border-bottom: 2px solid $rowBottomBorder;

            @include responsive(xs) {
                font-size: 10px;
                padding: 12px 12px;

                .eq-message-item-type {
                    font-weight: 700;
                }
            }



            .eq-message-item-action {
                cursor: pointer;

                .eq-message-action {
                    padding-left: 5px;

                    img {
                        transition: background 0.2s, color 0.2s;

                        &:hover {
                            transform: scale(1.2);
                            box-shadow: 0.1em 0.1em 1.2em #bbb;
                        }
                    }

                    .eq-message-action-reply {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-flag {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-delete {
                        flex: 1;
                        max-width: 32px;
                    }
                }

                .subject {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                    font-weight: 400;
                    line-height: 22px;
                    margin-top: 9px;
                }
            }
        }

        .eq-message-unread {
            @include Inter-14-regular;
            padding: 17px 16px;
            font-weight: bold;
            color: $darkBlack;
            border-bottom: 2px solid $rowBottomBorder;

            @include responsive(xs) {
                font-size: 10px;
                padding: 12px 12px;

                .eq-message-item-type {
                    font-weight: 700;
                }
            }

            .eq-message-item-action {
                cursor: pointer;

                .eq-message-action {
                    // display: flex;
                    // flex-direction: row;
                    padding-left: 5px;

                    img {
                        transition: background 0.2s, color 0.2s;

                        &:hover {
                            transform: scale(1.2);
                            box-shadow: 0.1em 0.1em 1.2em #bbb;
                        }
                    }

                    .eq-message-action-reply {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-flag {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-delete {
                        flex: 1;
                        max-width: 32px;
                    }
                }

                .subject {
                    margin-top: 9px;
                }
            }
        }

        .tag-body .tag {
            height: 17px;
            line-height: 17px;
            padding: 0 6px 0 12px;
        }

        .tag-body .tag::before {
            height: 6px;
            left: 3px;
            position: absolute;
            width: 6px;
            top: 5px;
        }

        .ant-avatar-group {
            display: inline-flex;
            flex-direction: column;
        }

        .ant-avatar-group .ant-avatar:not(:first-child) {
            margin-left: 0px;
            margin-top: -18px;
        }

        .msg-date-time {
            font-size: 12px;

            &.read {
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
}
