@import '../../shared/Vars.scss';
.eq-list-header {
    margin-bottom: 20px;

    .eq-list-heading {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        
        color: #000000;
    }

    .eq-list-icon {
        margin: 0px 5px;
    }
}
.eq-list-th {
    background: $grayHeading;
    padding: 17px 16px;
    color: $darkBlack;
    @include Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    border-bottom: 2px solid $rowBottomBorder;
    margin: 0px;
}
.eq-list-checkAll {
    padding: 17px 16px;
    color: $darkBlack;
    @include Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}