@import "../../../shared/Vars.scss";

.eq-sendMessage {
    border: 2px solid #f0f0f0;
    padding: 10px;
  .msg-title {
    @include poppins-18-semibold;
  }

  .action-row {
    padding: 5px;
    border-top: 1px solid #f0f0f0; // Add top border
    border-bottom: 2px solid #f0f0f0;
  }

  .eq-text-area-border {
    border: none;
    border-bottom: 1px solid #d9d9d9; /* Set the desired bottom border color */
    transition: border-bottom 0.3s ease; /* Add a transition for a smooth effect */
  }
  
  // .eq-bottom-border:focus {
  //   // border-bottom: 2px solid #1890ff; /* Set the desired bottom border color when focused */
  // }
}
