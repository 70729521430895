// EqJoinSingleEvent.scss
@import "../../../components/shared/Vars.scss";
.eq-join-single-event {
    // Style for the main container
    background-color: $whiteColor;
    padding: 10px 0px 20px 0px;
    font-size: 13px;
    h2 {
        margin-bottom: 20px;
    }
    .alternative-collapse {
        margin-bottom: 5px;
        // border-radius: 5px;

        .ant-collapse-header {
            background-color: #fff;
            // border-radius: 5px;
        }

        .ant-collapse-content {
            border-top: 0px;
        }
    }
    .class-collapse {
        margin-bottom: 5px;
        .ant-collapse-header {
            // background-color: $lightOrange;
            // border-radius: 5px;
        }
        div {
            @include responsive(xs) {
                font-size: 12px;
            }
        }
    }

    // }

    button {
        margin-top: 20px; // Margin for buttons
        width: 100%; // Full width buttons
    }

    .back-button {
        margin-right: 10px; // Space between buttons
    }
    .icon-clip {
        color: rgb(255, 136, 56);
        border: 2px solid;
        padding: 8px;
        border-radius: 50%;
        margin-right: 5px;
    }
    .icon {
        height: 5px;
        color: #808080;
        padding: 5px;
        :hover {
            color: #ff8838;
        }
    }

    .question-card {
        border-left: 5px solid $orangeColor;
        width: 95%;
        border-radius: 6px;
        margin-bottom: 10px;

        .ant-card-head {
            min-height: 30px;
            border-bottom: none;
            .ant-card-extra {
                padding: 10px 0px 0px;
            }

            // font-size: 12px;
            div{
                @include responsive(xs) {
                    font-size: 12px;
                }
            }
        }
        .ant-card-body {
            padding: 5px 24px 24px 24px;

            div {
                font-size: 12px;
            }
        }
    }

    .register-event-gust {
        margin-top: 25px;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;

        .section-heading {
            text-align: center;
            width: 100%;

            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            margin-bottom: 5px;
            border-bottom: 1px dashed #c4c4c4;
        }

        .section-sub-heading {
            text-align: center;
            width: 100%;

            // margin-bottom: 5px;
            // border-bottom: 1px dashed #c4c4c4;
        }
    }
}
