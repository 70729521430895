@import '../../shared/Vars.scss';
.eq-card-header {
    @include poppins-14-semibold;
    background: #FAFAFA;
    padding: 17px 16px;
    color: $darkBlack;
    border-radius:5px;
    border-bottom: 2px solid #F0F0F0;
    margin: 0px;

    .group-header-arrow {
        margin: 8px 0px;
        text-align: right;
        width: 5px;
    }
}
.eq-transaparent-header {
    background: none;
    border-bottom: none;
}
.eq-card-body {
    @include Inter-14-regular
    
}
