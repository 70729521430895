

@import '../shared/Vars.scss';
.eq-category {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 250px;
    height: 250px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    .category-image {
        width: 100%;
        height: 100%;
        border-radius: 7px;
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: #fff;
        transition: 2s;
        .caption {
            @include poppins-18-regular-btn;
            color: #202020;
            // font-size: 18px;
            text-align: center;
            // font-weight: 500;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding-left: 10%;
            padding-right: 10%;
            @media (max-width: 900px) {
                font-size: 13px;
            }
            @media (max-width: 540px) {
                font-size: 20px;
            }
            .anticon {
                transform: rotate(45deg);
            }
        }
    }

    // .overlay {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     color: #525757;
    //     font: 30px sans-serif;
    //     font-weight: bold;
    //     opacity: 0.9;
    //     /*transition: opacity .5s ease;*/
    //     background-color: #fff;
    //     border-radius: 11%;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     transition: all ease 0.5s;
    //     &:hover {
    //     }

    //     .caption {
    //         color: #525757;
    //         text-align: center;
    //             @media (max-width: 900px) {
    //             font-size: 20px;   
    //             }
    //             @media (max-width: 540px) {
    //                 font-size: 30px;
    //             }
    //         }
    //         .category-icon {
    //             @media (max-width: 900px) {
    //                     width: 72px;
    //                     height: 80px;
    //                 }
    //                 @media (max-width: 540px) {
    //                     width: 128px;
    //                     height: 120px;
    //                 }
    //         }

    // }
    &:hover {
        .overlay {
            width: 100%;
            opacity: 0.7;
        }
    }
    @media (max-width: 1440px) {
        width: 245px;
        height: 245px;
    }
    @media (max-width: 1366px) {
        width: 235px;
        height: 235px;
    }
    @media (max-width: 1280px) {
        width: 220px;
        height: 220px;
    }
    @media (max-width: 1024px) {
        width: 175px;
        height: 175px;
    }
    @media (max-width: 912px) {
        width: 155px;
        height: 155px;
    }
    @media (max-width: 820px) {
        width: 138px;
        height: 138px;
    }
    @media (max-width: 768px) {
        width: 129px;
        height: 129px;
    }
    @media (max-width: 540px) {
        width: 250px;
        height: 250px;
    }

}
