@import "../../../components/shared/Vars.scss";
.eq-single-event-details {
 
    .eq-create-event-title {
        margin-bottom: 5px;
    }

    .event-time {
        margin-top: 5px;
        align-items: center;
        color: $orangeColor;
        .time{
            padding: 0 2px;
        }
    }

    .description-text {
        margin: 16px 0px;
       

    }

    .discipline-row {
        align-items: center;
    }

    .alternative-header {
        font-size: 1.2em;
        margin-top: 10px;
        font-weight: bold;
    }

    .class-panel {
        margin-bottom: 10px;
    }

    .contact-info {
        margin-top: 16px;
    }

    .icon {
        height: 5px;
        color: #808080;
        padding: 5px;
        :hover {
            color: #ff8838;
        }
    }

    .class-panel {
        border: none; /* Remove all borders */
        .ant-collapse-header {
            padding: 8px 8px;
        }
    }
    .scrollable-column {
        height: 100vh;
        overflow-y: auto;
    }

    .event-card {

        .ant-card-cover{
            img{
                object-fit: cover;
                max-height: 450px;
            }
        }
      
        .price {
            color: $orangeColor;
        }
    }
}