


      .eq-csp-modal-modal {
        .options-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 4px;
          border: 1px solid #d9d9d9;
          border-radius: 8px; /* Increased border radius for a more text-area-like look */
          margin-bottom: 8px;
          width: 100%; /* Make the container span the entire width */
      
          .ant-tag {
            margin: 4px;
            white-space: nowrap;
            // border-radius: 16px; /* Make the tags circular */
            padding: 2px 8px;
          }
      
          input {
            border: none;
            outline: none;
            flex: 1;
            margin-left: 8px;
            padding: 4px;
            border-radius: 8px;
            background-color: transparent;
          }
        }
      }