@import '../../shared/Vars.scss';

.eq-sc-board {
    margin: 25px;
    padding-right: 20px;
    padding-left: 20px;


    .eq-pf-heading-row {
        @include poppins-24-regular;
        color: $makoColor;
        padding: 0px 0px 0px 0px;
        margin: 10px 0px;
        border-bottom: 1px solid $orangeBorder;


        .eq-pf-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-pf-heading {
                @include poppins-24-regular;
                display: flex;
                flex-direction: row;
                padding: 10px 0px 0px 0px;
                color: $makoColor;

                @media(max-width:$sm) {
                    padding: 5px 0px 0px 0px;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }

    .eq-pf-toggle-btn {
        .eq-pf-toggle {
            margin-right: 7px
        }

    }

    .eq-pf-sub-heading {
        @include poppins-16-semibold;
        margin-bottom: 30px;
    }
    .eq-pl-icons {
        .eq-pl-icon {
        padding-right: 3px;
        }
    }


    .eq-pb-right {
        order: 2;
        @media screen and (max-width: 760px) {
            order: 0;
        }
    }
    .eq-pb-right {
        padding: 25px;

        @media (max-width:$sm) {
            padding: 0px;
        }

        .eq-pb-search-heading {
           @include poppins-16-semibold;
            
            color: $blackColor;
            margin-bottom: 10px;

            @media(max-width:$sm) {
                font-size: 14px;
                line-height: 23px;
            }
        }
        
        .eq-pb-btn {
            margin-top: 9px;
        }
    }

    
}