@import "./Vars.scss";

.eq-nav-menu {
  position: relative;
  // z-index: 10000;
  z-index: 100;

  .ant-menu::before {
    display: table;
    content: none;
  }

  .eq-header {
    display: flex;
    justify-content: space-between;
    background: tra;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);



    .m-notification {
      @media (max-width: 500px) {
        order: 1 !important;
      }
    }

    // not in use
    // .eq-menu {
    //   /*display: flex;*/
    //   display: flex;
    //   justify-content: space-between;

    &.ant-menu-dark {
      background-color: #525757;





      &.ant-menu-vertical {
        .ant-menu-item {
          display: flex;
          align-items: center;
          @include poppins-18-regular;
          color: #fff;
          text-shadow: 0 0 0px #fff;
          align-self: center;
          vertical-align: middle;
          height: 60px;
          padding-right: 0;

          @media (max-width: 320px) {
            padding: 0;

            .menu-logo-btn {
              padding-left: 2px;
            }
          }


          &:hover {
            color: #fafafa;
            background-color: transparent;
          }

          @media (max-width: 1190px) {
            font-size: 14px;
            font-weight: 300;

          }
        }

        .ant-menu-submenu {
          align-self: center;
          vertical-align: middle;

          @media (max-width: 320px) {
            //  margin-left: 100px;
          }

          .ant-menu-submenu-title {
            @include poppins-18-regular;
            color: #fff;
            text-shadow: 0 0 0px #fff;
            align-self: center;
            display: flex;
            flex-direction: row-reverse;
            margin: 0;
            padding: 0;

            .anticon {
              align-self: center;
              font-size: xx-large;
              margin-left: 6px;
            }

            &:hover {
              color: #fafafa;
              background-color: transparent;
            }

            @media (max-width: 1190px) {
              font-size: 14px;
              font-weight: 300;
            }
          }
        }
      }

      &.ant-menu {
        .ant-menu-item-selected {
          color: #fff;
          background-color: transparent;
        }
      }
    }

    &.transparent-bg {
      padding-top: 7px;
      background-color: transparent;
      color: #fff;
      text-align: end;
    }




    &.transparent-bg {
      background-color: transparent;
      box-shadow: none;
      z-index: 100;
      margin-bottom: -7.3rem;

      .eq-menu {
        display: flex;

        &.ant-menu-dark {
          &.ant-menu-horizontal {
            .ant-menu-item {
              display: flex;
              align-items: center;
              color: $blackMenu;
              text-shadow: 0 0 0px $darkBlackZero;

              &:hover {
                color: $darkBlackZero;
                background-color: transparent;
              }
            }



            .ant-menu-submenu {
              align-self: center;

              .ant-menu-submenu-title {
                color: $blackMenu;
                text-shadow: 0 0 0px $darkBlackZero;

                &:hover {
                  color: $darkBlackZero;
                  background-color: transparent;
                }
              }
            }
          }

          &.ant-menu {
            .ant-menu-item-selected {
              color: $darkBlackZero;
              background-color: transparent;
            }
          }

          .ant-menu-sub {
            @include poppins-18-regular;
            color: #fff;
            background-color: $GreyLightLine2;
          }
        }

        &.transparent-bg {

          background-color: transparent;
          color: $blackMenu;
          text-align: end;
        }
      }

      .menu-logo-btn {
        margin-right: auto;

        .logo-home {
          @include cinzel;
          font-weight: 400;
          font-style: normal;
          font-size: 48px;
          line-height: 64.46px;
          display: inline-block;
          color: #fff;
          font-size: 48px;

          @media (max-width: $sm) {
            font-size: 20px;
            font-weight: 300;
          }

          .logo-image {
            height: 55px;
            width: 55px;
          }

        }
      }

    }

    .menu-logo-btn {
      margin-right: auto;

      .logo {
        position: relative;
        @include cinzel;
        font-weight: 400;
        font-style: normal;
        font-size: 48px;
        line-height: 64.46px;
        display: inline-block;
        color: #fff;
        // margin-left: 48px;

        .logo-img {
          // position: absolute;
          // left: -48px;
          width: 55px;
          top: 5px;
        }

        &.transparent-bg {
          color: $blackMenu;
          margin-left: 0px;

          @media (max-width: 1190px) {
            left: 32px;
          }

          .logo-img {
            // position: absolute;
            left: 200px;
            width: 55px;
            top: 7px;

            @media (max-width: 1190px) {
              left: -53px;
            }
          }
        }

        @media (max-width: 1190px) {
          font-size: 20px;
          font-weight: 300;

          .logo-img {
            width: 40px;
            top: 13px;
            // left: -40px;
          }
        }
      }
    }

    .quick-links {
      .quick-links-img {
        width: 24px;
        height: 24px;
      }

      .stripeDashboard {
        background: white;
        color: #525757;
        font-weight: 900;
        padding: 0px 4px;
        border-radius: 4px;
        font-family: 'Courier New', Courier, monospace;
      }
    }



    align-items: center;

    .eqButton {
      .ant-btn {
        &.ant-btn-button {
          &.round-bordered {
            color: #ff8838;
            background-color: #fff;
            border: 1px solid #ff8838;
            padding: 8px 16px;
            line-height: 0px;
            border-radius: 22px;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);

            @media (max-width: 320px) {
              padding: 2px 8px;
            }
          }
        }
      }
    }

    .lang-btn {
      scale: 0.6;
      color: #ff8838;
      background-color: #fff;
      border: 1px solid #ff8838;
      line-height: 0px;
      border-radius: 22px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      padding: 0;
      margin-bottom: 6px;


      .ant-menu-title-content {
        color: #ff8838;

        .lang-title {
          .lang-flag-title {
            margin-right: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          .lang-in-title {
            margin-right: 10px;
          }
        }

      }

    }
  }



}

.lang-title {
  .lang-flag-title {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .lang-in-title {
    margin-right: 10px;
  }
}

.language-dropdown {
  display: flex;
  align-content: center;
  justify-content: start;

  .lang-flags {
    width: 20px;
    height: 10px;
    margin-right: 20px;
  }

  .lang-option {}
}




.eq-submenu {
  .ant-menu-sub {
    @include poppins-18-regular;
    background: #efefef !important;
  }

  &.ant-menu-dark {
    .ant-menu-item {
      @include poppins-14-regular;
      color: $darkBlack !important;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #fff;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: $darkBlackZero !important;
        background: #eaeaea !important;
      }
    }
  }

  &.submenu-transparent {}
}

.ant-menu-dark.transparent-bg.ant-menu-vertical>.ant-menu-item {
  display: flex;
  align-items: center;
  color: $blackMenu;

  &:hover {
    color: $darkBlackZero;
    background-color: transparent;
  }
}

.ant-menu-dark.ant-menu-vertical>.ant-menu-item {
  color: #fff;
  display: flex;
  align-items: center;

  &:hover {
    color: #fafafa;
    background-color: transparent;
  }
}

.ant-menu.ant-menu-dark.transparent-bg .ant-menu-item-selected {
  color: $blackMenu;
  background-color: transparent;

}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  color: #fafafa;
  background-color: transparent;

}

.ant-menu.ant-menu-dark .ant-menu-submenu-title {
  margin-top: 0px;
}

.ant-menu.ant-menu-dark.transparent-bg .ant-menu-submenu-title {
  color: #000;
  margin-top: 0;

}

.ant-menu.ant-menu-dark {
  background-color: #525757;

}

.ant-select-dropdown {
  border-radius: 7px;
  z-index: 100000;

}