@import "../../shared/Vars.scss";
.eq-previous-training-board {
  background-color: $whiteColor;

  .section-padding {
    padding: 20px;
  }

}

