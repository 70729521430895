@import '../../shared/Vars.scss';
.eq-f-row {
    @include Inter-14-regular;
    padding: 17px 16px;

    color: $darkBlack;
    border-bottom: 2px solid $graylightborder;

    .eq-f-td {
        padding: 0px;

        .eq-f-currency {
            font-weight: bold;
        }

        .eq-status-green {
            color: $green;
        }

        .eq-status-purple {
            color: $purple;
        }

        .eq-status-red {
            color: $red;
        }

        .eq-status-orange {
            color: $orange;
        }

        .eq-action-invite {
            cursor: pointer;
            // margin: 5px;
        }

        .eq-action-delete {
            margin: 5px;
        }
    }
}

.eq-card-header {
}

.eq-card-body {
    .eq-card-row {
        padding: 17px 16px;

        .eq-card-col-heading {
            line-height: 22px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            
            color: #000000;
        }


        .eq-f-currency {
            font-weight: bold;
        }

        .eq-status-green {
            color: $green;
        }

        .eq-status-purple {
            color: $purple;
        }

        .eq-status-red {
            color: $red;
        }

        .eq-status-orange {
            color: $orange;
        }
    }

    .eq-card-divider {
        border-bottom: 1px solid #F0F0F0;
        margin: 0px 20px 0px 20px;
        padding: 0px;
    }
}