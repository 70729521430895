@import "../../../../../shared/Vars.scss";

.eq-student-activity-collapse {
    // border: solid;
    margin: 10px 10px 10px 10px;
    // border: 2px solid #F0F0F0;
    .eq-activity-row {
        @include poppins-14-regular;
        padding: 0px;
        color: $blackColor;
        cursor: pointer;
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

        .eq-status-row-border {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            margin: 0px;
            align-items: center;


            .content-col {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                align-items: center;



                .Header-col {
                    flex: 1 1 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100%;
                    margin-right: 20px;
                    margin-top: 10px;
                    align-items: center;
                    overflow: hidden;
                }

                .date-icon {
                    color: $darkbg;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    justify-content: center;
                    position: relative;
                    font-weight: 900;
                    margin-right: 12px;

                    .eq-cal-icon {
                        width: 34px;
                        height: 34px;
                    }

                    .day {
                        font-size: 15px;
                        line-height: 15px;
                        position: absolute;
                        left: 8px;
                        top: 13.5px;
                    }

                    .month {
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                .time {
                    font-weight: 700;
                    margin-right: 12px;
                }
            }
        }
    }

    .Course-Lesson-Collapse {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 15px;


        .eq-lesson-update-input {
            padding: 10px 10px 20px 10px;
        }
    }

    .student-details-table {
        padding: 20px 20px 20px 20px;

        .table-header,
        .table-row {
            //padding: 10px 10px 10px 10px;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 8px;
        }

        .user-details-row {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .table-header {
            font-weight: bold;
            background-color: #f9f9f9;
        }

        .table-row {
            &:nth-child(even) {
                background-color: #f2f2f2;
            }
        }

        .table-header > .ant-col,
        .table-row > .ant-col {
            padding: 8px 8px 8px 13px;
        }
    }

    .Horse-select {
        width: 140px;
        height: 50px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
    }

    .sub-Columns {
        display: flex;
        gap: 70px;
    }
}
.Delete-Icon {
    cursor: pointer !important;
    display: inline-block;
    padding: 5px;
}
.Delete-Icon img {
    max-width: 100%; 
    height: auto; 
}

