@import '../../shared//Vars.scss';
.eq-notification {
    .eq-nf-container {

        .eq-message-item{

            @media (max-width: $sm) {
              
                font-size: 11px;
              }
        }

        .eq-message-item-from{
            font-weight: 600;
    
            @media (max-width: $sm) {
              line-height: 22px;
            }
    
            .ant-tag {
                background-color: $lightTagGreenBG;
                // border-radius: 12px;
              @media (max-width: $sm) {
                line-height: 13px;
                font-size: 11px;
              }
            }
        }


        .eq-message-read {
            padding: 17px 16px;
            @include Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: $darkBlack;
            border-bottom: 2px solid $rowBottomBorder;

            @media(max-width:$sm) {
                .eq-message-item-type {
                    font-weight: 700;
                }
            }
        
            .eq-message-item-action {
                cursor: pointer;

                .eq-message-action {
                    display: flex;
                    flex-direction: row;

                    img {
                        transition: background 0.2s, color 0.2s;

                        &:hover {
                            transform: scale(1.2);
                            box-shadow: 0.1em 0.1em 1.2em #bbb;
                        }
                    }

                    .eq-message-action-reply {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-flag {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-delete {
                        flex: 1;
                        max-width: 32px;
                    }
                }
            }
        }

        .eq-message-unread {
            padding: 17px 16px;
            @include Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            color: $darkBlack;
            border-bottom: 2px solid $rowBottomBorder;

            @media(max-width:$sm) {
                .eq-message-item-type {
                    font-weight: 700;
                }
            }

            .eq-message-item-action {
                cursor: pointer;

                .eq-message-action {
                    display: flex;
                    flex-direction: row;

                    img {
                        transition: background 0.2s, color 0.2s;

                        &:hover {
                            transform: scale(1.2);
                            box-shadow: 0.1em 0.1em 1.2em #bbb;
                        }
                    }

                    .eq-message-action-reply {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-flag {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-delete {
                        flex: 1;
                        max-width: 32px;
                    }
                }
            }
        }
    }
}
