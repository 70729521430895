@import '../../shared/Vars.scss';


.eqSpace {
    padding: 0px;
    margin: 0px;
    /*.ant-space {
        width: 100%;
        padding: 0 10%;
    }
*/
    .ant-space {

        .ant-space-item {
            display: flex;
            justify-content: center;

            .ant-typography{
                text-align:center;
                color:white;
            }
        }
    }
}
