@import '../../shared/Vars.scss';

.eq-FinancialsBoard-container {
    background-color: white;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .filters {
        background-color: #FAFAFA;
        padding: 16px;
        border-radius: 12px;
        margin-top: 20px;
        margin-bottom: 25px;
        .picker-label {
            @include Inter-14-regular-btn;
            font-weight: 500;
        }

        .filter-type {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .eq-input-info {
                width: 100%;
            }

            .eq-filter-radius {
                border-radius: 5px;

                .ant-select-selector {
                    border-radius: 5px;
                }
            }

            .search-col {
                margin: auto;
            }
        }

    }

    .eq-FinancialsBoard-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-FinancialsBoard-col {
            .eq-FinancialsBoard-heading {
                margin-bottom: 10px;
                padding: 0px;
                @include poppins-24-regular;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }


    }
    // .eqButton .ant-btn:hover {
    //     background-color: #fff;
    //     box-shadow: 0 ;
    //     color: #ff8838;
    // }
    .eq-fb-filter {
        border: none;
    }

    .eq-action-delete {
        display: none;
    }


    .pdf-generate {
        color: #ff8838;
        background-color: #fff;
        border: 1px solid #ff8838;
        padding: 8px 16px;
        margin-left: 2px;
        line-height: 0px;
        border-radius: 22px;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);

    }

    .pdf-view {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}