@import '../../shared/Vars.scss';
.eq-sl-row {
    @include Inter-14-500;
    background: $grayHeading;
    padding: 17px 16px;
    color: $darkBlack;

    border-bottom: 2px solid $rowBottomBorder;
    margin: 0px;

    .eq-th-checkbox{
        padding:0px 20px 0px 0px;
    } 
    .eq-sl-th {
        padding: 0px;
        margin: 0px;
    }
}


    .copy-data-btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        transition: 0.2s linear all;

        .mail-copy {
            margin-right: 10px;
        }
    }
    .copy-data-btn-xs {
 
        display: flex;
        .mail-copy {
            margin-right: 7px;
        }
    }