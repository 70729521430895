@import '../../shared/Vars.scss';

.eq-message-modal {
    .error-message {
        color: $red;
    }

    .eq-message-container {
    }
}
