@import "../../shared/Vars.scss";

.eq-types-features {
    margin: 0 auto;

    .ant-row {
        display: flex;
        // justify-content: space-around;
    }

    .page-banner {

        // max-height: 80vh;
        // height: 80vh;
        @include responsive(sm) {
            max-height: 80vh;
        }

        @media (max-width: 460px) {
            height: 80vh;
        }

        padding: 0;
        margin-top: 0px;

        z-index: 99;
        opacity: 1;
        text-shadow: 0 0 0px #000;

        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .hero-image {
            // max-width: 100vw;
            //   max-height: 100vh;

            @media (min-width: 760px) {

                max-width: 100vw;
            }

            @media (max-width: 600px) {
                max-height: 100vh;
            }
        }

        .overlay {
            position: absolute;
            background: linear-gradient(to top,
                    rgba(0, 0, 0, 0.1),
                    transparent);
            // background: linear-gradient(0deg,
            //         rgba(255, 255, 255, 0.7) 0%,
            //         rgba(154, 154, 154, 0.5) 9%,
            //         rgba(65, 65, 65, 0.5) 19%,
            //         rgba(0, 0, 0, 0.7) 100%);
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-content: center;
            align-items: center;
            flex-direction: column;
            padding-bottom: 110px;


            .welcome-heading {
                @include poppins-64-bold;
                width: 80%;
                color: #FFF;
                text-align: center;

                padding-bottom: 60px;

                @media (max-width: 977px) {
                    font-size: 42px;
                    line-height: 50px;
                }

                @media (max-width: 760px) {
                    font-size: 32px;
                    line-height: 40.2px;
                }
            }

            .create-profile-btn {

                .eqButton {
                    text-align: center;

                    .ant-btn {
                        @include poppins-22-semibold-btn;
                        // color: #FFF;
                        height: auto;
                        text-align: center;
                        padding: 18px 110px;
                        font-size: 24px;
                        font-weight: 700;
                        text-transform: capitalize;
                        border-radius: 30px;

                        @media (max-width: 768px) {
                            padding: 8px 70px;
                            font-size: 23px;
                        }

                        @media (max-width: 430px) {
                            padding: 8px 50px;
                            font-size: 20px;
                        }

                        @media (max-width: 320px) {
                            padding: 8px 30px;
                            font-size: 16px;
                        }
                    }
                }

                @media (max-width: 977px) {
                    // scale: 0.8;
                }
            }
        }
    }

    .features-section {
        background: white;

        .feature-sub-section {
            // width: 80%;
            margin: auto;
            padding: 7px 40px;

            @media (max-width: 768px) {
                padding: 7px 0;
            }

            @media (max-width: 400px) {
                width: 100%;
            }

            .feature-img-section {
                position: relative;
                height: 100%;

                .feature-img {
                    position: absolute;

                    @media (max-width: 768px) {
                        scale: 0.8;
                    }

                    @media (max-width: 450px) {
                        scale: 0.6;
                    }

                    @media (max-width: 340px) {
                        scale: 0.5;
                        // top: 0 !important;
                    }
                }

                .feature-img-1 {
                    top: 10px;
                    left: 11px;

                    @media (max-width: 450px) {
                        top: 0;
                        left: 1%;
                    }
                }

                .feature-img-2 {
                    top: 10px;
                    left: 145px;

                    @media (max-width: 450px) {
                        top: 0;
                        left: 31%;

                    }
                }

                .feature-img-3 {
                    top: 87px;
                    left: 72px;

                    @media (max-width: 450px) {
                        top: 35px;
                        left: 17%;
                    }
                }

                .feature-img-4 {
                    top: 71px;
                    right: 113px;

                    @media (max-width: 450px) {
                        top: 35px;
                        right: 31%;
                    }
                }

                .feature-img-5 {
                    top: 9px;
                    right: 41px;

                    @media (max-width: 450px) {
                        top: 0px;
                        right: 17%
                    }
                }

                .feature-img-6 {
                    top: 96px;
                    right: 7px;

                    @media (max-width: 450px) {
                        top: 35px;
                        right: 2%;
                    }
                }
            }


            .basic-feature {
                width: 80%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 50px;
                flex-direction: column;

                @media (max-width: 900px) {
                    margin-top: 75px;
                }

                @media (max-width: 600px) {
                    margin-top: 90px;
                }

                .heading {
                    @include poppins-36-bold;
                    color: #000;
                    text-align: center;
                    // font-size: 40px;
                    font-style: normal;
                    font-weight: 700;

                }

                .heading-text {
                    @include Inter-18-regular;
                    margin-bottom: 50px;
                    color: #626262;
                    text-align: center;


                    /* 155.556% */
                    .emoji {
                        width: 30px;

                        margin-bottom: 3px;
                    }


                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }

                .feature-row {
                    margin: auto;
                    margin-top: 10px;
                    margin-bottom: 40px;
                    display: flex;
                    justify-content: center;

                    .feature-col {
                        display: flex;
                        justify-content: center;

                        .basic-content {
                            background: #F8F7F5;
                            border: 1px solid #DFDFDF;
                            max-width: 492px;
                            height: 100%;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: space-between;
                            ;

                            .basic-icon {
                                font-size: 75px;
                            }

                            .content-heading {
                                @include poppins-32-bold;
                                margin-top: 30px;
                                margin-bottom: 20px;
                                color: #000;
                            }

                            .content-text {
                                @include Inter-18-regular;
                                color: #555659;
                            }
                        }
                    }
                }
            }
        }


    }

    .offer-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        flex-direction: column;

        .heading {
            @include poppins-36-bold;
            color: #000;
            text-align: center;


        }

        .heading-text {
            @include Inter-18-regular;
            color: #555659;
            text-align: center;
            margin-bottom: 50px;
            width: 45%;

            @media (max-width: 600px) {
                width: 90%;
            }

        }

        .offer-block {

            width: 80%;

            @media (max-width: 740px) {
                margin-top: 50px;
            }

            @media (max-width: 430px) {
                margin-top: 20px;
            }

            .container {
                // width: 73%;
                margin-right: auto;
                margin-left: auto;

                @media (max-width: $sm) {
                    width: 100%;
                    padding-right: 12px;
                    padding-left: 12px;
                }

                .offer-col {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 90px;

                    @media (max-width: 430px) {
                        margin-bottom: 40px;
                        padding-bottom: 52px;
                        border-bottom: 1px dashed #F4D0B8;
                    }

                    .offer-image {
                        width: 100%;
                        padding: 10px 0;
                        background: #F4D0B8;

                        @media (max-width: 760px) {
                            scale: 0.9;
                        }

                        @media (max-width: 460px) {
                            scale: 1;
                        }

                        .image {
                            width: 100%;
                        }
                    }

                    .offer-content {

                        .offer-desc {

                            .sub-heading {

                                @include poppins-24-bold;
                                margin-bottom: 24px;

                            }

                            .sub-text {
                                color: #525252;
                                @include Inter-16-regular;
                                /* 150% */

                                margin-bottom: 18px;

                                @media (max-width: 425px) {
                                    font-size: 14px;
                                }
                            }

                            .sub-text-points {

                                ul {
                                    padding: 0;

                                    li {
                                        color: #4F4F4F;
                                        @include Inter-16-regular;
                                        display: flex;
                                        align-items: flex-start;
                                        gap: 12px;
                                        margin-bottom: 16px;

                                        @media (max-width: 425px) {
                                            font-size: 14px;
                                        }

                                        .icon {
                                            color: rgb(255, 136, 56);

                                            background: #df722a29;
                                            border-radius: 25px;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: 900px) {
                            .offer-desc {
                                .sub-heading {
                                    font-size: 20px;
                                }

                                .sub-text {
                                    font-size: 14px;
                                }

                                .sub-text-points {
                                    ul {
                                        li {
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: 760px) {
                            .offer-desc {
                                // .sub-heading {

                                // }

                                .sub-text {
                                    font-size: 18px;
                                }

                                .sub-text-points {
                                    ul {
                                        li {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: 430px) {
                            .offer-desc {
                                .sub-heading {
                                    font-size: 20px;
                                }

                                .sub-text {
                                    font-size: 16px;
                                }

                                .sub-text-points {
                                    ul {
                                        li {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }


                }

                @media (max-width: 430px) {
                    margin-bottom: 40px;
                }

                .offer-right-col {
                    @media (max-width: 760px) {
                        .offer-image {
                            order: 2;
                        }
                    }
                }

            }
        }
    }


}