@import '../../shared/Vars.scss';

.eq-eventInvitationBoard-container {
    background-color: white;
    // padding: 10px 35px 35px 35px;

    // .ant-row {
    //     display: flex;
    // }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .eq-eventInvitationBoard-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-eventInvitationBoard-col {
            .eq-eventInvitationBoard-heading {
                margin-bottom: 10px;
                padding: 0px;
                @include poppins-24-regular;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }
}
