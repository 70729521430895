@import '../../shared/Vars.scss';

.eq-s-tr {
    @include Inter-14-regular;
    padding: 17px 16px;

    color: $darkBlack;
    border-bottom: 2px solid $rowBottomBorder;

    .eq-td-checkbox {
        padding: 0px 20px 0px 0px ;
    }

    .eq-s-groups {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .eq-s-td {
        padding: 0px;
        margin: 0px;

        .ant-tag {
            // border-radius: 3px 0 0 3px !important;
        }

        .eq-group-color-1 {
            background: #F6FFED;
            border: 1px solid #B7EB8F;
            color: #52C41A;
        }



        .eq-group-color-2 {
            background: #F0F5FF;
            border: 1px solid #ADC6FF;
            color: #2F54EB;
        }



        .eq-group-color-3 {
            background: #FFF1F0;
            border: 1px solid #FFA39E;
            color: #F5222D;
        }



        .eq-group-color-4 {
            color: purple;
        }

        .eq-action-invite {
            margin: 5px;
        }

        .eq-action-delete {
            margin: 5px;
        }

    }
    .overflow-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }

    .action-btn {
        display: flex;
        justify-content: space-evenly;

        .anticon-copy {
            color:#1890ff;
        }
        .anticon-phone {
            color:#1890ff;
        }
        :hover {
            cursor: pointer;
        }

    }
    

    .horse-detail-copy {
        .copy-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    
    
        }
    
        .copy-text:hover {
            cursor: pointer;
            color: #40a9ff;
    
            line-height: 0.9;
    
        }
    }
}
.mobile-view {
        
    .eq-s-groups {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // white-space: nowrap;
    }

    .horse-detail-copy {
        .copy-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    
    
        }
    
        .copy-text:hover {
            cursor: pointer;
            color: #40a9ff;
    
            line-height: 0.9;
    
        }
    }
}