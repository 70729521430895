@import '../../shared/Vars.scss';


.locationModal{
    .ant-modal-content {
        border-radius: 20px;
    }

        
    .ant-modal-header {
        border-radius: 20px;
    }
}

.ex-location {

    .input-row {
        margin-bottom: 16px;
    }
    
   

    
    .eqInput {
        padding: 0px;
        margin: 0px;
    
    
        .ant-input {
            line-height: 1.75;
            padding-right: 6px !important;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            box-sizing: border-box;
            border-radius: $inputBorderRadius;
            padding-left: 16px;
        }
    
        .eq-label {
            @include Inter-14-regular ;
            color: $darkBrownText;
            margin-bottom: 3px;
        }
    }

    .error-message {
        color: $red;
    }
}