@import '../../shared/Vars.scss';

.eq-FinancialsBoard-container {
    background-color: white;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .ant-tabs {
        .ant-tabs-nav-list {

            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;
                transition: color 0.3s;

                &:hover {
                    color: #FF8838;
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;
                }
            }

            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }

    .filters {
        background-color: #FAFAFA;
        padding: 16px;
        border-radius: 12px;
        margin-top: 20px;
        margin-bottom: 25px;

        .picker-label {
            font-weight: 500;
            @include Inter-14-regular-btn;
        }

        .filter-type {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .eq-input-info {
                width: 100%;
            }

            .eq-filter-radius {
                border-radius: 5px;

                .ant-select-selector {
                    border-radius: 5px;
                }
            }
        }

    }

    .eq-FinancialsBoard-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-FinancialsBoard-col {
            .eq-FinancialsBoard-heading {
                @include poppins-24-regular;
                margin-bottom: 10px;
                padding: 0px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        .eq-fb-select {}
    }

    .eq-action-delete {
        display: none;
    }

    .pdf-view {
        display: flex;
        align-items: center;
        justify-content: center;
    }



}