@import '../../shared/Vars.scss';

.eq-card {
    border-radius: 0 0 12px 12px;
    height: 100%;

    .ant-card {
        border-radius: 10px;
    }

    .ant-card-bordered {
        border-radius: 10px;
        height: inherit;
    }

    .ant-card-cover {
        border-radius: 10px 10px 0 0;

        .event-img {
            object-fit: cover;
            border-radius: 12px;
            height: 200px;
            object-fit: cover;
border: 10px solid white;
            @media (max-width:$sm) {
            }
        }
    }

    .ant-card-actions {
        border-radius: 0 0 10px 10px;
    }

    .ant-card-meta-title {
        color: $darkBlack;
        @include poppins-16-semibold;
    }

    .ant-card-meta-description {
        @include poppins-14-regular;
        color: $darkBlack;
    }

    .time {
        text-align: center;
        margin-top: 12px;
        @include Inter-14-regular;
        color: $darkBlackSecondary;
    }
}