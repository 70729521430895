@import "../../shared/Vars.scss";

.eq-event-holder-features {
    margin: 0 auto;

    .offer-section {
        .heading-text {
            width: 29%;
        }
    }

    .contact-us {
        background: #FFF1E7;
        padding: 48px 0;

        @media (max-width: $sm) {
            padding: 22px 0px;
        }

        .contact-container {
            width: 80%;
            margin: auto;

            align-items: center;


            @media (max-width: $sm) {
                width: 100%;
                text-align: center;

            }


            .contact-info {
                @media (max-width: $sm) {
                    width: 90%;
                    margin: auto;
                    text-align: center;
                }

                .contact-heading {
                    @include poppins-36-bold;
                }

                .contact-btn {
                    @include poppins-22-semibold-btn;
                    padding-top: 30px;
                    font-size: 24px;


                    .ant-btn {
                        padding: 23px 90px;
                        line-height: 0;
                        border-radius: 27px;

                    }

                    @media (max-width: $sm) {
                        text-align: center;
                        padding-top: 20px;
                        font-size: 20px;

                        .ant-btn {
                            padding: 18px 45px;

                        }
                    }
                }
            }

            .contact-img {
                display: flex;
                align-items: center;
                justify-content: center;

                .image {
                    width: 90%;
                }
            }

            @media (max-width: 600px) {
                .contact-info {
                    .contact-heading {
                        font-size: 32px;
                    }
                }

                .contact-img {
                    margin-top: 22px;

                    .image {
                        width: 80%;
                    }
                }
            }

            @media (max-width: 425px) {
                .contact-info {
                    .contact-heading {
                        font-size: 24px;
                    }
                }
            }
        }
    }


}