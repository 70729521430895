@import '../../shared/Vars.scss';

.horse-task-list {
    // .row {
    //   justify-content: space-around;
    //   align-items: center;
    //   padding: 48px 0px;
    //   width: 100%;

    //   .link-category {
    //       display: flex;
    //       padding: 10px 8px;
    //     }
    // }

    .eq-f-row {
        padding: 17px 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $darkBlack;
        border-bottom: 2px solid $graylightborder;
        align-items: center;

        .eq-f-td {
            padding: 0px;

            .action-delete {
                margin-right: 25px;

                :hover {
                    cursor: pointer;
                    color: red
                }
            }

            .action-edit {
                :hover {
                    cursor: pointer;
                    color: #FF8838;
                }
            }


        }

        .horse-task-detail {
            display: flex;
            align-items: center;

            .task-info {
                display: flex;
                align-items: center;
                justify-content: space-around;

                .event-img {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    margin-right: 15px;
                }
            }
        }

        .task-status {
            height: 100%;
            width: 100%;
            padding-right: 10px;
            .ant-select {
                height: 100%;
                width: 100%;
                .ant-select-selector {
                    border: none;
                    box-shadow: none;
                    .ant-select-arrow{
                        border-left: 1px solid rgba(0, 0, 0, 0.25);
                        padding-left: 5px;
                    }
                }
            }

        }
    }

    .eq-card-body {
        padding-bottom: 20px;

        .task-detail {
            display: flex;
            align-items: center;

            .task-info {
                display: flex;
                align-items: center;
                justify-content: space-around;

                .event-img {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    margin-right: 15px;
                }
            }
        }

        .action-delete {
            margin-right: 25px;

            :hover {
                cursor: pointer;
                color: red
            }
        }

        .action-edit {
            :hover {
                cursor: pointer;
                color: #FF8838;
            }
        }
    }

    .edit-horse-panel {
        width: 100%;
    }

}