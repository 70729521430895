@import 'Vars.scss';

.eq-header {
    text-align: end;

    .ant-menu-dark.ant-menu-horizontal {
        .ant-menu-item {
            @include poppins-18-regular;
            color: $blackMenu;
            text-shadow: 0 0 0px $darkBlackZero;
        }
    }

    .ant-menu.ant-menu-dark {
        .ant-menu-item-selected {
            background-color: transparent;
        }
    }

    &.transparent-bg {
        background-color: transparent;
        z-index: 99;
    }
}

.ant-layout-sider-zero-width-trigger {


    @media(max-width: $sm) {
        top: 38px;
        
        
    }
}
