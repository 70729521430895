@import '../../shared/Vars.scss';
.eq-prf-card {
    padding: 0px 5px;

    .eq-pg-name {
        @include Inter-14-regular;
        text-transform: capitalize;
        color: $blackColor;
        margin-bottom: 5px;
    }

    .eq-pg-row {
        font-family: Poppins;
        font-style: normal;


        .eq-pl-address {
            @include Inter-14-regular;
            padding: 2px 0px 0px 10px;
            font-weight: normal;
            font-size: 14px;
            text-transform: capitalize;
            color: #000000;

            .eq-pl-address-icon {
            }
        }

        .eq-pl-discipline {
            @include poppins-14-semibold;
            margin: 15px 0px 0px 0px;
            padding: 2px 10px 0px 0px;
            // font-weight: 600;
            // font-size: 13px;
            
            color: $blackColor;
        }

        .eq-pl-discipline-list {
            margin: 15px 5px 0px 0px;
            padding: 2px 12px;
            border: 1px solid $disciplineBorder;
            border-radius: 37px;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: $blackMenu;

            .eq-pl-discipline-list-active {
                color: $blueLink;
            }
        }
    }


    .eq-pg-profile-link {
        font-weight: normal;
        font-size: 13px;
        line-height: 31px;
        text-transform: capitalize;
        color: $blueLink;
        cursor: pointer;
    }
}