@import '../../shared/Vars.scss';

.eq-board-container {
    background-color: $whiteBackground;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .trainer-info {
        @media (min-width:$sm) {
            padding-right: 15px;
        }


        .profile-img {
            object-fit: cover;
            border-radius: 128px;

            img {
                object-fit: cover;
                border-radius: 128px;
                height: 128px;
                width: 128px;
            }
        }

        .profile-name {
            margin-top: 25px;
            @include poppins-24-regular;
            color: #000000;
            text-transform: capitalize;
        }

        .profile-certified {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #525757;
            padding: 10px 0px 25px 0px;
            border-bottom: 1px solid #C4C4C4;
            margin-bottom: 25px;
        }

        .profile-info-h {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 28px;
            color: #525757;
        }

        .profile-info-i {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 28px;
            color: #525757;
        }

        .section-divider {
            border-bottom: 1px dashed #C4C4C4;
            margin: 20px 0px;
        }

        .section-heading {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            
            color: #000000;
        }

        .eq-col-discipline {
            background: #FFFFFF;
            border: 1px solid #ABD6FF;
            box-sizing: border-box;
            border-radius: 37px;
            padding: 5px 10px;
            margin: 10px 0px 0px 0px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: #626262;
        }

        .eq-list {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 28px;
            color: #0081FE;

            .list {
                margin-left: -25px;
            }
        }
    }

    .col-right {
        @media (min-width:$sm) {
            padding-left: 15px;
        }

        .profile-about {
            .about-message {
                cursor: pointer;
            }

            .about-info {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 23px;
                color: #626262;
                margin-bottom: 20px;

                about-left-col {
                    @media (min-width:$sm) {
                        padding-right: 15px;
                    }
                }

                .about-right-col {
                    @media (min-width:$sm) {
                        padding-left: 15px;
                    }

                    .msg-icon {
                        margin-right: 10px;
                    }

                    .msg-text {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #626262;
                    }
                }

                .about-heading {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    
                    color: #000000;
                    margin: 20px 0px 10px 0px;
                }
            }

            .eq-board-heading {
                @include poppins-24-regular;
                color: $makoColor;
                padding-bottom: 10px;

                @media (max-width:$sm) {
                    font-size: 16px;
                    padding-bottom: 5px;
                }

                .eq-board-col {

                    .eq-board-header {
                        @include Inter;
                        font-style: normal;
                    }
                }

                .my-loc {
                    margin-left: 25px;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    color: #0081FE;
                }
            }

            .eq-board-line {
                border-bottom: 1px solid $orangeBorder;
            }
        }
    }
}
