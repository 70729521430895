.student-horse-selector {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 4px;

    &.no-border{
        border:none;
    }

  }
  
  .selection-container {
    // border: 1px solid #d9d9d9;
    // border-radius: 4px;
    padding-top: 10px;
    // background-color: #fafafa;
  }
  
  .selection-header {
    font-weight: bold;
    padding: 8px 8px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #d9d9d9;
  }
  
  .header-title {
    // text-align: center;
  }
  
  .selection-row {
    padding: 8px 8px;
    border-bottom: 1px solid #d9d9d9;
  }
  
  .student-name {
    // text-align: center;
  }
  
  .horse-select {
    width: 100%;
  }
  