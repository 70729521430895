@import '../../shared/Vars.scss';

.eq-s-tr {
    @include Inter-14-regular;
    padding: 17px 16px;
    color: $darkBlack;
    border-bottom: 2px solid $rowBottomBorder;

    .eq-s-groups {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // white-space: nowrap;
    }

    .eq-s-td {
        padding: 0px;
        margin: 0px;


        .ant-tag {
            // border-radius: 12px;
        }

        .eq-group-color-1 {
            background: #F6FFED;
            border: 1px solid #B7EB8F;
            color: #52C41A;
        }



        .eq-group-color-2 {
            background: #F0F5FF;
            border: 1px solid #ADC6FF;
            color: #2F54EB;
        }



        .eq-group-color-3 {
            background: #FFF1F0;
            border: 1px solid #FFA39E;
            color: #F5222D;
        }



        .eq-group-color-4 {
            color: purple;
        }

        .eq-action-invite {
            cursor: pointer;
            margin: 5px;
        }

        .eq-action-delete {
            cursor: pointer;
            margin: 5px;
        }

        .eq-status-green {
            color: $green;
        }

        .eq-status-purple {
            color: $purple;
        }

        .eq-status-red {
            color: $red;
        }

        .eq-status-orange {
            color: $orange;
        }
    }

    .overflow-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .mail-text-copy {
            :hover {
                cursor: pointer;
                color: #40a9ff;
                border-bottom: 1px solid #40a9ff;
                line-height: 0.9;

            }
        }

    }

    .student-detail-copy {
        .copy-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    
    
        }
    
        .copy-text:hover {
            cursor: pointer;
            color: #40a9ff;
    
            line-height: 0.9;
    
        }
    }

    
}

.mobile-view {
    .eq-s-groups {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // white-space: nowrap;
    }

    .student-detail-copy {
        .copy-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    
    
        }
    
        .copy-text:hover {
            cursor: pointer;
            color: #40a9ff;
    
            line-height: 0.9;
    
        }
    }
}

