@import "../../shared/Vars.scss";

.eq-school-activity-board {
  background-color: $whiteColor;
  min-height: 100vh;
  padding: 10px 35px 35px 35px;

  @media (max-width: $sm) {
    padding: 10px;
  }

  .ab-tabs {
    box-shadow: 2px 4px 7px #c6c6c6
  }

  .ant-tabs {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding-top: 11px;
        transition: color 0.3s;

        &:hover {
          color: #FF8838;
          .ant-badge-count {
            background-color: #FF8838;
          }
        }
      }
      .ant-tabs-tab-btn {
        @include poppins-14-regular-btn;
        &:hover {
          color: #FF8838;
          .ant-badge-count {
            background-color: #FF8838;
          }
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #FF8838;

        }

        .ant-badge-count {
          background-color: #FF8838;
        }
      }

      .ant-tabs-ink-bar {
        background: #FF8838;
      }
    }
  }


  .eq-tp-heading-row {
    padding: 4px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    border-bottom: 1px solid $orangeBorder;

    .eq-tp-heading-col {
      padding: 0px;
      margin: 0px;

      .eq-tp-heading {
        @include poppins-24-regular;
        padding: 0px 0px 0px 0px;
        color: $makoColor;

        @media (max-width: $sm) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    .eq-event-buttons {
      margin: 0px 0px 0px 5px;

      @media (max-width: $sm) {
        margin: 0px 0px 5px 5px;
      }
    }
  }

  .eq-section {
    margin-bottom: 25px;
  }

  .product-type {
    // padding-bottom: 35px ;
    // .label {
    //   margin-top: 25px;
    //   @include poppins-16-semibold;
    //   color: $darkBlackZero;
    //   margin-bottom: 16px;
    //   
    // }
  }

  .label {
    // margin-top: 25px;
    @include poppins-16-semibold;
    color: $darkBlackZero;
    margin-bottom: 16px;
    
  }

  .ant-row {
    display: flex;
  }

  .eq-event-header-icon {
    cursor: pointer;

    img {
      padding: 5px 0px 5px 10px;
    }
  }

  .tab-badge {

    .ant-badge-count {
      background-color: #52575757;
      scale: 0.9;

    }
  }
}

.customCollapse {
  overflow: hidden;
  transition: 0.4s max-height;
}

.customCollapse>div {
  overflow: auto;
}

.sample-content {
  background: #ccc;
  padding: 10px;

  h1,
  p {
    margin: 0;
    text-align: center;
  }
}

.collapseHeader {
  padding: 10px;
  display: block;
  background: #333;
  color: white;
  width: 100%;
  border: 0;
  cursor: pointer;
}