@import "../../shared/Vars.scss";

.horse-management {
    background-color: white;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .eq-HorseTask-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 20px 0px;
        border-bottom: 1px solid $orangeBorder;
        display: flex;
        justify-content: space-between;

        .eq-HorseTask-col {
            .eq-HorseTask-heading {
                margin-bottom: 10px;
                padding: 0px;
                font-family: IBM Plex Serif;
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 31px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }

    .task-details {
        margin: 20px auto 10px 10px;

        .task-options {
            margin-bottom: 20px;

            .options-heading {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                text-transform: capitalize;
                margin-bottom: 10px;
            }

            .options {
                margin-top: 10px;

                .options_1 {
                    margin-right: 40px;
                }
            }
        }

        .horse-taskList {
            width: 100%;
            margin: auto;
        }


        .eq-add-horse-btn {
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .btn-background-white {
                :hover {
                    color: white;
                }
            }
        }
    }

    .eqInput {
        .ant-input {
            height: 40px;
        }
    }

    .eq-fl-row {
        margin-top: 22px;
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        border-bottom: 2px solid $graylightborder;

        .eq-fl-th {
            padding: 0px;
        }
    }

    .eq-list-checkAll {
        padding: 17px 16px;
        color: $darkBlack;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }

    position: relative;

    .add-task {
        display: block;
        position: absolute;
        top: -66px;
        right: 0px;
    }
}

.week-number {
}

.week-limits {
    font-size: 10px;
    color: #aeaeae;
    display: block;
}
.loading{
    margin-top: 12px;
}

.horse-management {

    .ant-tabs {
        .ant-tabs-nav-list {

            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;
                transition: color 0.3s;

                &:hover {
                    color: #FF8838;
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;
                }
            }

            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }






    .horse-task-list {
        // .row {
        //   justify-content: space-around;
        //   align-items: center;
        //   padding: 48px 0px;
        //   width: 100%;
        //   .link-category {
        //       display: flex;
        //       padding: 10px 8px;
        //     }
        // }

        .eq-f-row {
            padding: 17px 16px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: $darkBlack;
            border-bottom: 2px solid $graylightborder;
            align-items: center;

            .eq-f-td {
                padding: 0px;

                .action-delete {
                    margin-right: 25px;

                    :hover {
                        cursor: pointer;
                        color: red
                    }
                }

                .action-edit {
                    :hover {
                        cursor: pointer;
                        color: #FF8838;
                    }
                }
            }

            .horse-task-detail {
                display: flex;
                align-items: center;

                .task-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .event-img {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                }
            }

            .task-status {
                height: 100%;
                width: 100%;
                padding-right: 10px;

                .ant-select {
                    height: 100%;
                    width: 100%;

                    .ant-select-selector {
                        border: none;
                        box-shadow: none;

                        .ant-select-arrow {
                            border-left: 1px solid rgba(0, 0, 0, 0.25);
                            padding-left: 5px;
                        }
                    }
                }
            }
        }

        .eq-card-body {
            padding-bottom: 20px;

            .task-detail {
                display: flex;
                align-items: center;

                .task-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .event-img {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                }
            }

            .action-delete {
                margin-right: 25px;

                :hover {
                    cursor: pointer;
                    color: red
                }
            }

            .action-edit {
                :hover {
                    cursor: pointer;
                    color: #FF8838;
                }
            }
        }

        .edit-horse-panel {
            width: 100%;
        }
    }
}

.filters {
    background-color: #F1EDEC;
    margin-top: 20px;
    padding: 16px;
    padding-left: 30px;
    border-radius: 12px;

    .picker-label {
        font-weight: 500;
        @include Inter-14-regular-btn;
        vertical-align:bottom;
    }

    .filter-type {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .eq-input-info {
            width: 100%;
        }

        .eq-filter-radius {
            border-radius: 5px;

            .ant-select-selector {
                border-radius: 5px;
            }
        }
    }
}
