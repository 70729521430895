@import '../../shared/Vars.scss';

.eq-rs-board {
    background-color: $whiteColor;
    /* padding: 10px 35px 35px 35px;

    @media(max-width:$sm) {
        padding: 10px;
    }*/

    .ant-row {
        display: flex;
    }

    .eq-rs-heading-row {
        @include poppins-24-regular;

        color: $makoColor;
        padding: 0px 0px 0px 0px;
        margin: 10px 0px;
        border-bottom: 1px solid $orangeBorder;


        .eq-rs-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-rs-heading {
                display: flex;
                flex-direction: row;
                padding: 10px 0px 0px 0px;
                @include poppins-24-regular;
                color: $makoColor;

                @media(max-width:$sm) {
                    padding: 5px 0px 0px 0px;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }

    .eq-pb-left {
        padding: 10px 0px;

        @media (max-width:$sm) {
            padding: 0px;
        }
    }

    .eq-pb-right {
        padding: 25px;

        @media (max-width:$sm) {
            padding: 0px;
        }

        .eq-pb-search-heading {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 31px;
            
            color: $blackColor;
            margin-bottom: 10px;

            @media(max-width:$sm) {
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-pb-invite {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 31px;
            
            color: $blackColor;
            margin-bottom: 10px;

            @media(max-width:$sm) {
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-pb-invite-info {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 31px;
            color: $makoColor;
            margin-bottom: 10px;

            @media(max-width:$sm) {
                font-size: 14px;
                line-height: 23px;
            }
        }

        .eq-pb-search {
            margin: 9px 20px 0px 20px;
        }

        .eq-pb-btn {
            margin: 20px 20px 40px 20px;
            height: 40px;
        }
    }
}

