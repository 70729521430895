@import "./../shared/Vars.scss";

.eq-footer {
    /*background-color: $eqFooterBg;*/
    color: $buttonTextColor;
    /*position: absolute;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    /*height: 2.5rem;*/
    background-color: #fff;
    // padding: 48px 24px;
    padding-top: 48px;
    padding-bottom: 48px;

    @media (max-width: $sm) {
        padding: 9px 12px;
    }

    .container {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }

    .first-col {
        padding: 0 5rem 0rem 0rem;
    }


    .copy-right {
        background-color: $navBg;
        color: $navTxtColor;

        a {
            padding-left: 6px;
            color: $buttonTextColor;
        }
    }

    .eq-p-4 {
        padding: 1.22rem;
    }

    .heading {
        @include poppins-36-semibold;
        color: $darkBlackZero;
        text-align: center;
        margin-bottom: 48px;
        padding-bottom: 30px;
        border-bottom: 1px solid #A3A3A3;

        @media (max-width: $sm) {
            font-size: 26px;
        }
    }

    .equiworld-logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        /*border-bottom: 1px solid #E4E4E7;*/
        margin-bottom: 9px;

        .logo {
            display: flex;
            align-items: center;

            @media (max-width: $sm) {
                flex-direction: column;
            }

            .logo-img {
                width: 82px;
                height: auto;
                margin-right: 15px;
            }

            .logo-description {
                @include poppins-24-semibold;
                color: #18181B;
                // font-family: Plus Jakarta Sans;
                // font-size: 24px;
                // font-style: normal;
                // font-weight: 700;
                // line-height: 42px;
                width: 50%;
                margin: auto;

                @media (max-width: 977px) {
                    width: auto;
                }

                @media (max-width: $sm) {
                    text-align: center;
                }
            }
        }


        .footer-profile-btn {
            .eqButton {
                .ant-btn {
                    @include poppins-14-semibold-btn;
                    padding: 16px;
                    text-transform: capitalize;
                    // font-size: 14px;
                    color: #fff;
                    margin-bottom: 36px;
                    padding: 19px 24px;
                    width: 100%;
                    line-height: 0px;
                    // font-family: Poppins;
                    // font-weight: 600;
                }
            }
        }

        @media (max-width: 977px) {
            flex-direction: column;
        }
    }


    .comments {
        @include poppins-14-regular;
        color: $darkBlackZero;
        margin-bottom: 24px;
        text-align: left;
    }

    .contact {
        @include poppins-14-regular;
        color: $darkBlackZero;
        margin-bottom: 16px;
        text-align: left;
        border-bottom: 1px solid $orange;
        width: fit-content;
    }

    .footer-link {
        @include poppins-14-regular;
        color: $darkBlackZero;
        margin-bottom: 16px;
        line-height: 20px;
        display: block;

        &:hover {
            color: $orange;
        }
    }

    .footer-sub {
        @include poppins-14-regular;
        color: $darkBlackZero;
        margin-bottom: 16px;
        line-height: 20px;
        display: block;
    }

    .rights {
        @include poppins-14-regular;
        color: $darkBlackZero;
        margin-bottom: 16px;
        line-height: 20px;
        display: block;
        margin-top: 48px;
    }

    .ft-top {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .to-top {
            text-align: center;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: #e5e5e5;
            display: flex;
            justify-content: center;
            align-content: center;
            cursor: pointer;
            transition: 1s;

            .to-top-img {
                object-fit: cover;
                width: 17px;
                height: 20px;
                text-align: center;
                margin-top: 15px;
            }
        }

        .to-top:hover {
            box-shadow: 2px 2px 0px 0 #FF8838;
        }
    }
}