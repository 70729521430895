@import '../../shared/Vars.scss';


.eqButton {
    padding: 0px;
    margin: 0;


    .ant-btn {
        border-radius: $btnBorderRadius;
        /*line-height: 2.2715;*/
        background-color: #FF8838;
        border-color: #FF8838;
        @include poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #fff;
        /*margin: auto 5px;*/


        &:hover {
            background-color: #ff9558;
            box-shadow: 0 2px #999;
            color: $whiteColor;
        }

        &:active {
            background-color: #ff9558;
            transform: translateY(4px);
        }

        &.btn-round {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            box-shadow: 0px 2px 0px 1px #899599;

            &:hover {
                background-color: linear-gradient(#FF8838, #ededed);
                transform: translateY(2px)
            }
        }

        @media(max-width:$sm) {
            font-weight: 400;
            padding: 3px 8px;
            font-size: 12px;
        }

        @include poppins-14-regular;

        &.full-width {
            width: 100%;
        }

        &.eq-block {
            width: 100%;
        }

        &.light-bg {
            background-color: #fff;
            color: #FF8838;
        }
    }

    .ant-btn-sm {
        height: 32px;
    }

    &.centered {
        text-align: center;
    }

    .bordered {
        background-color: #fff;
        // border-color: $darkBlackZero;
        color: $darkBlackZero;
        line-height: 2px;
        border: 1px solid #ff8838;

        &:hover {
            background-color: #f9f9f9;
            color: $darkBlackZero;
        }
    }

    .lang-toggle {
        font-size: 11px;
        font-weight: 100;
        padding: 2px;
        line-height: 12px;
        height: 15px;
    }

    .btn-border-orange {
        border: 1px solid $orangeColor;
        border-radius: $btnBorderRadius;
    }

    .btn-border-red {
        background-color: $redColorHover;
        // border: 1px solid $redColorHover;
        border-radius: $btnBorderRadius;

        &:hover {
            background-color: $redColorHover;
        }
    }

    .btn-border-black {
        border: 1px solid $blackColor;
        border-radius: $btnBorderRadius;
    }

    .btn-background-orange {
        background-color: $orangeColor;
        color: $whiteColor;
        vertical-align: middle;
        @include poppins
    }

    .btn-background-purple {
        background-color: $purpleBtnClr;
        color: $whiteColor;
        vertical-align: middle;
        @include poppins;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
    }

    .btn-background-purple {
        background-color: $purpleBtnClr;
        color: $whiteColor;
        vertical-align: middle;
        @include poppins;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
    }

    .btn-gray {
        background-color: $btnBgDisable;
        color: $whiteColor;
        vertical-align: middle;
        @include poppins;
        border: none;
        // box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
        &:hover {
            background-color: $btnBgDisable;
            color: $darkBlackZero;
        }
    }


    .btn-background-white {
        background-color: $whiteColor;
        color: $btnTextBlack;
    }

    .btn-float-right {
        float: right;
    }

    .btn-full-width {
        width: 100%;
    }

    .btn-border-text-orange {
        border: 1px solid $orangeColor;
        border-radius: $btnBorderRadius;
        color: $orangeColor;
        vertical-align: middle;
    }

    .btn-light-gray {
        border: 1px solid #D9D9D9;
        color: $darkBlack;
        vertical-align: middle;
    }

    .btn-border-orange {
        border: 1px solid #FF8838;
        border-radius: $btnBorderRadius;
        color: #525757;
        vertical-align: middle;
        @include poppins;
    }

    .btn-dotted {
        border: 1px dotted $blueDate;
        @include poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }

    .eq-popup-btn {
        margin-left: 10px;
        height: 24px;
        min-width: 35px;
        padding-right: 5px;
        padding-left: 5px;
        border: hidden;
        background: $blueButton;
        @include poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $whiteColor;
        border-radius: $btnBorderRadius;
        vertical-align: middle;
    }

    .icon-space {
        margin: 3px;
    }

    .eq-btn-icon-text {
        vertical-align: middle;

        .eq-btn-icon {
            margin: 0px 3px 0px 0px;

            .eq-btn-icon img {
                width: 14px;
                height: 22px;
                vertical-align: middle;
            }
        }
    }

    .btn-height {
        height: 40px;
    }

    /*background-image: radial-gradient(75% 50% at 50% 0%, #FF8800, transparent), radial-gradient(75% 35% at 50% 80%, #f4feff, transparent);*/
    /*box-shadow: inset 0 -2px 4px 1px rgba(17, 110, 231, 0.6), inset 0 -4px 4px 1px #8de3fc, inset 0 0 2px 1px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(17, 110, 231, 0.2), 0 1px 4px 1px rgba(0, 0, 0, 0.1);*/
    transition-property: border-color, transform, background-color;
    transition-duration:0.2s;
}
