@import '../shared/Vars.scss';

.eq-update-password {
    background-color: #fff;
    padding: 10px 35px 35px;
    
    display: flex;
    justify-content: center;
    margin: 30px auto;
    flex: 1;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 80%;



    .eq-up-heading-row {
        @include Inter;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 31px;
        color: $makoColor;
        padding: 0px 0px 0px 0px;
        margin: 10px 0px;
        border-bottom: 1px solid $orangeBorder;


        .eq-up-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-up-heading {
                @include Inter;
                display: flex;
                flex-direction: row;
                padding: 10px 0px 0px 0px;
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 31px;
                color: $makoColor;

                @media(max-width:$sm) {
                    padding: 5px 0px 0px 0px;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }
    .password-error {
        color: #e66666;
        font-weight: 500;
        font-size: 16px;
    }

    .password-success {
        font-weight: 500;
        font-size: 16px;
        margin: 12px auto;
        color: #4fd3c6;
    }
    .eq-up-button{
        padding: 1rem 0;
    }
}
