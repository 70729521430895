@import '../../shared/Vars.scss';

.eq-subscription-settings {
    margin-top: 24px;

    .ant-tabs-top > .ant-tabs-nav {
        margin: 12 0 1px 0;
    }

    .ant-tabs {
        .ant-tabs-tab-btn {
            
            font-size: 12px;
            font-weight: 500;
            color: $blackColor;
        }

        .ant-tabs-nav {

            @media (max-width: $sm) {
                position: sticky;
                top: 0;
                z-index: 3;
            }
            //   border-bottom: 1px solid $orangeBorder;
            background-color: #fff;
            padding-left: 10px;
        }

        .ant-tabs-tab-active {
            border-bottom: 2px solid $orangeBorder;

            .ant-tabs-tab-btn {
                color: $orangeBorder;
            }
        }

        .ant-tabs-ink-bar {
            display: none;
        }
    }
}
