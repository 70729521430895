

@import "../../../../src/components/shared/Vars.scss";

.rg-tag-body {
    @include poppins-14-regular;
    background-color: transparent;
    display: flex;
    width: fit-content;
    align-items: center;
    text-align: center;
    border-radius: 16px;
    // z-index: 999999;
    padding: 3px;

    .tag {
        // background: #1790FC15;
        // color: $orangeColor;
        border-radius: 3px 0 0 3px;
        display: inline-block;
        height: 26px;
        line-height: 25px;
        padding: 0 14px 0 14px;
        margin: 0;
        text-decoration: none;
        -webkit-transition: color 0.3s;
        border: none;
        display: flex;
        align-items: center;
        border-radius: 16px;
        

        .tag-icon {
            font-size: 14px;
            margin-right: 7px;
            margin-bottom: 1px;
            align-items: center;
        }
    }

}