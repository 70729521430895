@import "../../../shared/Vars.scss";


.Course-order-details {
    padding: 35px, 35px,10px,10px;

    .Request-class {
        padding: 15px 35px 10px 35px;

        @media (max-width:$sm) {
            padding: 10px
        }
    }

    .eq-sl-th {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }



    .Product-Order-details {
        padding: 35px 35px 15px 35px;

        @media (max-width:$sm) {
            padding: 10px
        }

        .eq-input-info {
            width: 100%;
            max-width: 130px;
            //min-width: 100px;
            margin-left: 10px;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .picker-label {
            @include Inter-14-regular-btn;
            font-weight: 500;
            padding: 35px 35px 35px 35px;
        }

        .student-search {
            padding-top: 20px;
        }

        .eq-Search-Label {
            width: 100%;
            max-Width: 600px;
            min-Width: 200px;
            //padding-Left: 30px;
            //padding-Bottom: 10px;
            //padding-top: 10px;
            margin-top: 10px;
            margin-left: 25px;
        }
    }
}
.Course-Invitation {
    padding: 35px 35px 10px 35px;
}
.Course-order-details {
    width: 100% !important;

    .search-Col-info {
        margin-top: 35px;
        margin-left: 10px;
        margin-bottom: 10px;

        .search {
            .eq-input-info-button {
               margin-top: 10px;
            }
        }
    }
}
.Course-order-heading {
    padding :35px 35px 0px 35px ;
}

