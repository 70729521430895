@import "../../../src/components/shared/Vars.scss";

.eq-public-booking-confirmation {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: $whiteBackground;
    width: fit-content;
    // justify-content: center;
    // @media (min-width: $md) {
    //     flex-direction: row;
    //     // justify-content: space-between;
    // }

    .text-center{
        text-align: center;
    }
    h1,h2{
        margin: 0;
    }
    
    // h2,
    h4 {
        @include poppins-16-semibold;
        color: $textColor;
        margin: 0;
        
    }

    p,
    span,
    li {
        color: $textColor;
    }

    ul {
        padding-left: 20px;
    }

   // Image Section
   .image-column{

    display: flex;
justify-content: center;

.confirm-image {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;

    // @media (min-width: $md) {
    //     margin-top: 0;
    //     width: 70%;
    // }
}
}


    // Question and Answer Box
    .question-answer-box {
        // border: 1px solid $orangeColor;
        padding: 10px;
        // margin-bottom: 10px;

        h4 {
            margin: 0px;
        }
        h5 {
            margin-top: 2px;
        }
        .qa-item {
            // border: 1px solid #d9d9d9;
            padding: 5px;
            border-radius: 4px;
            // margin-bottom: 10px;
        }
    }

    // Ticket Table
    .ant-table-tbody > tr > td {
        background-color: $whiteBackground;
    }

    .total-amount {
        font-weight: bold;
        margin-top: 10px;
    }

    // Terms and Conditions
    .terms {
        margin-top: 20px;
        a {
            text-decoration: underline;
        }
    }

 

    .booking-table{
        // background-color: $orangeColor !important; /* Set your desired color */
        color: white; /* Optional: Set text color to white for better contrast */

        .ant-table-thead{
            .ant-table-cell{
                // background-color: $orangeColor !important; /* Set your desired color */

            }
        }
    }
}
