@import '../../shared/Vars.scss';

.eq-create-edit-g-container {
    // border: 1px solid $borderColor;
    box-sizing: border-box;
    // border-radius: 24px;
    padding: 22px 32px 22px 32px;
    margin: 14px 0px 14px 0px;
    font-family: Poppins;
    font-style: normal;


    @media (max-width:$sm) {
        padding: 22px 14px 22px 14px;
    }

    .eq-cg-heading {
        @include poppins-16-semibold;
        // font-weight: 600;
        // font-size: 14px;
        // line-height: 23px;
        margin: 5px 0px 5px 0px;
        
        color: $orangeColor;
    }

    .eq-cg-col-left {
        @media (min-width:$sm) {
            .ant-col {
                padding: 0px 10px 0px 0px;
            }
        }
    }

    .eq-cg-col-right {
        @media (min-width:$sm) {
            .ant-col {
                padding: 0px 0px 0px 10px;
            }
        }
    }

    .eq-cg-input {
        padding-left: 16px;
        width: 100%;
        border: 1px solid $GreyLightLine2;
        box-sizing: border-box;
        border-radius: 20px;
        line-height: 22px;
        min-height: 40px;
    }

    .eq-cg-input::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: $darkBrownText;
        opacity: 0.4;
    }

    .eq-cg-input-heading {
        @include Inter-14-regular;
        // font-weight: normal;
        // font-size: 14px;
        // line-height: 23px;
        margin: 10px 0px 10px 0px;
        color: $darkBrownText;
        padding-left: 5px;
        width: 100%;
    }

   

    .eq-cg-members-header {
        padding: 10px 0px 0px 0px;

        @media (max-width:$sm) {
            .ant-row-end {
                justify-content: center;
            }
        }

        .eq-cg-members-heading {
            @include  Inter-14-semibold;
            // font-weight: 600;
            // font-size: 14px;
            // line-height: 22px;
            
            color: $blackColor;

            @media (max-width:$sm) {
                text-align: center;
            }
        }

        .eq-cg-right {

            @media (max-width:$sm) {
                justify-content: space-between;

                .eq-cg-sort {
                    margin-left: 0px;
                    padding-left: 0px;

                    .ant-select-selector {
                        margin-left: 0px;
                        padding-left: 0px;

                        .ant-select-selection-item {
                            margin-left: 0px;
                            padding-left: 0px;
                        }
                    }
                }
            }


            .eq-cg-add {
                margin: 0px;
                padding: 0px;
            }
        }
    }

    .eq-cg-search-header {
        padding: 0px 0px 10px 0px;
    }

    .eq-cg-btn {
        margin: 20px 0px 5px 0px;

        .eq-cg-buttons {
            margin-left: 8px;
        }
    }


.group-options {
    margin-bottom: 12px;
    margin-top: 16px;

    .options-heading {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
        margin-bottom: 3px;
    }

    .options {
        margin-top: 10px;

        .options_1 {
            margin-right: 20px;
        }
    }
}
}