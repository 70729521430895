@import '../../shared/Vars.scss';

.eq-sb-container {
    background-color: white;
    padding: 20px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .eq-copy-link {
        .section-heading {
            @include Inter-14-semibold;
            margin-right: 3px;
        }
        .eq-input-copy {
            display: flex;
            margin: 2px;

            .ant-input-affix-wrapper {
                background-color: #f7f4f4;
                padding: 3px;

                .ant-input {
                    background-color: #f7f4f4;
                }
            }
        }
    }

    .eq-sb-heading-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-sb-heading {
            @include poppins-24-regular;
            margin-bottom: 10px;
            padding: 0px;
            color: $makoColor;

            @media (max-width:$sm) {
                font-size: 16px;
                line-height: 21px;
            }
        }

        .eq-sb-student-header {
            @include Inter;
            font-style: normal;

            .eq-sb-followers {
                font-weight: 500;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 0px;
            }

            .eq-sb-student-sort {
                font-weight: normal;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                padding: 0px;
                margin-bottom: -8px;
            }

            .eq-sb-student-search {
                padding: 0px;

                .ant-input {
                    border: 1px solid $borderColor;
                    border-radius: $inputBorderRadius 0px 0px $inputBorderRadius;
                }

                .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
                    border-radius: 0px $inputBorderRadius $inputBorderRadius 0px;
                    border: 1px solid $borderColor;
                }

                .anticon svg {
                    margin-top: -5px;
                }
            }

            .eq-sb-student-btn {
                margin-left: 5px;
            }
        }
    }

    @media (max-width:$sm) {

        .eq-sb-list-header {
            @include Inter;
            font-style: normal;

            .eq-sb-followers {
                font-weight: 500;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);

                @media (max-width:$sm) {
                    text-align: center;
                    margin: 1vw;
                }
            }

            .eq-sb-student-sort {
                font-weight: normal;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: -9px;
                padding: 0px;

                @media (max-width:$sm) {
                    text-align: center;
                    padding: 0px;
                    margin: 0px;
                    margin-top: 1w;
                    margin-bottom: 1vw;
                }
            }

            .eq-sb-student-search {
                margin-bottom: -5px;
                padding: 0px;

                .ant-input {
                    border: 1px solid $borderColor;
                    border-radius: $inputBorderRadius 0px 0px $inputBorderRadius;
                }

                .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
                    border-radius: 0px $inputBorderRadius $inputBorderRadius 0px;
                    border: 1px solid $borderColor;
                }

                .anticon svg {
                    margin-top: -5px;
                }

                @media (max-width:$sm) {
                    margin: 1vw;
                    margin-left: 40px;
                    margin-right: 40px;
                }
            }

            .eq-sb-student-btn {
                margin: 0px 0px 0px 5px;

                .ant-btn {
                    @media(min-width:$sm) {
                        width: 100px;
                    }
                }

                @media (max-width:$sm) {
                    margin: 12px 5px;
                }
            }
        }
    }
}