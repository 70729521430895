@import '../../shared/Vars.scss';


.eq-activity-main {
    background-color: $whiteColor;
    // Title
    .create-title {
        background-color: $whiteColor;
        border-bottom: 1px solid $navTxtColor;
        padding: 1rem;
        margin: 0 1rem;
        align-items: center;
        
        .back-icon{
            font-size: 1.5rem;
            padding-right: 1rem
        }
        @include responsive(xs) {
            background-color: $orange;
            margin: 0;
            padding: .3rem;
            font-size: 21px
        }
        
        .title-text {
             @include Inter-14-regular;
            font-weight: 300;
            color: $darkBrownBar;

            @include responsive(xs) {
                color: $eqWhiteColor;
                font-size: 22px
            }
        }

     


        .action-group {
            display: flex;

            >div {

                :first-child {
                    margin-right: 6px;
                }
            }

            @media(max-width:$sm) {
                justify-content: space-between;
                margin: 5px 0px 0px 0px;
            }
        }
    }

    // body Container
    .eq-create-activity-container {
        margin: 0px;
        background-color: $whiteColor;
        padding: 35px;

        @media(max-width:$sm) {
            padding: 10px;
        }


        .event-info-input {

            // .ant-col {
            //     margin: 0px;
            //     padding: 0px;
            // }

            .event-type {}

            .label {
                margin-top: 25px;
                @include poppins-16-semibold;
                color: $darkBlackZero;
                margin-bottom: 16px;
                
            }

            .description {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 23px;
                color: rgba(0, 0, 0, 0.85);
                padding-bottom: 15px;
            }

            .eq-input-group {
                margin-bottom: 9px;

                .input-label {
                    @include Inter-14-regular;
                    color: $darkBrownText;
                    margin-bottom: 0.1rem;
                    display: block;
                }

                .name-input {
                    min-height: 40px;
                    border-radius: $inputBorderRadius;
                    padding-left: 18px;
                }
            }

            /*.event-date-time {
                @media(min-width:$sm) {
                    padding-right: 30px;
                }*/

            .divider {
                margin: 20px 0px !important;
                border-bottom: 1px solid #C4C4C4;
            }

            .eq-create-add-card {
                border: 1px solid #d9d9d9;
                border-radius: 5px;
            }



            .col-2 {
                @media(min-width:$sm) {
                    padding-left: 25px;
                }

                .eq-cg-members-header {
                    padding: 0px 0px 0px 0px;
                    margin-bottom: 10px;

                    @media (max-width:$sm) {
                        .ant-row-end {
                            justify-content: center;
                        }
                    }

                    .eq-cg-search {
                        margin-bottom: 10px;
                    }

                    .eq-cg-right {
                        justify-content: space-between;
                        max-height: 40px;

                        .eq-cg-sort {}

                        @media (max-width:$sm) {
                            justify-content: space-between;

                            .eq-cg-sort {
                                margin-left: 0px;
                                padding-left: 0px;

                                .ant-select-selector {
                                    margin-left: 0px;
                                    padding-left: 0px;

                                    .ant-select-selection-item {
                                        margin-left: 0px;
                                        padding-left: 0px;
                                    }
                                }
                            }
                        }


                        .eq-cg-add {
                            margin: 0px;
                            padding: 0px;
                        }
                    }
                }

                .total-price {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 23px;
                    color: #626262;
                }

                .total-price-value {
                    text-align: right;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px;
                    align-items: center;
                    text-indent: 20px;
                    text-transform: capitalize;
                    color: #000000;
                    opacity: 0.4;

                    .value {
                        font-weight: normal;
                    }
                }

                .paid-text {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 23px;
                    color: rgba(0, 0, 0, 0.85);
                    text-transform: capitalize;
                }
            }

            .event-payment {
                @media(min-width:$sm) {
                    padding-left: 50px;
                }

                .eq-radio-group {
                    &.ant-radio-group {
                        width: 100%;
                    }
                }
            }

            .eq-is-input-heading {
                @include Inter-14-regular;
                // font-weight: normal;
                // font-size: 14px;
                // line-height: 163.19%;
                margin: 10px 0px 10px 0px;
                color: $darkBrownText;
                padding-left: 5px;
            }

            .eq-is-textarea {
                margin-top: 10px;
                padding: 16px;
                min-height: 142px;
                border: 1px solid #D9D9D9;
                box-sizing: border-box;
                border-radius: $inputBorderRadius;
            }

            .eq-is-textarea:focus {
                box-shadow: none;
            }

            .repeat-time {
                display: flex;
                align-content: center;

                button {
                    align-self: center;
                    margin-right: 6px;
                }
            }

            .eq-cg-search-header {
                padding: 0px 0px 10px 0px;
            }

            .eq-attendees-list-container {
                margin: 0%;
                padding: 0%;

                .eq-list-header {
                    background-color: #FAFAFA;
                }
            }

            .eq-cg-btn {
                margin: 50px 0px 5px 0px;

                .eq-cg-buttons {
                    margin-left: 8px;

                    .eq-save-btn {
                        background-color: $purpleBtnClr;
                        border-color: $purpleBtnClr;
                    }
                }
            }
        }

        .attendee-card-box {
            position: relative;


            .eq-btn-add {
                position: absolute;
                bottom: -65px;
                right: -50px;
                display: flex;
                flex-direction: column;
                z-index: 9;
                overflow: visible;
                box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
                border-radius: $btnBorderRadius;
                background-color: $greenBtnClr;
                padding: 12px;
                margin: auto;
                margin-bottom: 20px;

                @media(max-width:$sm) {
                    bottom: -70px;
                    right: 55px;
                }

                img {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    cursor: pointer;
                    height: 20px;
                    width: 20px;
                    padding: 5px;
                    border-radius: 50%;
                    transition: all ease-out 0.3s;

                    @media(max-width:$sm) {
                        height: 30px;
                        width: 30px;
                        padding: 6px;
                    }
                }

                &:hover {
                    transform: scale(1.03);
                }
            }

            .ant-row {
                &:first-child {
                    .eq-btn-icon {
                        display: none;
                    }
                }
            }
        }

        .eq-card-header {
            .header-label {
                text-transform: capitalize;
            }

            .eq-tag {
                &.ant-tag {
                    padding: 5px 10px;
                    // border-radius: $tagBorderRadius;
                }
            }
        }
    }
}