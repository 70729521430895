@import "../../shared/Vars.scss";


.custom-product-card {
  border-radius: 24px 24px;
  max-width: 380px;
  height: 100%;
  // height: 350px;

  .ant-card-body {
    padding: 0;
  }

  .product-img {
    object-fit: cover;
    border-radius: 24px;

    max-width: 100%;
    width: 350px;
    height: 150px;
    object-fit: cover;

  }

  .product-detail {
    padding: 15px;

    .product-heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      
      .product-title {
        color: #000000CC;
        @include poppins-18-semibold
      }
    }

    .product-user{
      @include poppins-14-regular;

      .icon{
      margin: 2px;
      }
    .user-text{
      text-transform: capitalize;
      color: $lightTagGrey;
      // text-decoration: underline;
      // font-weight: 500;
    }
    }

    .product-description {
      @include Inter-16-regular;
      height: 63px;
      letter-spacing: 0em;
      text-align: left;
      color: #667085;

      .description-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 15px;
      }
    }

    .product-info {
      padding-top: 15px;
      color: #667085;
      @include Inter-14-regular;

    }
  }

  .ant-card-actions {
    border-radius: 0 0 24px 24px;
    align-items: center
  }

  .custom-action-send {
    // width: 100%;
    margin-left: 5px;

    // @media (max-width: $sm) {
    //   margin-left: 25px;
    // }
  }

  .eqButton {
    .custom-action-buy {
      width: 50%;
    }
  }
  

}









.product-info {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;


}

.border-line {
  padding: 8px 0;
  border-bottom: 1px solid #f0f4f9;

}