@import "../../shared/Vars.scss";

.eq-trainer-profile-container {
  //   background-color: $whiteBackground;
  padding: 10px 35px 35px 35px;

  @media (max-width: $sm) {
    padding: 10px;
  }

  // .ant-row {
  //   display: flex;
  // }

  .eq-profile-Heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #525757;
    padding: 10px 0px 16px 0px;
    // border-bottom: 1px solid #C4C4C4;
    // margin-bottom: 25px;
  }

  .eq-board-line {
    border-bottom: 1px solid $orangeBorder;
  }

  .eq-profile-button {
    padding: 5px 0px 5px 0px;
  }

  .trainer-info {
    @media (min-width: $sm) {
      padding-right: 15px;
    }

    .profile-img {
      // object-fit: cover;
      text-align: center;
      justify-content: center;

      img {
        object-fit: cover;
        border-radius: 128px;
        height: 128px;
        width: 128px;
      }
    }

    .profile-name {
      justify-content: center;

      margin-top: 25px;
      @include poppins-24-regular;
      color: #000000;
      text-transform: capitalize;
    }

    .profile-certified {
      justify-content: center;

      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #525757;
      padding: 5px 0px 5px 0px;
      // border-bottom: 1px solid #C4C4C4;
      // margin-bottom: 25px;
    }

    .contact {
      justify-content: center;
      margin-bottom: 10px;

      .msg-icon {
        margin-right: 10px;
      }

      .msg-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #626262;
      }
    }

    .eq-subscribe-button {
      justify-content: center;
      // padding: 0px 0px 10px 0px;
    }

    .eq-board-line {
      border-bottom: 1px solid $orangeBorder;
      margin: 20px 0px;
    }

    .profile-info-h {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      color: #525757;
    }

    .profile-info-i {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 28px;
      color: #525757;
      text-align: end;

      //   @include responsive(xs) {
      //     text-align: center;
      // }
    }

    .section-divider {
      border-bottom: 1px dashed #c4c4c4;
      margin: 20px 0px;
    }

    .section-heading {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      
      color: #000000;
      margin: 20px 0px 10px 0px;
    }

    .eq-invite-link {
      .ant-col {
        width: 100%;
        // max-width: 80%;
      }
    }

    .eq-col-discipline {
      background: #ffffff;
      border: 1px solid #abd6ff;
      box-sizing: border-box;
      border-radius: 37px;
      padding: 5px 10px;
      margin: 10px 0px 0px 0px;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: #626262;
    }

    .eq-list {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      color: #0081fe;

      .list {
        margin-left: -25px;
      }
    }
  }

  .col-right {
    @media (min-width: $sm) {
      padding-left: 15px;
    }

    .profile-about {
      .about-message {
        cursor: pointer;
      }

      .about-info {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        color: #626262;
        margin-bottom: 20px;
        text-align: justify;

        .about-left-col {
          @media (min-width: $sm) {
            padding-right: 15px;
          }
        }

        .about-right-col {
          @media (min-width: $sm) {
            padding-left: 15px;
          }

          .msg-icon {
            margin-right: 10px;
          }

          .msg-text {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #626262;
          }
        }

        .about-heading {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          
          color: #000000;
          margin: 20px 0px 10px 0px;
        }

        .about-text {
          width: 100%;
        }
      }

      .section-heading {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        
        color: #000000;
      }

      .eq-col-discipline {
        background: #ffffff;
        border: 1px solid #abd6ff;
        box-sizing: border-box;
        border-radius: 37px;
        padding: 5px 10px;
        margin: 10px 0px 0px 0px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: #626262;
      }

      // .eq-list {
      //     font-family: Poppins;
      //     font-style: normal;
      //     font-weight: normal;
      //     font-size: 14px;
      //     line-height: 28px;
      //     color: #0081FE;

      //     .list {
      //         margin-left: -25px;
      //     }
      // }

      .ant-tabs {
        .ant-tabs-nav-list {
          .ant-tabs-tab-btn {
            @include poppins-14-regular-btn;
            
            // font-size: 12px;
            // font-weight: 500;
            color: $blackColor;
            transition: color 0.3s;

            &:hover {
              color: #FF8838;
            }
          }

          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #FF8838;
            }
          }

          .ant-tabs-ink-bar {
            background: #FF8838;
          }
        }
      }

      .eq-profile-button {
        padding: 15px 0px 30px 0px;
        max-width: 300px;

        

        .btn-session {
          
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          //   line-height: 175.2%;
        }
      }
    }

    @media (max-width: $sm) {
      font-size: 16px;
      padding-bottom: 5px;
    }

    .eq-board-col {
      .eq-board-header {
        @include Inter;
        font-style: normal;
      }
    }

    .location {
      @media screen and (max-width: 780px) {
        flex: auto;

        .about-right-col {
          float: left;
        }
      }

      .loc-info {
        // display: block;
        display: flex;
        margin-top: 10px;
        padding-bottom: 15px;
        align-items: center;

        .my-loc {
          margin: 2px 0px 0 8px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #0081fe;
        }
      }

      .about-right-col {
        display: inline-block;
        float: right;
        padding-right: 70px;

        @media screen and (max-width: 780px) {
          float: left;
          margin-bottom: 20px;
        }

        @media (min-width: $sm) {
          padding-left: 15px;
        }

        .msg-icon {
          margin-right: 10px;
          align-items: center;
          display: flex;
        }

        .msg-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #626262;
        }
      }

      .about-heading {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        
        color: #000000;
        margin: 20px 0px 10px 0px;
      }
    }

    .eq-board-line {
      border-bottom: 1px solid $orangeBorder;
    }
  }
}

.eq-board-line {
  border-bottom: 1px solid $orangeBorder;
}