@import '../../shared/Vars.scss';

.prod-list {
    .eq-sl-row {
        @include Inter-14-500;

        .row {
            // justify-content: space-around;
            align-items: center;
            padding: 48px 0px;
            width: 100%;

            .link-category {
                display: flex;
                padding: 10px 8px;
            }
        }

        .product-info {
            display: flex;
            // justify-content: space-between;
            padding-top: 7px;

        }

      
    border-radius: 10px;
    height: inherit;
    }
}