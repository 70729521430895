@import "../../../../shared/Vars.scss";

.Main-course-information {
    padding: 0px 35px 35px 35px;
    .Main-course-information, .eq-pbo-container {
        
        width: 100%;
    }
    eq-pbo-container
    .picker-label {

        @include Inter-14-regular-btn;
        font-weight: 500;
        padding: 0px 35px 35px 35px;
    }

    .student-search {
        padding-top: 20px;
    }

    .eq-Search-Label {
        width: 100%;
        max-Width: 600px;
        min-Width: 200px;
        //padding-Left: 30px;
        //padding-Bottom: 10px;
        //padding-top: 10px;
        margin-top: 10px;
        margin-left: 25px;
    }

    .eq-sl-th {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
