@import "../../../../components/shared/Vars.scss";

.eq-course-card {
    max-width: 400px;
    margin: 5px;
    // height: 500px;

    .ant-card {
        border-radius: 15px;
        min-height: 550px;
    }

    .ant-card-body {
        padding: 15px;
    }

    .skeleton-type {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .event-img {
        object-fit: cover;
        border-radius: 12px;
        max-width: 100%;
        width: 400px;
        height: 250px;
        object-fit: cover;
    }

    .event-detail {
        margin-top: 10px;
        // display: flex;
        justify-content: space-between;
        // align-items: flex-end;

        .event-type {
            color: $orangeColor;
            @include poppins-14-semibold;
        }
        // .event-title {
        //     font-size: 24px;
        //     font-weight: 600;
        //     line-height: 32px;
        //     letter-spacing: 0em;
        //     text-align: left;
        // }
        // .event-price {
        //     font-size: 12px;
        //     line-height: 12px;
        //     text-align: center;
        //     border-radius: 50px;
        //     background: #ff97512b;
        //     padding: 6px 15px;
        // }

        .icon {
            transform: rotate(45deg);
            padding-right: 15px;
            scale: 1.5;
            color: #667085;
        }

        .event-trainer {
            .trainer {
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                margin-right: 2px;
                font-weight: 900;
            }

            .trainer-name {
                color: #667085;
            }
        }

        .event-student {
            .students {
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                margin-right: 2px;
                font-weight: 900;
            }

            .no-students {
                color: #667085;
            }
        }
    }

    .event-description {
        @include poppins-16-regular;
        // font-size: 14px;
        height: 110px;
        // font-weight: 400;
        // line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #667085;

        p {
            @include poppins-16-regular;
            margin-bottom: 0.2em;
        }

        .description-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            // margin-bottom: 15px;
        }
    }

    .event-time {
        align-items: center;
        color: #667085;
        @include poppins-14-semibold;
        // font-size: 12px;
        // line-height: 12px;
        .icon {
            height: 5px;
            color: #808080;
            padding: 5px;

            :hover {
                color: #ff8838;
            }
        }


        .time {
            // @include poppins-14-regular;
            margin-left: 4px;
            // font-size: 12px;
        }
    }

    .in-days {
        color: #ff8838;
        align-items: center;
        @include poppins-14-semibold;

        .icon {
            padding: 3px;
            margin-left: 5px;
        }
    }
}
