@import '././../../shared/Vars.scss';
.eq-registrationForm {
    width: 50%;
    margin: auto;
    @media(max-width:$md) {
        width: 100%;
    }

    .show {
        display: block
    }

    .invalid {
        color: $redColor;
    }

    .valid {
        color: $whiteColor;
    }


    .password-criteria {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    
        .valid {
            color: green;
    
            // Style for the check mark icon
            .anticon-check {
                color: green;
            }
        }
    
        .invalid {
            color:$red;
    
            .dot {
                color: $red;
            }
        }
    }

}