@import '../../shared/Vars.scss';

.eq-Tb-container {
    background-color: white;
    /*padding: 20px 35px 35px 35px;

    @media (max-width:$sm) {
        padding: 10px;
    }*/
    .ant-row{
        display:flex;
    }
    .eq-Tb-heading-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-Tb-heading {
            @include poppins-24-regular;
            margin-bottom: 10px;
            padding: 0px;

            color: $makoColor;

            @media (max-width:$sm) {
                /*font-size: 16px;
                line-height: 21px;*/
            }
        }
    }

    .eq-section {
        padding-top: 20px;
        padding-bottom: 20px;

        @media (max-width:$sm) {
            .hidden-sm-students {
                display: none !important;
            }
        }

        .eq-db-h2 {
            @include poppins-16-semibold;
            
            color: $blackColor;
            margin-bottom: 10px;
            @media(max-width:$sm) {
                // padding-left: 16px;
                font-size: 14px;
                line-height: 23px;
            }


        }

        .eq-db-h2-collapse {
            @include poppins-16-semibold;
            
            color: $blackColor;
            margin-bottom: 10px;
            padding: 10px 0px 0 16px;
            @media(max-width:$sm) {
                // padding-left: 16px;
                font-size: 14px;
                line-height: 23px;
            }


        }

        .eq-events-add {
            padding: 5px 20px;
            margin-right: 16px;
            background: #FFFFFF;
            border: 1px dashed #0081FE;
            box-sizing: border-box;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 22px;
            @include Inter;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.85);

            @media(max-width:$sm) {
                margin-left: 16px;
                margin-bottom: 10px;
            }

            .plus-icon {
                margin-right: 10px;
            }
        }

        .eq-el-editCal {
            margin-right: 16px;
            @include poppins-14-regular;
            text-transform: capitalize;
            color: $blueDate;

            @media(max-width:$sm) {
                padding: 10px 16px 10px 16px;
            }

            .eq-el-editCal-icon {
                margin-right: 10px;

                .eq-el-editCal-icon img {
                    width: 17px;
                    height: 24px;
                    padding-bottom: 5px;
                }
            }
        }

        .eq-event-header-icon{ 
            cursor:pointer;
            img {
            padding: 5px 0px 5px 10px;
        }
                               }

        .eq-financials-header-right {

            @include Inter-14-regular;
          
            text-align: right;
            text-transform: capitalize;
            display: flex;
            padding-bottom: 10px;

            @media(max-width:$sm) {
                padding-left: 16px;
            }

            .eq-financials-total {
                color: #000000;
            }

            .eq-financials-link {
                color: #0081FE;
            }
        }

        .eq-db-row {
            display:flex;
            .eq-db-icon-row {
                @media(max-width:$sm) {
                    &.ant-row-bottom {
                        align-items: flex-start;
                    }
                }
            }

            .eq-db-icons {
                cursor: pointer;

                @media(max-width:$sm) {

                    .eq-db-icon {
                        margin-top:auto;
                        padding-bottom: 12px;

                        img {
                            height: 32px;
                        }
                    }

                    .eq-db-icon-text {
                        padding-left: 2em;
                        padding-right: 2em;
                    }
                }

                .eq-db-icon {
                    text-align: center;
                    transition: background 0.2s, color 0.2s;

                    &:hover {
                        transform: scale(1.1);
                    }

                    img {
                        /*box-shadow: 0.1em 0.1em 1.2em #bbb;*/
                        width: 64px;
                    }
                }

                .eq-db-icon-text {
                    @include Inter-14-regular;
                    margin-top: 10px;
                    // font-family: 'Poppins';
                    // font-style: normal;
                    // font-weight: 400;
                    // font-size: 13px;
                    // line-height: 20px;
                    text-align: center;
                    color: #000000;
                }
            }

            .eq-db-invite {
                @media(max-width:$sm) {
                    margin-top: 20px;
                }

                .eq-pb-invite-info {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: $makoColor;

                    @media(max-width:$sm) {
                        padding: 0px 16px;
                        font-size: 14px;
                        line-height: 23px;
                    }
                }

                .eq-pb-btn {
                    margin: 10px 0px;
                    height: 40px;

                    @media(max-width:$sm) {
                        padding: 0px 16px;
                    }
                }
            }
        }

        .eq-msg-header {
            padding: 0px 16px 10px 0px;

            @media(max-width:$sm) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            }

            .eq-msg-icons {
                cursor: pointer;
                max-width: 32px;
                transition: background 0.2s, color 0.2s;

                img {

                    &:hover {
                        transform: scale(1.2);
                        /*box-shadow: 0.1em 0.1em 1.2em #bbb;*/
                    }
                }
            }
        }

        .eq-events-header {
            padding: 0px 8px;

            @media(max-width:$sm) {
                padding: 10px 16px 10px 16px;
            }

            .eq-el-heading {
                @include poppins-14-regular;
                margin: 0px;
                padding-left: 8px;
                // font-family: 'Poppins';
                // font-style: normal;
                // font-weight: 300;
                // font-size: 14px;
                // line-height: 23px;
                
                color: #000000;
            }

            .eq-el-dateHeading {
                display: flex;
                flex-direction: row-reverse;

                .eq-el-date {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: $blueDate;
                    line-height: 23px;
                    margin: 0px 10px 0px 0px;
                    padding-top: 5px;

                    @media (max-width:$sm) {
                        padding-top: 0px;
                        font-weight: 600;
                    }
                }

                .eq-el-date-icon {
                    padding: 0px 10px 0px 0px
                }
            }
        }

        .eq-list-header {
            background: $trainerMessageListHeaderBackground;
            padding: 17px 16px;
            color: $darkBlack;
            @include Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            border-bottom: 2px solid $rowBottomBorder;
            margin: 0px;
        }

        .eq-event-th {
            padding: 10px 16px 10px 16px;
            margin: 0px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-transform: capitalize;
            color: $makoColor;
        }
    }

    .eq-section-divider {
        border-bottom: 1px solid #C4C4C4;
    }
}
