@import "Vars.scss";

.trainer-layout {
  &.ant-layout {
    &.ant-layout-has-sider {
      min-height: 93vh;

      .ant-layout-sider {
        background: $GreySiderBg;

        .ant-layout-sider-children {
          color: $darkBlackZero;
          @include poppins-14-regular;


          .eq-sidebar-notification{
            @include poppins-16-regular;
            margin: 1px 0px;
            background-color: #525757;
            border-color: #FF8838;
            color: #fff;
            padding: 5px 16px;
            // font-size: 14px;
            
            .notification-bar:hover {
              cursor: pointer;
            }
          }
          

          .eq-sider-inner {
            padding: 24px 16px;
            text-align: center;

            .eqButton {
              .ant-btn {
                &.ant-btn-button {
                  &.round-bordered {
                    color: #ff8838;
                    background-color: #fff;
                    border: 1px solid #ff8838;
                    padding: 8px 16px;
                    line-height: 0px;
                    border-radius: 22px;
                    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                  }
                }
              }
            }

            .bell-icon {
              padding-left: 2rem;
            }
            .profile {
              cursor: pointer;
            }

            .user {
              border-radius: 50%;
              width: 92px;
              height: 92px;
              margin-bottom: 16px;
            }

            .name {
              color: $darkBlackZero;
              @include poppins-18-regular;
              font-weight: 500;
              // margin-bottom: 12px;
              text-transform: capitalize;
            }

            .expertize {
              @include Inter-14-regular;
              font-weight: 500;
              margin-bottom: 12px;
              color: $darkBrownBar;
            }
            .border-line {
              padding: 8px 0;
              border-bottom: 1px solid $navTxtColor;
            }

            .edit {
              padding-bottom: 12px;

              a {
                @include poppins-14-regular;
                color: $darkBrownBar;

                &:hover {
                  color: $darkBlack;
                  font-weight: 600;
                }
              }

              .link-icon {
                width: 14px;
                margin-right: 6px;
                vertical-align: inherit;
              }
            }

            .route-links {
              text-align: justify;
              // padding: 12px;
              background: $GreySiderBg;
              border: 0;
              .ant-menu-item-selected {
                background-color: $GreyLightLine2;
              }
              .route-item {
                // padding: 12px;
                border-bottom: 1px solid $GreyLightLine2;

                a {
                  @include poppins-14-regular;
                  color: $darkBrownText;

                  &:hover {
                    color: $darkBlack;
                    font-weight: 600;
                  }

                  &.seleted {
                    color: $darkBlack;
                    font-weight: 600;
                  }
                }

                .link-icon {
                  width: 15px;
                  height: 15px;
                  margin-right: 6px;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        @media (max-width: $sm) {
          position: absolute;
          z-index: 5;
        }
      }
    }
  }
}
