@import "../../shared/Vars.scss";


.integration-section {
    padding: 0px 0px 25px 0px;

    .key-row{
      margin-bottom: 10px
    }

    .section-heading {
      @include poppins-16-semibold;
    }
 
    .integration-selection{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .submitButton{
      padding-top: 10px;
    }
}
