// Center content in the middle of the page
.eq-unsubscribe-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh; // Full viewport height
    background-color: #f9f9f9; // Light background color
  
    .card {
      background-color: #fff;
      padding: 2rem;
      border-radius: 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 90%; // Adjusts to screen size
      max-width: 400px; // Limits maximum size
      text-align: center;
  
      h1 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        color: #333;
      }
  
      p {
        font-size: 1rem;
        margin-bottom: 1rem;
        color: #666;
      }
  
      button {
        // padding: 0.8rem 1.5rem;
        font-size: 1rem;
        background-color: #f44336; // Red unsubscribe color
        color: #fff;
        // border: none;
        // border-radius: 0.5rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #d32f2f; // Darker red on hover
        }
      }
  
      .error-message {
        color: #d32f2f;
        font-size: 0.9rem;
        margin-top: 1rem;
      }
    }
  }
  