@import "../../../shared/Vars.scss";

.event-class {
    .heading{
      @include poppins-16-semibold;
        
        color: #000000;
        margin-bottom: 5px;
        // border-bottom: 1px solid #c4c4c4;
    }

  .tb-heading {
    @include Inter-14-semibold;
    // border-bottom: 1px solid #d9d9d9;
    background-color: #F0F2F5;
  }

  .tr-details {
    @include Inter-14-regular;
    border-bottom: 1px solid #d9d9d9;
    padding: 3px 0;
  }
  .tr-odd {
    @include Inter-14-regular;
    border-bottom: 1px solid #d9d9d9;
    padding: 3px 0;
  }
}
