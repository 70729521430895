@import '../../shared/Vars.scss';

.eq-list-container {
    margin-bottom: 20px;

    .eq-jrl-row {
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        @include Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        border-bottom: 2px solid $rowBottomBorder;

        .eq-jrl-th {
            padding: 0px;
        }
    }
}