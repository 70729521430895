@import '../../shared/Vars.scss';

.eq-st-dashboard-list {
    padding: 7px 10px;
    
    .eq-el-container {
        padding: 0px 0px 15px 0px;

        .eq-el-dateHeading {
            font-size: 11px;

            .eq-el-date {
                padding-top: 0;
            font-size: 11px;

            }
            .eq-el-date-icon{
                color: #FF8838;
                padding-right: 4px;
            }
        }
    }
    .tab-badge {

        .ant-badge-count {
            background-color: #52575757;
            scale: 0.9;

        }
    }

    .ant-tabs {
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                padding-top: 11px;
                transition: color 0.3s;

                &:hover {
                    color: #FF8838;

                    .ant-badge-count {
                        background-color: #FF8838;
                    }
                }
            }

            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;

                &:hover {
                    color: #FF8838;

                    .ant-badge-count {
                        background-color: #FF8838;
                    }
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;

                }

                .ant-badge-count {
                    background-color: #FF8838;
                }
            }

            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }

    .eq-activity-row {
        @include poppins-14-regular;
        padding: 0px;
        color: $blackColor;
        cursor: pointer;
        transition: all 0.3s ease;
        // transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

        .eq-status-row-border {
            justify-content: space-between;
            flex-wrap: nowrap;

            .action-col {
                overflow: initial;
                padding-right: 10px;

                .ant-row {
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;
                }

                .eq-event-actions-trash {
                    padding-left: 4px;
                }
            }
        }

        @include responsive(xs) {
            font-size: 10px;
        }

        .eq-status-row-border {
            /*margin: 5px 0px 8px 0px;*/
            margin: 0px;
            align-items: center;

            .content-col {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .ant-row {
                    flex-flow: nowrap;

                    .header-details {
                        .ant-row {
                            flex-flow: wrap;
                        }

                        @media (max-width: $sm) {
                            flex: 1;
                        }

                        .ant-row {
                            flex-flow: wrap;
                        }
                    }
                }
            }

            .eq-event-type {
                font-weight: 600;
                text-transform: capitalize;

                @media (max-width: $sm) {
                    line-height: 22px;
                }

                .ant-tag {
                    @media (max-width: $sm) {
                        line-height: 13px;
                        font-size: 11px;
                        vertical-align: super;
                    }
                }
            }

            .ant-tag {
                background-color: $lightTagGreenBG;
            }

            .trainer-link {
                text-transform: capitalize;
                color: $lightTagGrey;
                text-decoration: underline;
                font-weight: 500;
            }

            .eq-event-price-col {
                display: flex;
                flex-direction: row;

                .eq-event-price {
                    font-weight: bold;
                    margin-left: 5px;
                }
            }

            .eq-event-actions {
                display: flex;
                flex-direction: row;
                cursor: pointer;

                img {
                    object-fit: cover;
                    transition: background 0.2s, color 0.2s;

                    &:hover {
                        transform: scale(1.2);
                        box-shadow: 0.1em 0.1em 1.2em #bbb;
                    }
                }

                .eq-event-actions-edit {
                    padding: 0px 5px;
                }

                .eq-event-actions-trash {
                    padding: 0px 5px 0 4px;
                }
            }

            .date-icon {
                color: $darkbg;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                position: relative;
                font-weight: 900;
                margin-right: 12px;

                .eq-cal-icon {
                    width: 34px;
                    height: 34px;
                }

                .day {
                    font-size: 15px;
                    line-height: 15px;
                    position: absolute;
                    left: 8px;
                    top: 13.5px;
                }

                .month {
                    font-size: 12px;
                    line-height: 12px;
                }
            }

            .time {
                font-weight: 700;
                margin-right: 12px;
            }

            .location {
                color: $lightTagGrey;
                margin-right: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 75px;
                display: inline-block;

                svg {
                    vertical-align: text-top;
                }
            }

            .title {
                @media (max-width: $sm) {
                    font-weight: 500;
                    font-size: 12px;
                }
            }

            .price {
                margin-right: 12px;
                color: $lightTagGrey;

                svg {
                    vertical-align: middle;
                }
            }


            .trainer {
                margin-right: 12px;

                svg {
                    vertical-align: middle;
                }

                @media (max-width: $sm) {
                    vertical-align: super;
                }
            }

            .attendees {
                margin-right: 12px;
                color: $lightTagGrey;

                svg {
                    vertical-align: middle;
                }
            }
        }


    }

    .eq-activity-row:hover {
        scale: 1.01;
        transition: 0.2;

        .trainer {
            .trainer-link {
                color: #FF8838;
                cursor: pointer;
            }
        }
    }


    .eq-el-btn {
        text-align: center;
        margin-top: 15px;
    }
}