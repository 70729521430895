@import "../../shared/Vars.scss";

.eq-toggle-badge {
  .eq-btn-icon img {
    height: 14px;
  }

  .ant-radio-button-wrapper {
    background-color: #fff;
    border-color: $navTxtColor;
    @include poppins-14-regular-btn;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 6px;
    border-radius: 16px;
    border: 0px;
    margin: 5px;

    &.ant-radio-button-wrapper-checked {
      border-radius: 16px;
    }

    &:last-child {
      margin-right: 0px;
    }

    &::before {
      width: 0px;
      display: none;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: $navTxtColor;
    border-color: $navTxtColor;
    color: #fff;
  }



  // &.bordered {
  //   .ant-radio-button-wrapper-checked {
  //     border-radius: 16px;

  //     &:not(.ant-radio-button-wrapper-disabled) {
  //       color: $navTxtColor;
  //       background-color: #fff;
  //       border: 1px solid $navTxtColor;
  //     }
  //   }
  // }

  &.eq-block {
    width: 100% !important;
  }
}

.eq-toggle-orange-border {
  @media (max-width: $sm) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.eq-toggle-create-event {
    .ant-radio-button-wrapper {
      @media (max-width: $sm) {
        margin: 5px;
      }
    }
  }
  .ant-radio-button-wrapper {
    border-radius:16px !important;
    border-radius: 16px;
    border: 1px solid $orangeBorder;
    align-content: center;
    min-width: 50px;

    .eq-btn-icon img {
      min-width: 11px;
      max-width: 18px;
      margin-bottom: 3px;
      margin-right: 0px;

      @media (min-width: $sm) {
        margin-right: 5px;
      }
    }
  }

  .ant-radio-button-wrapper-checked {
    border-radius: 16px;

    &:not(.ant-radio-button-wrapper-disabled) {
      .eq-btn-icon img {
        background-color: transparent;
        filter: invert(100%);
      }
    }
  }

  .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    border-right-color: $navTxtColor;
  }
}
