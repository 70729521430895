@import '../../shared/Vars.scss';
.eq-pick {
    .ant-picker {
        width: 100%;
    }

    &.eq-event {
        width: 100%;

        .ant-picker {
            border-radius: $inputBorderRadius;
            height: 40px;
            padding-left: 16px;
        }

        &.small-height {
            height: 30px;
        }

        &.medium-height {
            border-radius: $inputBorderRadius;
            height: 32px;
        }
    }

    &.eq-card {
        width: 90%;

        .ant-picker {
            border-width: 0 0 1px;
            height: 40px;
            padding-left: .5rem;
        
            .ant-picker-input > input[disabled]{
                color: #333333;
                background-color:#fff ;
            }
        }
        .ant-picker:hover{
            border-width: 0 0 1px !important;
        }
        .ant-picker-focused{
            border-width: 0 0 1px !important;
            box-shadow: 0px 2px 0 0px #1890ff33;
        }
        .ant-picker-disabled{
            background: #fff;

        }
    }

    .eq-schedule {

        .ant-picker {
            border-radius: $inputBorderRadius;
        }

        .ant-picker-input {
            flex-direction: row-reverse;
        }

        .ant-picker-suffix {
            margin: 0px 8px 0px 0px;
            color: #333333;
        }
    }
}
