@import './../../shared/Vars.scss';

.eq-confirmRegister {
    margin: auto;
    display: flex;

    .confirm-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .confirm-image {
            height: 400px;
    width: fit-content;
        }

        .confirm-text {}

    }

}