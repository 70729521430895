@import '../../shared/Vars.scss';
@media only screen and (max-width: 600px) 
{
    .eq-popup-container {
        max-width: 48%;
    }
    }
@media only screen and (min-width: 600px) {
    .eq-popup-container {
        max-width: 384px;
    }
    }
.eq-popup-container {
    top: 123px;
    min-height: 148px;
    padding: 20px 20px;
    background-color: $whiteColor;
    position: absolute;
    right: 0;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    .eq-popup-notification {
        width: 100%;
        min-height: 100px;
        display: flex;
        flex-direction: row;
        order: 0;

        .eq-popup-check {
            display: flex;
            flex-direction: column;
            order: 0;
            width: 10%;
            font-family: $awesomeFont;
            font-style: normal;
            font-weight: 900;
            font-size: 14px;
            line-height: 22px;
            color: #43A047;
            order: 0;
        }

        .eq-popup-content {
            width: 80%;
            display: flex;
            flex-direction: column;
            order: 1;

            .eq-popup-title {
                @include Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: $greenText;
                min-height: 30px;
            }

            .eq-popup-description {
                @include Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .eq-popup-close {
            width: 5%;
            display: flex;
            flex-direction: column;
            order: 2;

            .eq-popup-vector {
                position: absolute;
                right: 10px;
                background-color: $whiteColor;
                border: hidden;
                display: flex;
                flex-direction: row;
                order: 0;
            }
        }
    }

    .eq-popup-footer {
        display: flex;
        justify-content: flex-end;
        order: 1;
    }
}
