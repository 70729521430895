@import "../../shared/Vars.scss";

.eqInput {
  padding: 0px;
  margin: 0px;
  width: 100%;

  .ant-input-affix-wrapper {
    border-radius: $inputBorderRadius;
    line-height: 2.2715;

    .ant-input {
      margin-right: 6px !important;
    }

    .ant-input-prefix {
      padding: 0 6px 0 10px;
    }
  }

  .ant-input {
    border-radius: $inputBorderRadius;
    line-height: 2.2715;
    padding-right: 6px !important;

    .ant-input-prefix {
      padding: 0 6px 0 10px;
    }
  }

  .eq-label {
    @include Inter-14-regular;
    color: $darkBrownText;
    margin-bottom: 3px;
  }
}

.eqInput-password {
  padding: 0px;
  margin: 0px;

  .ant-input-password {
    border-radius: $inputBorderRadius;
    line-height: 2.2715;

    .ant-input-prefix {
      padding: 0 6px 0 10px;
    }
  }

  .eq-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $darkBrownText;
    margin-bottom: 3px;

    &.eq-wrap {
      width: 95%;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
      display: inline-block;
    }
  }
}

.eqInput-number {
  padding: 0px;
  margin: 0px;

  .ant-input-number {
    width: 100%;
    line-height: 2.2715;
    min-height: 40px;
    border-radius: $inputBorderRadius;
    padding-left: 12px;

    .ant-input-number-handler-wrap {
      border-radius: 0px $inputBorderRadius $inputBorderRadius 0px;

      .ant-input-number-handler {
        &.ant-input-number-handler-up {
          border-radius: 0px $inputBorderRadius 0px 0px;
        }

        &.ant-input-number-handler-down {
          border-radius: 0px 0px $inputBorderRadius 0px;
        }
      }
    }
  }

  .ant-input-number-disabled {
    background: #fff;
    color: #333333;
  }

  &.avg-height-35 {
    .ant-input-number {
      min-height: 35px;
    }
  }

  .eq-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $darkBrownText;
    margin-bottom: 3px;

    &.eq-wrap {
      width: 95%;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
      display: inline-block;
    }
  }
}

.eq-card-input {

  .ant-input-number {
    width: 90%;
    border-width: 0 0 1px;
    height: 40px;
    border-radius: 0px;
  }

  .ant-input {
    width: 100%;
    border-width: 0 0 1px;
    height: 40px;
    border-radius: 0px;
  }
  .ant-input-affix-wrapper{
    border-width: 0 0 1px;
    border-radius: 0px;
    .ant-input-prefix{
      padding: 0px;
    }
  }
  

}

.eq-input-copy {
  .ant-input-affix-wrapper {
    cursor: copy;
    line-height: 0.2715;
    background-color: $buttonColorDisabled;

    input {
      background-color: $buttonColorDisabled;
    }
  }

  .ant-input {
    cursor: pointer;
  }
}

.eq-bottom-border {
  .ant-input {
    border: none;
    border-radius: 0;

    border-bottom: 1px solid #d9d9d9; /* Set the desired bottom border color */
    transition: border-bottom 0.3s ease; /* Add a transition for a smooth effect */
  }
}

//   .eq-bottom-border:focus {
//     border-bottom: 2px solid #1890ff; /* Set the desired bottom border color when focused */
//   }
