@import '../../shared/Vars.scss';

.eq-upload {
    position: relative;
    margin-right: 6px;

    .ant-image {

        .eq-image {
            cursor: pointer;
            padding: 8px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            cursor: pointer;

            &.ant-image-img {
                max-height: 100px;
            }
        }
    }

    &.selected {
        .ant-image {
            .eq-image {
                border: 3px solid $orangeColor;
            }
        }
    }
}

.feedback {
    margin-bottom: 12px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.ant-upload-list-item {
    &.ant-upload-list-item-selected {
        background-color: $orangeBorder;
    }
}
