.email-management {
    padding: 20px;
    overflow: auto;
  
    .header {
      display: flex;
      flex-direction: column;
    //   align-items: center;
      margin-bottom: 20px;
    }
  
    .filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding: 8px;
        background: #fff; // White background for the header
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for elevation
    
        .ant-form-item{
            margin-bottom: 0;
            .ant-form-item-row{
        align-items: center;

            }
        }
    }
  
    input {
      height: 40px;
      border-radius: 6px;
    //   padding: 8px;
      font-size: 16px;
      width: 300px;

    }
  
    .ant-table {
      border-radius: 8px;
      overflow: hidden;
    }
  
    .resend-button {
      color: #1890ff;
      font-weight: bold;
      &:hover {
        color: #40a9ff;
      }
    }
  }
  