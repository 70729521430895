.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fafafa;
}

.StripeElement--focus {
  border-color: #40a9ff;
}

.StripeElement--invalid {
  border-color: #ff4d4f;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.eq-stripeCard {
  .eq-cardElement {
    padding: 20px 0; 
  }
}
