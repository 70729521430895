@import "../../shared/Vars.scss";


.eq-HorseBoard-container {
    background-color: white;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .eq-HorseBoard-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 20px 0px;
        border-bottom: 1px solid $orangeBorder;
        display: flex;
        justify-content: space-between;

        .eq-HorseBoard-col {
            .eq-HorseBoard-heading {
                @include poppins-24-regular;
                margin-bottom: 10px;
                padding: 0px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        .create-Profile {
            margin-bottom: 5px;
        }
    }

    .action-delete-all {
        margin-top: 7px;
    }



    .horse-details {
        border-bottom: 1px solid #f8f8f9;

        .horse-section {
            // display: flex;
            // justify-content: space-between;
            .label {
                margin-top: 25px;
                @include poppins-16-semibold;
                color: $darkBlackZero;
                margin-bottom: 16px;
            }

            .ant-image {
                .eq-image {
                    cursor: pointer;
                    padding: 8px;
                    border: 1px solid #d9d9d9;
                    border-radius: 2px;
                    cursor: pointer;

                    &.ant-image-img {
                        max-height: 104px;
                    }
                }
            }
        }

        .input-row {
            .profile-input {
                @include Inter-14-regular;
                height: 40px;
                margin-bottom: 10px;
            }
        }

        .eq-add-horse-btn {
            padding: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .btn-background-white {
                :hover {
                    color: white;
                }
            }
        }
    }

    .horse-info-label {
        @include Inter-14-regular;
    }

    .eq-fl-row {
        @include Inter-14-500;
        margin-top: 22px;
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        border-bottom: 2px solid $graylightborder;

        .eq-fl-th {
            padding: 0px;

            .action-delete-all {
                margin-left: 20px;
                color: $orangeBorder;

                :hover {
                    cursor: pointer;
                    color: rgb(179, 2, 2);
                }
            }
        }
    }

    .eq-list-checkAll {
        padding: 17px 16px;
        color: $darkBlack;
        @include Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        .action-delete-all {
            margin-top: 0;
            margin-right: 10px;
        }
    }
    position:relative;
    .add-horse {
        display: block;
        position: absolute;
        top: -66px;
        right: 0px;
    }
}