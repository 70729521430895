@import '../../shared/Vars.scss';

.eq-message-board {
    background-color: $whiteColor;
    padding: 2%;

    // display: flex;
    // flex-wrap: nowrap;
    .eq-messageBoard-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 15px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-messageBoard-col {
            .eq-messageBoard-heading {
                margin-bottom: 10px;
                padding: 0px;
                @include poppins-24-regular;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

    }

    .eq-message-board-container {
        display: flex;
        // flex-wrap: nowrap;

        .messages-list {
            margin-top: 14px;

            .top-actions {
                margin-bottom: 7px;
            }

            .messages {
                height: 100vh;
                overflow-y: scroll;
            }
        }

        .composer-container-empty {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            .icon-message {
                color: #FF8838;
                height: 200px;
            }

            .container-empty-text {
                margin: 16px auto;
            }



        }

        .ant-row {
            display: flex;
        }

        .eq-message-composer-container {
            /*border: 1px solid $borderColor;*/
            /*border-radius: $borderRadiusBase;*/
        }

        .eq-message-list-container {
            padding-top: 0px;
        }

        .eq-message-reply-container {
            margin-top: 14px;
        }

    }


}