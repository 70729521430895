@import '../shared/Vars.scss';


.contact {
    .main {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../../public/contact/contactHeader.png');
        background-position: center;
        min-height: 660px;
        color: $whiteColor;
        margin: auto;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;


        .heading-parent {
            display: flex;
            flex-direction: column;
            align-self: center;

            h2 {
                font-family: Poppins;
                font-size: 72px;
                font-weight: 600;
                line-height: 80px;
                letter-spacing: 0px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom: 6px;
            }
        }

        .lines {
            margin-bottom: 16px;
            align-self: end;
            width: fit-content;
            margin-bottom: 16px;
        }
    }


    p {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
    }

    .about {
        display: flex;
        flex-direction: row;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-right: 32px;

        .person {
        }

        .section-heading {
            padding: 16px;

            .top-tag {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 2px;
                text-align: left;
                color: $orange;
            }

            .header {
                font-family: Poppins;
                font-size: 48px;
                font-weight: 600;
                line-height: 68px;
                letter-spacing: 0.01em;
                text-align: left;
                color: #333333;
            }

            .description {
                font-family: Poppins;
                font-size: 24px;
                font-weight: 500;
                line-height: 34px;
                letter-spacing: 0em;
                text-align: left;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 34px;
                letter-spacing: 0em;
                text-align: left;
                color: #252525;
            }
        }

        &.equinet {
            background-color: #FFF1E7;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .section-heading {
                flex: 1;
            }
        }


        .promo-img-parent {
            align-self: center;
            flex: 1;

            .promo-img {
                width: 100%;
            }
        }
    }

    .contact-form-parent {


        .contact-form {
            position: relative;
            background-color: #242424;
            border-radius: 12px;
            margin: 5% 20%;
            min-height: 500px;
            overflow: hidden;
            padding: 24px;

            .section-heading {
                font-family: Poppins;
                font-size: 60px;
                font-weight: 600;
                line-height: 80px;
                letter-spacing: 0px;
                text-align: center;
                color: #fff;
            }

            .section-desc {
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: center;
                color: #fff;
            }

            .contact-details {
                display: flex;
                flex-direction: row;
                justify-content: center;

                .detail {
                    display: flex;
                    flex-direction: row;
                    margin: 16px 0px;

                    p {
                        font-family: Poppins;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #fff;
                        padding: 0px 9px;
                        margin: auto;
                    }

                    img {
                        width: 52px;
                        height: 52px;
                    }
                }
            }

            .no-se {
                max-width: 350px;
                position: absolute;
                right: -100px;
                bottom: -58px;
            }
        }
    }
}
