@import '../shared/Vars.scss';

.eq-trainerInvitationBoard-container {
    background-color: white;
    // padding: 10px 35px 35px 35px;

    // .ant-row {
    //     display: flex;
    // }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .eq-trainerInvitationBoard-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-trainerInvitationBoard-col {
            .eq-trainerInvitationBoard-heading {
                @include Inter;
                margin-bottom: 10px;
                padding: 0px;
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 31px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }
}
