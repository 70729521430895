@import '../../shared/Vars.scss';

.horse-list {
  // .row {
  //   justify-content: space-around;
  //   align-items: center;
  //   padding: 48px 0px;
  //   width: 100%;

  //   .link-category {
  //       display: flex;
  //       padding: 10px 8px;
  //     }
  // }

  .eq-f-row {
    @include Inter-14-regular;
    padding: 17px 16px;
    color: $darkBlack;
    border-bottom: 2px solid $graylightborder;
    align-items: center;

    .eq-f-td {
      padding: 0px;

      .action-delete {
        margin-right: 25px;

        :hover {
          cursor: pointer;
          color: red
        }
      }

      .action-edit {
        :hover {
          cursor: pointer;
          color: #FF8838;
        }
      }
    }

    .horse-detail {
      display: flex;
      align-items: center;

      .horse-info {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .event-img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
  }

  .eq-card-body {
padding-bottom: 20px;
    .horse-detail {
      display: flex;
      align-items: center;

      .horse-info {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .event-img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }

    .action-delete {
      margin-right: 25px;

      :hover {
        cursor: pointer;
        color: red
      }
    }

    .action-edit {
      :hover {
        cursor: pointer;
        color: #FF8838;
      }
    }
  }
  .edit-horse-panel {
    width: 100%;
  }

}