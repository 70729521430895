@import '../../shared/Vars.scss';

.eq-card-container {
    padding: 10px;

    .eq-event-price {
        margin-left: 5px;
        font-weight: 600;
    }

    .ant-tag {
        border-radius: 12px;
    }

    .eq-group-color-1 {
        background: #F6FFED;
        border: 1px solid #B7EB8F;
        color: #52C41A;
    }



    .eq-group-color-2 {
        background: #F0F5FF;
        border: 1px solid #ADC6FF;
        color: #2F54EB;
    }



    .eq-group-color-3 {
        background: #FFF1F0;
        border: 1px solid #FFA39E;
        color: #F5222D;
    }



    .eq-group-color-4 {
        color: purple;
    }
}