

@import "../shared/Vars.scss";
.home {
  margin: 0 auto;
  .ant-row {
    display: flex;
    // justify-content: space-around;
  }
  .page-banner {
    // max-height: 80vh;
    // height: 80vh;
    @include responsive(sm) {
      max-height: 80vh;
    }
    @media (max-width: 460px) {
      height: 80vh;
    }
    padding: 0;
    margin-top: 0px;
    z-index: 99;
    opacity: 1;
    text-shadow: 0 0 0px #000;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .hero-image {
      //   width: 100%;
      //   height: 100%;
      @media (min-width: 1320px) {
        width: 100%;
      }
      @media (max-width: 460px) {
        height: 100%;
      }
    }
    .overlay {
      position: absolute;
      background: linear-gradient(to top,
          rgba(0, 0, 0, 0.45),
          transparent);
      background: linear-gradient(0deg,
          rgb(230, 227, 226) 0%,
          rgba(154, 154, 154, 0.5) 9%,
          rgba(65, 65, 65, 0.5) 19%,
          rgba(0, 0, 0, 0.7) 100%);
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 110px;

      .welcome-heading {
        width: 80%;
        @include poppins-64-bold;
        color: #fff;

        margin: 0;
        text-align: center;
        // text-transform: capitalize;
        @media (max-width: 977px) {
          font-size: 42px;
          line-height: 50px;
        }
        @media (max-width: 760px) {
          font-size: 32px;
          line-height: 40.2px;
        }
        @media (max-width: 425px) {
          font-size: 27px;
        }
      }
      .welcome-text {
        @include poppins-24-semibold;
        text-align: center;
        width: 80%;
        color: #FFF;
        // font-size: 24px;
        // font-weight: 500;
        // line-height: 150%;
        padding-bottom: 20px;
        @media (max-width: 977px) {
          font-size: 18px;
        }
        @media (max-width: 760px) {
          font-size: 16px;
        }
        @media (max-width: 425px) {
          font-size: 14px;
        }
      }

      .create-profile-btn {
        .eqButton {
          text-align: center;
          .ant-btn {
            @include poppins-22-semibold-btn;
            // font-family: Poppins;
            // color: #FFF;
            height: auto;
            text-align: center;
            padding: 12px 95px;
            // font-size: 24px;
            // font-weight: 600;
            // text-transform: capitalize;
            border-radius: 30px;
            @media (max-width: 768px) {
              padding: 8px 70px;
              font-size: 23px;
            }
            @media (max-width: 430px) {
              padding: 8px 50px;
              font-size: 20px;
            }
            @media (max-width: 320px) {
              padding: 8px 30px;
              font-size: 16px;
            }
          }
        }
        @media (max-width: 977px) {
          // scale: 0.8;
        }

      }
    }
  }
  .about {
    background: linear-gradient(180deg, #E6E3E2 0%, #f1edec00 100%);
    padding: 48px 0;
    @media (max-width: $sm) {
      padding: 18px 0;
    }
    .about-container {
      width: 80%;

      margin: auto;

      @media (max-width: 425px) {
        padding-top: 30px;
      }
      .about-info {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        .about-heading {
          @include poppins-52-regular;
          color: #FF7A21;

          font-style: normal;

        }
        .about-text {
          @include Inter-32-semibold;
          color: #000;
          padding-right: 30px;
          margin: 0;
          /* 48px */
          @media (max-width: $sm) {
            padding-right: 0;
          }
        }
        .about-sub {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-bottom: 24px;
          @media (max-width: 767px) {
            padding-bottom: 0;
            padding-top: 20px;
          }
          .about-sub-text {
            color: #444343;
            @include Inter-18-bold;
            margin: 0;
            // font-size: 23px;
            // font-style: normal;
            // font-weight: 700;
            // line-height: normal;
          }
          .sub-image {
            width: 60px;
            padding-bottom: 12px;
          }
        }
        // @media (max-width: 1930px) {
        //   .about-heading {
        //     font-size: 60px;
        //     line-height: 120%;
        //   }
        //   .about-text {
        //     font-size: 32px;
        //     line-height: 150%;
        //   }
        //   .about-sub {
        //     .about-sub-text {
        //       font-size: 18px;
        //     }
        //   }
        // }
        @media (max-width: 1300px) {
          .about-heading {
            font-size: 50px;
          }
          .about-text {
            font-size: 30px;
          }
          .about-sub {
            .about-sub-text {
              font-size: 16px;
            }
          }
        }
        @media (max-width: 1024px) {
          .about-heading {
            font-size: 38px;
          }
          .about-text {
            font-size: 23px;
          }
        }
        @media (max-width: 900px) {
          .about-heading {
            font-size: 34px;
          }
          .about-text {
            font-size: 20px;
          }
          .about-sub {
            .about-sub-text {
              font-size: 14px;
            }
          }
        }
        @media (max-width: 767px) {
          .about-sub {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .about-sub-text {}
            .sub-image {
              width: 40px;
              transform: rotate(180deg);
            }
          }
        }
        @media (max-width: 425px) {
          .about-heading {
            font-size: 32px;
          }
          .about-text {
            font-size: 18px;
          }
          .about-sub {
            .about-sub-text {
              font-size: 13px;
            }
            .sub-image {
              width: 40px;
              transform: rotate(180deg);
            }
          }
        }
        @media (max-width: 322px) {
          .about-heading {
            font-size: 28px;
          }
          .about-text {
            font-size: 16px;
          }
          .about-sub {
            .about-sub-text {
              font-size: 12px;
            }
            .sub-image {
              width: 29px;
              height: 33px;
              transform: rotate(180deg);
            }
          }
        }
      }

      .about-img {
        display: flex;
        align-items: center;
        .image-container {
          margin-left: -8px;
          margin-right: -8px;
          row-gap: 16px;
          justify-content: space-around;
          .image-col {
            position: relative;
            transition: 1s;
            width: 100%;
            overflow: hidden;
            .overlay {
              position: absolute;
              top:0;
              opacity: 0; 
              width: 100%;
              background: white;
              height: 100%;
              
              border-radius: 35% 35% 0px 0px;
              transition: 1s;
              overflow: hidden;
            }
            .overlay-holder {
              border-radius: 35.5% 0 35.5% 35.5%;
            }
            .overlay-student {
              
              border-radius: 0 0 35% 35%
            }
            .btn-type {
              font-family: Poppins;
              transform: translateX(-50%);
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 0;
              position: absolute;
              left: 50%;
              top: 8%;
              border-radius: 15px 15px 3px 15px;
              transition: 1s;
            }

            @media (max-width: 1024px) {
              .btn-type {
                font-size: 16px;
              }
            }
            @media (max-width: 767px) {
              .btn-type {
                font-size: 18px;
              }
            }
            @media (max-width: 470px) {
              .btn-type {
                font-size: 12px;
                padding: 0 7px;
              }
            }
            @media (max-width: 325px) {
              .btn-type {
                font-size: 11px;
                font-weight: 300;
              }
            }
            .image {
              width: 100%;
              transition: 1s;
            }
            .image-trainer {
              border-radius: 35% 35% 0px 0px;
            }
            .image-holder {
              border-radius: 0 0 35% 35% ;
            }
          }
        }

        .image-col:hover {
          z-index: 1000;
          cursor: pointer;
          .overlay {
            opacity: 0.8;
          }
          .btn-type {
            transform: translateX(-50%) scale(1.1);
            // top: 50%;
            color: #fff;
            background: #FF8838;
            border-color: #fff;
          }
          .image {}

        }
      }

    }
  }

  .contact {
    background: #FFF1E7;
    padding: 48px 0;
    @media (max-width: $sm) {
      padding: 22px 0px;
    }
    .contact-container {
      width: 80%;
      margin: auto;
      align-items: center;

      @media (max-width: $sm) {
        width: 100%;
        text-align: center;
      }

      .contact-info {
        @media (max-width: $sm) {
          width: 90%;
          margin: auto;
          text-align: center;
        }
        .contact-heading {
          @include poppins-36-bold;
          // font-size: 40px;
          // font-family: Rufina;
          // font-weight: 700;
          // line-height: 110%;
        }
        .contact-btn {
          padding-top: 30px;
          @include poppins-22-semibold-btn;
          // font-size: 24px;
          // font-weight: 700;

          .ant-btn {
            padding: 23px 90px;
                line-height: 0;
                border-radius: 27px;
          }
          @media (max-width: $sm) {
            text-align: center;
            padding-top: 20px;
            font-size: 20px;
            .ant-btn {
              padding: 18px 45px;
            }
          }
        }
      }
      .contact-img {
        display: flex;
        align-items: center;
        justify-content: center;
        .image {
          width: 90%;
        }
      }
      @media (max-width: 600px) {
        .contact-info {
          .contact-heading {
            font-size: 32px;
          }
        }
        .contact-img {
          margin-top: 22px;
          .image {
            width: 80%;
          }
        }
      }
      @media (max-width: 425px) {
        .contact-info {
          .contact-heading {
            font-size: 24px;
          }
        }
      }
    }
  }
  .container {
    width: 73%;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: $sm) {
      width: 100%;
      padding-right: 12px;
      padding-left: 12px;
    }
  }




  .categories {
    transition-delay: 4000s;
    padding: 48px 0px;
    background-color: #fff;
    @media (max-width: $sm) {
      padding: 22px 0px;
    }
    .categories-inner {
      // width: 53%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @media (max-width: 760px) {
        width: 75%;
        padding-right: 12px;
        padding-left: 12px;
        .row {
          margin-right: auto;
          margin-left: auto;
          justify-content: center;
        }
      }
      @media (max-width: $sm) {
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        .row {
          margin-right: auto;
          margin-left: auto;
          justify-content: center;
        }
      }
      .categories-text {
        display: flex;
        flex-direction: column;
        .categories-heading {
          @include Inter-18-semibold;
          color: #000;
          text-align: center;

          font-style: italic;

        }
        .category-description {
          text-align: center;
          // padding: 2px 60px;
          @include poppins-32-bold;
          font-style: normal;
          // font-weight: 700;
          width: 80%;
          margin: auto;
          margin-bottom: 35px;
          @media (max-width: $sm) {
            width: 100%;
            font-weight: 600;
            font-size: 20px;
          }
        }
      }

      .category-liks {
        width: 50%;
        justify-content: space-evenly;
        @media (max-width: $sm) {
          width: 100%;
        }
        .link-category {
          flex: 0;
          padding: 4px 3px;
        }
      }
    }
  }
  .events {
    background-color: #F1EDEC;
    padding: 48px 0px;
    @media (max-width: $sm) {
      padding: 22px 0px;
    }
    .events-container {
      width: 73%;
      margin-right: auto;
      margin-left: auto;
      @media (max-width: $sm) {
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
      }
      .ant-carousel {
        padding-bottom: 30px;
        @media (max-width: $sm) {
          padding-bottom: 9px;
        }
        .slick-dots-bottom {
          bottom: -40px;
        }
        .slick-dots li button {
          background: $darkBrownBar;
          opacity: 0.3;
        }
        .slick-dots li.slick-active button {
          background: $darkBrownBar;
          opacity: 1;
        }
      }
      .event-card-col {
        padding: 10px 5px 0px 5px;
        cursor: pointer;
      }
      .more-events {
        @include poppins-18-regular;
        color: $darkBlackZero;
        margin-top: 12px;
        text-align: center;
        cursor: pointer;
        .eqButton {
          .ant-btn {
            padding: 3px 70px;
          }
        }
      }
      .event-heading {
        .event-title {
          @include poppins-36-semibold;
          color: $darkBlackZero;
          text-align: center;
          margin-bottom: 20px;
          // line-height: 36px;
          @media (max-width: $sm) {
            font-size: 28px;
          }
        }
        .event-text {
          @include Inter-18-regular;
          color: #626262;
          text-align: center;
          width: 55%;
          margin: auto;
          margin-bottom: 35px;
          @media (max-width: 768px) {
            width: 90%;
          }
          @media (max-width: $sm) {
            width: 100%;
            font-size: 17px;
          }
        }
      }
      .filters {
        background-color: #fff;
        padding: 16px;
        border-radius: 12px;
        .picker-label {
          @include Inter-14-regular-btn;
          font-weight: 500;
        }
        .filter-type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .eq-input-info {
            width: 100%;
          }
          .eq-filter-radius {
            border-radius: 5px;
            .ant-select-selector {
              border-radius: 5px;
            }
          }
        }

      }
    }
  }
  .reach-out {
    background: url("../../../public/about-bg.webp");
    /*background: url('https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/c898034d-e78c-4636-4daf-603ff0ec5e00/public');*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .about-us {
      padding: 24px 0px;
      display: flex;
      @media (max-width: $sm) {
        padding: 9px 0px;
      }
      .column {
        padding: 16px 24px;
        @media (max-width: $sm) {
          padding: 12px 9px;
        }
        @media (min-width: $sm) {
          position: relative;
          flex: 1;
        }
        .desc {
          margin-bottom: 16px;
          @media (max-width: $sm) {
            margin-bottom: 9px;
          }
          @media (max-width: $sm) {
            padding-top: 9px;
          }
          .heading {
            @include Inter-18-semibold;
            font-weight: 700;
            margin-bottom: 18px;
          }
          .sub-heading {
            @include Inter-18-semibold;
            margin-bottom: 18px;
            color: $darkBlackZero;
          }
          .explanation {
            @include poppins-14-regular;
            color: $darkBrownText;
            margin-bottom: 18px;
          }
          .map-frame {
            margin-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid $GreyLightLine;
            margin-bottom: 24px;
            @media (max-width: $sm) {
              margin-bottom: 9px;
              padding-bottom: 9px;
              margin-top: 9px;
            }
          }
          .more {
            @include poppins-14-regular;
            color: $blueLink;
            margin-bottom: 18px;
            display: inline-block;
          }
          &.rider {
            margin-top: 36px;
          }
          .react-out {
            .name-input,
            .email-input {
              margin-bottom: 12px;
            }
            .hide {
              display: none;
            }
            .show {
              display: block;
              padding: 0px 0px 9px 5px;
            }
            .red {
              color: red;
            }
            .green {
              color: green;
            }
          }
        }
      }
    }
    .about-bg {
      width: 100%;
    }
    .react-out-overlay {
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(255, 255, 255, 0.99) 50%,
          rgba(255, 255, 255, 0.9) 50%);
      @media (min-width: $sm) {
        background: linear-gradient(to left,
            rgba(255, 255, 255, 0.99) 50%,
            rgba(255, 255, 255, 0.9) 50%);
      }
    }
  }
  .partners {
    display: none;
    background: url("../../../public/about-bg.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.969);
      padding: 48px 24px;
      @media (max-width: $sm) {
        padding: 24px 9px;
      }
      .heading {
        @include  Inter-14-regular;
        color: $darkBlackZero;
        font-weight: 500;
        text-align: center;
      }
      .description {
        @include poppins-14-regular;
        line-height: 28px;
        color: $darkBlackZero;
        text-align: center;
      }
      .partner-item {
        margin-bottom: 16px;
        text-align: center;
      }
      .eq-img {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin-bottom: 12px;
        text-align: center;
        object-fit: cover;
      }
      .eq-caption {
        @include poppins-16-semibold;
        color: $darkBlackZero;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 6px;
      }
      .eq-link {
        cursor: pointer;
        margin-bottom: 12px;
        color: $blueLink;
        @include poppins-14-regular;
        line-height: 28px;
      }
    }
  }
}

