@import '../../../shared/Vars.scss';

.eq-tr-dashboard-list {
    padding: 7px 10px;

    .eq-el-container {
        padding: 0px 0px 15px 0px;

        .eq-el-dateHeading {
            font-size: 11px;

            .eq-el-date {
                padding-top: 0;
            font-size: 11px;

            }
            .eq-el-date-icon{
                color: #FF8838;
                padding-right: 4px;
            }
        }
    }

    .tab-badge {

        .ant-badge-count {
            background-color: #52575757;
            scale: 0.9;

        }
    }

    .ant-tabs {
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                padding-top: 11px;
                transition: color 0.3s;

                &:hover {
                    color: #FF8838;

                    .ant-badge-count {
                        background-color: #FF8838;
                    }
                }
            }

            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;

                &:hover {
                    color: #FF8838;

                    .ant-badge-count {
                        background-color: #FF8838;
                    }
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;

                }

                .ant-badge-count {
                    background-color: #FF8838;
                }
            }

            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }


    .eq-el-btn {
        text-align: center;
        margin-top: 18px;
    }
}