@import '../../shared/Vars.scss';

.eq-message-list {
    margin: 0;
    padding: 0;

    .eq-messages-header {
        background: $trainerMessageListHeaderBackground;
        padding: 17px 16px;
        color: $darkBlack;
        @include Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        border-bottom: 2px solid $rowBottomBorder;
        margin: 0px;
    }

    .eq-messages-body {
        padding: 0;
        margin: 0;

        .eq-messages-filter-time {
            .eq-message-filter-day {
                padding: 17px 0px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 32px;
                
            }
        }
    }
}
