
@import "../../../../components/shared/Vars.scss";



.create-course-tab {
    .eq-create-course-container {
        background-color: #fff;
        /*border: 1px solid #d9d9d9;*/
        /*border-radius: 8px;*/
        padding: 20px;
        margin-bottom: 15px;
    }

    .eq-create-course-divider {
        margin: 20px 0;
        border-top: 2px solid rgba(0, 0, 0, 0.06);
    }

    .eq-create-course-input {
        margin-bottom: 20px;

        .switch-container {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .label-h2 {
            font-family: "Inter", "Sans Serif";
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 150%;
            color: #626262;
            margin-bottom: 3px;
        }
    }
}
.edit-create-product {
    padding: 1.5rem;


    .product-type {
        margin: 25px 0;

        .label {
            @include poppins-16-semibold;
            // font-family: Poppins;
            // font-weight: 600;
            // font-style: normal;
            // font-size: 16px;
            // line-height: 24px;
            color: #000000;
            margin-bottom: 16px;
            
        }
    }

    .mb-16 {
        margin-bottom: 16px;
    }

    .input-row {
        padding: 10px 0px 10px 0px;

        @media (min-width: $sm) {
            .col-left {
                padding-right: 15px;
            }

            .col-right {
                padding-left: 15px;
            }
        }

        .input-heading {
            @include Inter-14-regular;
            color: rgba(0, 0, 0, 0.85);
        }

        .profile-input {
            width: 100%;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            border-radius: $inputBorderRadius;
            padding-left: 16px;
            min-height: $inputHeight;
        }

        .profile-input1 {
            width: 100%;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            border-radius: $inputBorderRadius;
            padding-left: 16px;
            min-height: $inputHeight;
            max-height: 100px;
            background-color: navy;
        }

        .text-area {
            padding-top: 16px;
            margin: 0px;
        }
    }

    .about-my-self-heading {
        @include Inter-14-regular;
        color: #626262;
    }

    .col-spacing {
        margin-right: 16px; /* Adjust the value to create the desired space */
    }

    .space-add-between {
        display: flex;
    }

    .about-my-self-heading {
        @include Inter-14-regular;
        color: #626262;
    }
}
// .eq-input-group {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 16px; 
// }

.profile-input,
.eq-select,
.eq-date-picker,
.eq-time-picker,
.eq-input-number {
    width: 100%;
    box-sizing: border-box;
    // height : 40px !important;
   
}

.col-left {
    padding: 0 10px; 
}

.mt-16 {
    margin-top: 16px; 
}

.mb-16 {
    margin-bottom: 16px;
}

.required {
    color: red; 
}
.profile-input{
    height:30px;
}
.eq-select{
    // height:50px !important;
}
.eq-create-event-input {
    margin-bottom: 20px;

    .switch-container {
        display: flex;
        align-items: center;
        gap: 10px; /* Adjust the gap value as needed */
    }
}
.eq-std-row{
    margin: 10px;
    margin-top : 30px;
}



/* Container styling */
.image-selector {
    position: relative;
    width: 300px; /* Set as needed */
    height: 200px; /* Set as needed */
    perspective: 1000px; /* Enable 3D effect */
    margin: 20px;
    transform: rotateX(15deg) rotateY(15deg); /* Adjust for 3D rotation */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0; /* Background for contrast */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    border-radius: 10px;
    overflow: hidden;
}

/* Dotted border */
.image-selector::before {
    content: '';
    position: absolute;
    top: 10px; /* Adjust distance from the edge */
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 2px dashed #333;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transform: rotateX(20deg) translateZ(20px); /* 3D layer effect */
    pointer-events: none; /* Makes it non-interactive */
}

/* Image styling */
.image-selector img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateZ(10px); /* Elevates the image slightly for 3D depth */
}
.ql-editor {
    min-height: 100px;
}