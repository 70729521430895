
@import "../../../../components/shared/Vars.scss";


.riding-course-view {
    .ant-image-img {
        max-height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .stat-card {
        background: #f9f9f9;
    }
}
