@import '../../shared/Vars.scss';

.eq-el-container {
    // border-bottom: 1px solid $GreyLightLine2;
    padding: 15px 0px 15px 0px;

    .eq-el-divider {
        padding: 10px 0px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        text-transform: capitalize;
        color: $makoColor;
        border-bottom: 1px dashed $dividerBorderColor;
    }

    .eq-el-editCal {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: $blueDate;
        margin: 20px 0px;

        .eq-el-editCal-icon {
            margin-right: 10px;
            padding-bottom: 15px;

            .eq-el-editCal-icon img {
                width: 17px;
                height: 24px;
            }
        }
    }

    .eq-el-heading {
        @include poppins-16-semibold;
        // font-family: Poppins;
        // font-style: normal;
        // font-weight: 600;
        // font-size: 16px;
        // line-height: 32px;
        
    }

    .eq-el-dateHeading {
        display: flex;
        flex-direction: row-reverse;

        .eq-el-date {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-transform: capitalize;
            color: $blueDate;
            line-height: 23px;
            margin: 0px 10px 0px 0px;
            padding-top: 5px;

            @media (max-width:$sm) {
                font-weight: 600;
            }
        }

        .eq-el-date-icon {
            padding: 0px 10px 0px 0px
        }
    }

    .eq-event-th {
        padding: 10px 16px 10px 16px;
        margin: 0px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-transform: capitalize;
        color: $makoColor;
    }

}

.eq-el-container-today {
    border-top: 1px solid #C4C4C4;
    margin-top: 35px;
}

