@import '../../../shared/Vars.scss';


.eq-productOrder-invite {


    .eq-invite-row {
        display: flex;
        @include Inter;
        font-style: normal;
        font-weight: normal;
        // font-size: 14px;
        line-height: 22px;
        color: $darkBlack;
        border-bottom: 2px solid $graylightborder;
        padding: 1rem 1rem;
        align-items: center;
        .ant-row {
            align-items: center;
        }
        .user-img {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
        }
    
        &-title {
            font-size: 16px;
            font-weight: bold;
        }
    
        @include responsive (xs) {
            &-subTitle {
                font-size: 13px;
            }
    
            &-dis {
                font-size: 13px;
                line-height: 15px;
            }
    
        }
    
    
    }

    .eq-invites-list {
        padding: 0.5rem;
    }

    .invite-action {
        margin: 2rem .5rem;
    }

    .invite-accept-btn {
        background: limegreen;
        color: white;
    }

    .invite-reject-btn {
        background: red;
        color: white;
    }


}