@import "../../shared/Vars.scss";

.eq-join-event {
  .event-tags {
    // margin: 1.5rem 0;
    margin: -1.9rem -0.4rem;
    padding-bottom: 1rem;

    @include responsive(xs) {
      margin: 0;
    }

    img {
      padding: 0px 6px;
    }

    .eq-tag {
      &.ant-tag {
        padding: 5px 10px;
        // border-radius: $tagBorderRadius;
      }
    }
  }

  .join-class-steps {
    width: 65%;

    .ant-steps-item {
      display: inline-table;
      margin-bottom: 15px;

      
      .ant-steps-item-container {
        .ant-steps-item-process {
          background-color: #ff9751;
        }

        .completed-step {
          background-color: #ff9751;
        }
      }
    }

    @media screen and (max-width: 720px) {
      width: 100%;
    }

    .ant-steps-item-process .ant-steps-item-icon {
      background-color: #ff9751;
      border: #ff9751;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: #fff;
      border-color: #ff9751;

      svg {
        fill: #ff9751;
      }
    }

    .ant-steps-item-wait .ant-steps-item-icon {
      background: #979797;
      border-color: #979797;

      span {
        color: #fff;
      }
    }
  }

  .eq-join-event-container {
    margin: 0px;
    background-color: $whiteColor;
    // padding: 35px 35px 35px 35px;
    padding-top: 35px;
    padding-bottom: 35px;

    .custom-title {
      @include poppins-20-semibold;
      
      color: #000000;
      margin-bottom: 5px;
      border-bottom: 1px solid #c4c4c4;
      margin: 0px 0px 15px;
    }

    .section {
      margin: 20px 0px 10px 0px;
    }

    .section-heading {
      // flex:auto;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      
      color: #000000;
      margin-bottom: 5px;
      border-bottom: 1px dashed #c4c4c4;
    }

    @media (max-width: $sm) {
      padding: 10px;
    }

    .add-class {
      // justify-content: space-between;
      align-items: center;

      .eq-input-group {
        margin-right: 10px;
        margin-bottom: 7px;
        padding-top: 7px;
      }
    }

    .create-title {
      padding-bottom: 9px;
      border-bottom: 1px solid $navTxtColor;

      .title-text {
        font-weight: 300;
        color: $darkBrownBar;
      }

      .action-group {
        display: flex;

        > div {
          :first-child {
            margin-right: 6px;
          }
        }

        @media (max-width: $sm) {
          justify-content: space-between;
          margin: 5px 0px 0px 0px;
        }
      }
    }

    .register-event {
      justify-content: center;
    }

    .register-event-gust {
      display: flex;
      flex-direction: column;
      align-items: center;

      .section-heading {
        width: 100%;
        text-align: center;
      }
    }

    .event-info-input {
      .eqButton {
        max-width: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        align-content: flex-start;
      }

      .ant-btn {
        width: 400px;
        margin: 0 auto;
        height: 40px;
      }

      .label {
        margin-top: 25px;
        @include poppins-16-semibold;
        color: $darkBlackZero;
        margin-bottom: 16px;
        
      }

      .description {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 15px;
      }

      .eq-input-group {
        margin-bottom: 9px;
        padding: 2px;
        .input-label {
          @include Inter-14-regular;
          color: $darkBrownText;
          margin-bottom: 0.1rem;
          display: block;
        }

        .name-input {
          min-height: 40px;
          border-radius: $inputBorderRadius;
          padding-left: 18px;
        }
        .input-select {
          width: 100%;
          
          .ant-select-selector {
            border-radius: $inputBorderRadius;
            min-height: 40px;
            align-items: center
        }

          
        }
      }

      /*.event-date-time {
                @media(min-width:$sm) {
                    padding-right: 30px;
                }*/

      .divider {
        margin: 20px 0px !important;
        border-bottom: 1px solid #c4c4c4;
      }

      .eq-create-add-card {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }

      .col-2 {
        @media (min-width: $sm) {
          padding-left: 25px;
        }

        .eq-cg-members-header {
          padding: 0px 0px 0px 0px;
          margin-bottom: 10px;

          @media (max-width: $sm) {
            .ant-row-end {
              justify-content: center;
            }
          }

          .eq-cg-search {
            margin-bottom: 10px;
          }

          .eq-cg-right {
            justify-content: space-between;
            max-height: 40px;

            .eq-cg-sort {
            }

            @media (max-width: $sm) {
              justify-content: space-between;

              .eq-cg-sort {
                margin-left: 0px;
                padding-left: 0px;

                .ant-select-selector {
                  margin-left: 0px;
                  padding-left: 0px;

                  .ant-select-selection-item {
                    margin-left: 0px;
                    padding-left: 0px;
                  }
                }
              }
            }

            .eq-cg-add {
              margin: 0px;
              padding: 0px;
            }
          }
        }

        .total-price {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          color: #626262;
        }

        .total-price-value {
          text-align: right;
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          align-items: center;
          text-indent: 20px;
          text-transform: capitalize;
          color: #000000;
          opacity: 0.4;

          .value {
            font-weight: normal;
          }
        }

        .paid-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.85);
          text-transform: capitalize;
        }
      }

      .event-payment {
        @media (min-width: $sm) {
          padding-left: 50px;
        }

        .eq-radio-group {
          &.ant-radio-group {
            width: 100%;
          }
        }
      }

      .eq-is-input-heading {
        @include Inter-14-regular;
        // font-weight: normal;
        // font-size: 14px;
        // line-height: 163.19%;
        margin: 10px 0px 10px 0px;
        color: $darkBrownText;
        padding-left: 5px;
      }

      .eq-is-textarea {
        margin-top: 10px;
        padding: 16px;
        min-height: 142px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: $inputBorderRadius;
      }

      .eq-is-textarea:focus {
        box-shadow: none;
      }

      .repeat-time {
        display: flex;
        align-content: center;

        button {
          align-self: center;
          margin-right: 6px;
        }
      }

      .eq-cg-search-header {
        padding: 0px 0px 10px 0px;
      }

      .eq-attendees-list-container {
        margin: 0%;
        padding: 0%;

        .eq-list-header {
          background-color: #fafafa;
        }
      }

      .eq-cg-btn {
        margin: 50px 0px 5px 0px;

        .eq-cg-buttons {
          margin-left: 8px;

          .eq-save-btn {
            background-color: $purpleBtnClr;
            border-color: $purpleBtnClr;
          }
        }
      }
    }

    .attendee-card-box {
      position: relative;

      .eq-btn-add {
        position: absolute;
        bottom: -65px;
        right: -50px;
        display: flex;
        flex-direction: column;
        z-index: 9;
        overflow: visible;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
        border-radius: $btnBorderRadius;
        background-color: $greenBtnClr;
        padding: 12px;
        margin: auto;
        margin-bottom: 20px;

        @media (max-width: $sm) {
          bottom: -70px;
          right: 55px;
        }

        img {
          text-align: center;
          display: flex;
          justify-content: center;
          align-content: center;
          cursor: pointer;
          height: 20px;
          width: 20px;
          padding: 5px;
          border-radius: 50%;
          transition: all ease-out 0.3s;

          @media (max-width: $sm) {
            height: 30px;
            width: 30px;
            padding: 6px;
          }
        }

        &:hover {
          transform: scale(1.03);
        }
      }

      .ant-row {
        &:first-child {
          .eq-btn-icon {
            display: none;
          }
        }
      }
    }

    .eq-card-header {
      .header-label {
        text-transform: capitalize;
      }

      .eq-tag {
        &.ant-tag {
          padding: 5px 10px;
          // border-radius: $tagBorderRadius;
        }
      }
    }
  }
}
