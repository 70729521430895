@import "../../shared/Vars.scss";

.eq-trainer-products-board {
  background-color: $whiteColor;
  padding: 10px 35px 35px 35px;

  @media (max-width:$sm) {
    padding: 10px;
  }

  .add-btn-icon {
    float: right;
    width: 2.8rem;
    display: flex;
    flex-direction: column;
    z-index: 9;
    overflow: visible;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
    border-radius: $btnBorderRadius;
    background-color: $greenBtnClr;
    padding: 12px;
    // margin: auto;
    margin-bottom: 20px;

    @media (max-width: $sm) {
      bottom: -60px;
      right: -17px;
      padding: 10px;
      margin-bottom: 25px;
      float: right;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  .eq-tp-heading-row {
    padding: 4px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    border-bottom: 1px solid $orangeBorder;

    .eq-tp-heading-col {
      padding: 0px;
      margin: 0px;

      .eq-tp-heading {
        @include poppins-24-regular;
        padding: 0px 0px 0px 0px;
        color: $makoColor;

        @media (max-width: $sm) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    .eq-event-buttons {
      margin: 0px 0px 0px 5px;

      @media (max-width: $sm) {
        margin: 0px 0px 5px 5px;
      }
    }
  }

  .eq-section {
    margin-bottom: 25px;
  }

  .product-type {
    // padding-bottom: 35px ;
    // .label {
    //   margin-top: 25px;
    //   @include poppins-16-semibold;
    //   color: $darkBlackZero;
    //   margin-bottom: 16px;
    //   
    // }
  }

  .label {
    // margin-top: 25px;
    @include poppins-16-semibold;
    color: $darkBlackZero;
    padding-top: 10px;
    
  }

  .dis {
    @include Inter-14-regular;

    @include responsive (xs) {
      font-size: 13px;
    }
  }

  .ant-row {
    display: flex;
  }

  .eq-event-header-icon {
    cursor: pointer;

    img {
      padding: 5px 0px 5px 10px;
    }
  }

  .filter-row {
    margin-right: 33px;
    margin-left: 33px;

    .filters {
      background-color: #FAFAFA;
      padding: 16px;
      border-radius: 12px;
      margin-top: 20px;
      margin-bottom: 25px;

      .picker-label {
        @include Inter-14-regular-btn;
        font-weight: 500;
      }

      .filter-type {
        display: flex;
        justify-content: space-between;
        // align-items: center;

        .eq-input-info {
          width: 100%;
        }

        .eq-filter-radius {
          border-radius: 5px;

          .ant-select-selector {
            border-radius: 5px;
          }
        }

        .search-col {}
      }

    }
  }

}

.customCollapse {
  overflow: hidden;
  transition: 0.4s max-height;
}

.customCollapse>div {
  overflow: auto;
}

.sample-content {
  background: #ccc;
  padding: 10px;

  h1,
  p {
    margin: 0;
    text-align: center;
  }
}

.collapseHeader {
  padding: 10px;
  display: block;
  background: #333;
  color: white;
  width: 100%;
  border: 0;
  cursor: pointer;
}