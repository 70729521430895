@import '../../shared/Vars.scss';

.eq-p-row {
    border-bottom: 1px dashed $dashedBorder;
    padding: 0px 10px 5px 10px;
    margin-bottom: 15px;
    font-family: Poppins;
    font-style: normal;

    @media (max-width:$sm) {
        border: none;
        margin: 0px;
        padding: 0px;

        .eq-pl-img img {
            object-fit: cover;
            height: 32px;
            width: 32px;
        }

        .eq-pl-msg {
            margin: 0px;
        }
    }

    .eq-p-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .eq-pl-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .eq-pl-img {
        object-fit: cover;

        .user {
            width: 61.54px;
            height: 64px;
            border-radius: 15px;

            @media (max-width:$sm) {
                height: 32px;
                width: 32px;
            }
        }
    }

    .eq-pl-info {
        padding: 0px 10px 20px 20px;

        @media (max-width:$sm) {
            margin: 0px;
            padding: 17px 16px;
        }

        .eq-pl-name {
            @include Inter-14-regular;
            text-transform: capitalize;
            color: $blackColor;
            margin-bottom: 5px;

            @media (max-width:$sm) {
                font-size: 16px;
            }
        }

        .eq-pl-address {
            @include Inter-14-regular;
            padding: 2px 0px 0px 10px;
            font-weight: normal;
            // font-size: 14px;
            text-transform: capitalize;
            color: #000000;

            .eq-pl-address-icon {
                @media (max-width:$sm) {
                    margin-right: 10px;
                }
            }

            @media (max-width:$sm) {
                margin: 0px 0px 15px 0px;
                padding: 0px;
            }
        }

        .eq-p-discipline-plus-profile {
            display: flex;
            flex-wrap: nowrap;
        }
        .eq-pl-discipline {
            @include poppins-14-semibold;
            margin: 15px 0px 0px 0px;
            padding: 6px 10px 0px 0px;
            // font-weight: 600;
            // font-size: 13px;
            
            color: $blackColor;

            @media (max-width:$sm) {
                margin: 0px;
                margin: 0px 0px 5px 0px;
            }
        }

        .eq-pl-discipline-list {
            margin: 15px 0px 0px 0px;
            // padding: 2px 12px;
            border: none;
            // border-radius: 37px;/
            // font-weight: normal;
            // font-size: 13px;
            line-height: 19px;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: $blackMenu;

            @media (max-width:$sm) {
                margin: 5px 5px 5px 0px;
            }

            .eq-pl-discipline-list-active {
                color: $blueLink;
            }
        }

        .eq-p-profile-visit {
            width: max-content;
    
            .eq-p-profile-btn {
                width: max-content;
                .eq-p-profile-link {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 31px;
                    text-transform: capitalize;
                    color: $blueLink;
                    cursor: pointer;
    
                    @media (max-width:$sm) {
                        margin: 10px 0px 0px 0px;
                    }
                }
            }
    
        }
    }

    .eq-pl-msg {
        border: 1px solid $orange;
        box-sizing: border-box;
        border-radius: 37px;
        height: 32px;
        width: 32px;
        padding: 2.5px 8.5px 0px 7.5px;
        cursor: pointer;

        .eq-pl-icon {
            height: 14px;

            .eq-pl-icon img {
                object-fit: cover;
                height: 12px;
                align-content: center;
            }
        }
    }

    


}