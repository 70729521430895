@import '../../shared/Vars.scss';
.tag-body {
    @include Inter;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin: 0 2px 2px 0;
    

    .tag {
        background: #FF97512B;
        border-radius: 3px 0 0 3px;
        color: black;
        display: inline-block;
        height: 26px;
        line-height: 25px;
        padding: 0 20px 0 23px;
        position: relative;
        margin: 0;
        text-decoration: none;
        -webkit-transition: color 0.3s;
        border: none;
        display: flex;
        align-items: center;

        &::before {
            background: #fff;
            border-radius: 10px;
            box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
            content: '';
            height: 6px;
            left: 10px;
            position: absolute;
            width: 6px;
            top: 10px;
        }

        // &::after {
        //     background: #fff;
        //     border-bottom: 13px solid transparent;
        //     border-left: 10px solid #eee;
        //     border-top: 13px solid transparent;
        //     content: '';
        //     position: absolute;
        //     height: 26px;
        //     right: 0;
        //     top: 0;
        // }

        &:hover::after {
            border-left-color: #FF8838;
        }

        &.dicipline {
            background:  #9cb9ca;
            font-weight: 600;

        }

    }

    .after {
        background: transparent;
        border-bottom: 13px solid transparent;
        border-left: 10px solid #FF97512B;
        border-top: 13px solid transparent;

        &.dicipline {
            border-left: 10px solid #9cb9ca;

        }


    }

    &:hover {
        .tag {
            background-color: #FF8838;
            color: white;
            &.dicipline {
                background:  #9cb9ca;
                color: black;
                
            }
        }

        .after {
            background: transparent;
            border-bottom: 13px solid transparent;
            border-left: 10px solid #FF8838;
            border-top: 13px solid transparent;

            &.dicipline {
                border-left: 10px solid #9cb9ca;
                
            }

        }

    }

    //profile 
    &.borderColor {
        background: transparent;

        .tag {
            background: #FF97512B;
            color: black;
        }

        &::before {
            background: #f0f4f9;
        }

        .after {
            border-bottom: 13px solid transparent;
            border-left: 10px solid #FF97512B;
            border-top: 13px solid transparent;
        }
    }

}