@import '../../shared/Vars.scss';

.ant-input-group {
    &.ant-input-group-compact {
        &.eq-search {
            height: 35px;

            .ant-input-group-wrapper {

                &.round-input {
                    height: inherit;

                    .ant-input-wrapper {
                        &.ant-input-group {
                            height: inherit;

                            .ant-input-affix-wrapper {
                                border-top-left-radius: $borderRadiusBase;
                                border-bottom-left-radius: $borderRadiusBase;
                                border-color: $navTxtColor;
                                height: inherit;

                                .ant-input {
                                    /*height: inherit;*/
                                }

                                .ant-input-prefix {
                                    .anticon svg {
                                        margin-top: 0px;
                                    }
                                }
                            }

                            .ant-input-group-addon {
                                border-color: $navTxtColor;

                                .ant-select-arrow .anticon > svg {
                                    vertical-align: middle;
                                }

                                .ant-select {
                                    font-weight: 700;

                                    .item-icon {

                                        .anticon svg {
                                            margin-top: -9px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            > *:last-child {
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                height: inherit;
            }

            .ant-btn-primary {
                background-color: $navTxtColor;
                border-color: $navTxtColor;
            }
        }
    }
}

/*
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-radius: 1px;
}*/
/*ant-input-affix-wrapper round-input round-search*/


.round-search {

    &.ant-input-group {

        &.ant-input-group-compact {
            height: 40px;

            .ant-input-prefix {
                font-size: 16px;
                line-height: 24px;

                .anticon svg {
                    margin-top: 2px;
                }
            }

            .ant-input {
                border-radius: 0px;
                @include poppins;
                font-style: normal;
            }

            > *:first-child {
                border: 1px solid $navTxtColor;
                border-top-left-radius: 24px;
                border-bottom-left-radius: 24px;
            }
        }
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: none;
    }
}
