@import "../../../../shared/Vars.scss";

.req-list-generic{
margin-top: 15px;
    .eq-fl-row {
        @include Inter-14-500;
        margin-top: 22px;
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        border-bottom: 2px solid $graylightborder;
    }

    .eq-fl-th {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .eq-f-row {
        @include Inter-14-regular;
        padding: 17px 16px;
    
        color: $darkBlack;
        border-bottom: 2px solid $graylightborder;
    
        .eq-f-td {
            padding: 0px;
        }
    
    }
}