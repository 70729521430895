@import '../../shared/Vars.scss';

.eq-date-pick {
    display: grid;

    .rmdp-container {
        display: grid;

        input {
            width: 100%;
            overflow: scroll;
            max-height: auto;
        }

        .rmdp-input {
            width: 100%;
            border-radius: $inputBorderRadius;
            min-height: 40px;
            padding-left: 16px;
            overflow: hidden;
            overflow-wrap: break-word;

            input {
                width: 100%;
            }
        }

        .ep-arrow {
            visibility: hidden;
            display: none;
        }

        .rmdp-wrapper {
            margin-top: -20px;
            margin-bottom:-40px;
            background-color: #fff;

            .rmdp-header {
                color: rgba(0, 0, 0, 0.85);
                flex: auto;
                font-weight: 500;
                line-height: 40px;

                .rmdp-arrow-container {
                    background-color: #fff;

                    &:hover {
                        border: none;
                        box-shadow: none;
                        color: black;

                        .rmdp-arrow {
                            border: solid rgba(0, 0, 0, 0.85);
                            border-width: 0 2px 2px 0;
                            height: 7px;
                            width: 7px;
                        }
                    }


                    .rmdp-arrow {
                        border: solid rgba(0, 0, 0, 0.25);
                        border-width: 0 2px 2px 0;
                        height: 7px;
                        width: 7px;
                    }
                }
            }

            .rmdp-day-picker {

                .rmdp-week {
                    .rmdp-week-day {
                        color: rgba(0, 0, 0, 0.85);
                    }

                    .rmdp-day {



                        &.rmdp-selected {
                            span {
                                margin: 2px;
                                border: none;
                                border-radius: 2px;

                                &:not(.highlight) {
                                    &:hover {
                                        background-color: #0074d9;
                                        box-shadow: 0 0 3px #8798ad;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        span {
                            margin: 2px;
                            border: none;
                            border-radius: 2px;

                            &:not(.highlight) {

                                &:hover {
                                    transition: background 0.3s, border 0.3s;
                                    background-color: #f5f5f5;
                                    color: black;
                                }
                            }
                        }

                        &.rmdp-today {
                            span {
                                color: black;
                                border: 1px solid #1890FF;
                                box-sizing: border-box;
                                border-radius: 2px;
                                background: #fff;

                                &:hover {
                                    background-color: #0074d9;
                                    box-shadow: 0 0 3px #8798ad;
                                    color: #fff;
                                }
                            }

                            &.rmdp-selected {
                                span {
                                    margin: 2px;
                                    border: none;
                                    border-radius: 2px;
                                    background-color: #0074d9;
                                    box-shadow: 0 0 3px #8798ad;
                                    color: #fff;

                                    &:hover {
                                        background-color: #0074d9;
                                        box-shadow: 0 0 3px #8798ad;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .rmdp-month-picker {
                border-radius: 2px;

                .rmdp-ym {

                    .rmdp-day {
                        &.rmdp-selected {
                            span {
                                border: none;
                                border-radius: 2px;

                                &:not(.highlight) {
                                    &:hover {
                                        background-color: #0074d9;
                                        box-shadow: 0 0 3px #8798ad;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        span {
                            border: none;
                            border-radius: 2px;

                            &:not(.highlight) {

                                &:hover {
                                    transition: background 0.3s, border 0.3s;
                                    background-color: #f5f5f5;
                                    color: black;
                                }
                            }
                        }
                    }

                    .rmdp-today {
                        span {
                            background-color: #0074d9;
                            box-shadow: 0 0 3px #8798ad;
                            color: #fff;
                        }
                    }
                }
            }

            .rmdp-year-picker {
                border-radius: 2px;

                .rmdp-ym {

                    .rmdp-day {
                        &.rmdp-selected {
                            span {
                                border: none;
                                border-radius: 2px;

                                &:not(.highlight) {
                                    &:hover {
                                        background-color: #0074d9;
                                        box-shadow: 0 0 3px #8798ad;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        span {
                            border: none;
                            border-radius: 2px;

                            &:not(.highlight) {

                                &:hover {
                                    transition: background 0.3s, border 0.3s;
                                    background-color: #f5f5f5;
                                    color: black;
                                }
                            }
                        }
                    }

                    .rmdp-today {
                        span {
                            background-color: #0074d9;
                            box-shadow: 0 0 3px #8798ad;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
