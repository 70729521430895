@import "../shared/Vars.scss";

.eq-board-container {
  background-color: $whiteBackground;
  padding: 10px 35px 35px 35px;

  @media (max-width: $sm) {
    padding: 10px;
  }

  .feedback {
    /*margin-top: 24px;*/
    /*margin-bottom: 24px;*/
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .label-left {
    text-align: left;
    margin-bottom: 0.2em;
  }

  .eq-board-heading {
    @include poppins-24-regular;

    color: $makoColor;
    padding-bottom: 10px;

    @media (max-width: $sm) {
      font-size: 16px;
      padding-bottom: 5px;
    }

    .eq-board-col {
      .eq-board-header {
        @include Inter;
        font-style: normal;
      }
    }
  }

  .eq-board-line {
    border-bottom: 1px solid $orangeBorder;
  }

  .profile-section {
    padding: 0px 0px 25px 0px;

    .section-heading {
      @include poppins-16-semibold;
    }
    /*border-bottom: 1px solid #C4C4C4;*/
    .eq-loc-map {
      /*max-height:500px;*/
      margin: 5px;
      padding: 0px;

      @media (max-width: $sm) {
        margin: 5px 0px;
      }

      .eq-loc-img {
        height: 450px;
        width: 100%;

        /*@media (max-width:$sm) {
                    height: 350px !important;
                }*/
      }

      .eq-loc-marker {
        /*@media (min-width:$sm) {
                    min-height: 500px;
                }*/
      }

      .ant-card-body {
        padding: 5px;
      }

      .eq-loc-heading1 {
        margin: 10px 0px;
        @include Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $blackColor;
      }

      .eq-loc-heading2 {
        margin-bottom: 5px;
        @include Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        color: $darkBrownText;
      }
    }

    .about-my-self-heading {
      @include Inter-14-regular;
      color: #626262;
    }

    .ant-tabs {
      .ant-tabs-nav-list {

        .ant-tabs-tab-btn {
          @include poppins-14-regular-btn;
          
          // font-size: 12px;
          // font-weight: 500;
          color: $blackColor;
          transition: color 0.3s;

          &:hover {
            color: #FF8838;
          }
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #FF8838;
          }
        }

        .ant-tabs-ink-bar {
          background: #FF8838;
        }
      }
    }

    .eq-pl-discipline-list {
      margin: 5px 5px 5px 0px;
      padding: 2px 12px;
      border: 1px solid $disciplineBorder;
      border-radius: $tagBorderRadius;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: $blackMenu;

      @media (max-width: $sm) {
        margin: 5px 5px 5px 0px;
      }

      .eq-pl-discipline-list-active {
        color: $blueLink;
      }

      .tag-desc {
        font-size: 11px;
        line-height: 11px;
        display: block;
        color: $darkBrownText;
        font-weight: 400;
      }
    }

    .my-loc {
      margin-left: 25px;
      @include poppins-14-regular;
      color: #0081fe;
    }

    .my-details-heading {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    .input-row {
      padding: 10px 0px 10px 0px;

      @media (min-width: $sm) {
        .col-left {
          padding-right: 15px;
        }

        .col-right {
          padding-left: 15px;
        }
      }

      .input-heading {
        @include Inter-14-regular;
        // font-style: normal;
        // font-weight: normal;
        // font-size: 14px;
        // line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }

      .profile-input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: $inputBorderRadius;
        padding-left: 16px;
        min-height: $inputHeight;
      }

      .text-area {
        padding-top: 16px;
        margin: 0px;
      }
    }

    .horse-details {
      .horse-section {

        // display: flex;
        // justify-content: space-between;
        .label {
          margin-top: 25px;
          @include poppins-16-semibold;
          color: $darkBlackZero;
          margin-bottom: 16px;
          
        }

        .ant-image {
          .eq-image {
            cursor: pointer;
            padding: 8px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            cursor: pointer;

            &.ant-image-img {
              max-height: 104px;
            }
          }
        }
      }

      .eq-sb-student-btn {
        padding: 4px;
      }
    }

    .top-section {
      display: flex;
      justify-content: space-between;

      .my-details-heading {
        @include poppins-16-semibold;
        padding-top: 10px;
        
        color: #000000;
        align-self: center;
      }

      .ant-upload-picture-card-wrapper {
        width: auto;
      }

      .ant-upload.ant-upload-select-picture-card {
        padding: 12px;
        width: auto;
        height: auto;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-right: 12px;
      }
    }
  }

  .payment-section {
    .section-heading {
      margin: 0px;
    }
    .sub-heading{
      @include poppins-14-semibold;

    }

    .sub-head {
      margin-bottom: 10px;
      @include Inter-14-regular;
    }

    .ant-row {
      align-items: center
    }
  }

  .offered-trainings {
    border: none;
  }

  .save-changes {
    margin-top: 10px;
  }

  .bank-account {
    align-items: flex-end;

    .ant-col {
      padding-left: 1rem;
    }

    button {
      margin-right: 9px;

      &::last-child {
        margin-right: 0px;
      }
    }
  }

  .account-info {
    .info-title {}

    .info-item {
      display: flex;

      .label {
        @include Inter-14-regular;
        color: $darkBrownText;
        margin-bottom: 16px;
      }

      .value {
        margin-bottom: 16px;

        .profile-input {
          border-radius: $inputBorderRadius;
          min-height: $inputHeight;
        }
      }
    }
  }

  .parent-section {
    display: flex;
    flex-direction: column;
  }
}