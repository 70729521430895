@import "../../../src/components/shared/Vars.scss";

.eq-public-sucess{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 10px;
    background-color: $whiteBackground;
    span{
        @include poppins-14-regular;
    }
    .progress-bar {
        width: 50%;
        margin: 0 auto;
        @media (max-width:$sm) {
        width: 90%;
        }
    }
    .confirm-image {
        // margin-top: -40px;
        width: 60%;
        @media (max-width:$sm) {
            width: 90%;
            }
       
    }

    .ant-progress-bg{
        height: 15px !important;
        background-color: $orangeColor;
    }
}