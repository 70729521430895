@import "../../shared/Vars.scss";

.review-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 100px;
    background: #F8F7F5;

    .heading {
        font-size: 40px;
        font-weight: 700;
        line-height: 78px;
        text-transform: capitalize;
        text-align: center;

    }

    .review {
        width: 400px !important;
        border-radius: 30px;
        background: rgba(254, 156, 89, 0.53);
        padding: 40px 25px;
        display: flex !important;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        margin: 10px auto;

        @media (max-width: 768px) {
            width: 350px !important;
        }


        .logo {
            @include cinzel;
            font-weight: 800;
            font-size: x-large;
        }

        .text-col {
            height: 100px;

            .review-text {
                line-height: 30px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;

            }
        }


        .review-image {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin-bottom: 15px;

            .image {
                width: inherit;
                height: inherit;
                overflow: auto;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .review-name {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 0;
        }

        .professional {
            color: #5A6475;
            font-size: 14px;
        }
    }

    .ant-carousel {
        .review-carousel {}
    }

}