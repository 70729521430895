@import '../../shared/Vars.scss';
.eq-list-container {
    margin-bottom: 20px;

    .eq-sl-row {
        background: $trainerMessageListHeaderBackground;
        @include Inter-14-500;
        padding: 17px 16px;
        color: $darkBlack;
        border-bottom: 2px solid $rowBottomBorder;
        margin: 0px;

        .eq-sl-th {
            padding: 0px;
            margin: 0px;
        }
    }

    @media (max-width:$sm) {
        .hidden-sm-students {
            display: none !important;
        }
    }
    
}
.mobile-view {
        
    .action-btn {
        display: flex;
        justify-content: space-evenly;

        .anticon-copy {
            color:#1890ff;
        }
        .anticon-phone {
            color:#1890ff;
        }
        :hover {
            cursor: pointer;
        }

    }
}

.copy-data-stList-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    transition: 0.2s linear all;
    border-top: 1px solid #F0F0F0;
    padding-top: 5px;

    .mail-copy {
        margin-right: 7px;
    }
}