@import '../../shared/Vars.scss';

.eq-gb-container {
    background-color: white;
    margin: 0px;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .delete-message {
        color: red;
    }

    .eq-gb-heading-row {
        padding: 10px 0px 6px 0px;
        margin: 0px 0px 0px 0px;
        border-bottom: 1px solid $orangeBorder;

        .eq-gb-col {
            padding: 0px;
            margin: 0px;

            .eq-gb-heading {
                @include poppins-24-regular;
                padding: 0px 0px 5px 0px;
                margin-bottom: 5px;

                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }

                @media (min-width:$sm) {}
            }


            .eq-gb-student-header {
                margin: 0px 0px 0px 0px;
                padding: 0px 0px 0px 0px;
                @include Inter;
                font-style: normal;

                .eq-gb-student-sort {
                    font-weight: normal;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    padding: 0px;
                    margin: 0px;
                    margin-bottom: -9px;
                }

                .eq-gb-student-search {
                    padding: 0px;

                    .ant-input {
                        border: 1px solid $borderColor;
                        border-radius: 22px 0px 0px 22px;
                    }

                    .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
                        border-radius: 0px 22px 22px 0px;
                        border: 1px solid $borderColor;
                    }

                    .anticon svg {
                        margin-top: -5px;
                    }
                }

                .eq-gb-student-btn {
                    margin-left: 5px;
                }
            }
        }
    }
}