@import '../../shared/Vars.scss';

.eq-subscription-product {
    margin-top: 24px;

    .eq-heading-row {
        // padding: 4px 0px 0px 0px;
        // margin: 0px 0px 0px 0px;
        border-bottom: 2px solid $orangeBorder;

      
    }


  
}
