@import '../../shared/Vars.scss';

.eq-board-container {
    background-color: $whiteBackground;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .eq-board-heading {
        @include poppins-24-regular;
        color: $makoColor;
        padding-bottom: 10px;

        @media (max-width:$sm) {
            font-size: 16px;
            padding-bottom: 5px;
        }

        .eq-board-col {

            .eq-board-header {
                @include Inter;
                font-style: normal;

                .eq-board-invite {
                    margin: 5px;
                    padding: 0px 5px;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: $makoColor;
                }
            }
        }
    }

    .eq-board-line {
        border-bottom: 1px solid $orangeBorder;
    }

    .section {
        margin: 12px 0px 12px 0px;
        padding: 0px;

        .heading {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            
            color: $blackColor;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 0.9em;
        }

        .search {
            margin-left: 10px;
            margin-bottom: 25px;
        }
    }
}
