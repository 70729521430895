@import "../../../../shared/Vars.scss";

.eq-create-class-card {
  margin-bottom: 10px;
  position: relative;
  @include Inter-14-regular;
  color: $darkBrownText;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .eq-card-header {
    padding: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: $blackColor;
    padding: 8px 16px;
    @include responsive(xs) {
      font-size: 11px;
      line-height: 13px;
    }
    .at-card-header {
      .at-header-row {
        justify-content: space-between;
        flex-wrap: nowrap;
        // margin: 0px;
        align-items: center;

        .content-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .ant-row {
            flex-flow: wrap;

            //   .header-details {
            //     .ant-row {
            //       flex-flow: wrap;
            //     }
            //     @media (max-width: $sm) {
            //       flex: 1;
            //     }
            //     .ant-row {
            //       flex-flow: wrap;
            //     }
            //   }
          }

          .date-icon {
            color: $darkbg;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            position: relative;
            font-weight: 900;
            margin-right: 12px;

            .eq-cal-icon {
              width: 34px;
              height: 34px;
            }

            .day {
              font-size: 15px;
              line-height: 15px;
              position: absolute;
              left: 8px;
              top: 13.5px;
            }

            .month {
              font-size: 12px;
              line-height: 12px;
            }
          }

          .header-label {
            font-weight: 700;
            margin-right: 12px;
          }
          .time {
          }
        }

        .action-col {
          overflow: initial;
          // padding-right: 10px;
          .ant-row {
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
          }
          .trash-bin {
            padding: 0px 4px 3px 4px;
          }
          .eq-event-actions-trash {
            padding-left: 4px;
          }
        }
      }
    }
  }

  .card-body {
    padding: 10px;

    .ant-tabs {
      .ant-tabs-nav-list {
          .ant-tabs-tab-btn {
            @include poppins-14-regular-btn;
            color: $blackColor;
              transition: color 0.3s;           
              &:hover {
                color: #FF8838; 
              }
            }
          .ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                  color: #FF8838;
              }
          }
          .ant-tabs-ink-bar {
              background: #FF8838;
          }
      }
  }
  

    .eq-email-tag {
      margin-top: 5px;

      &.ant-tag {
        // border-radius: $tagBorderRadius;
      }
    }

    .label-h1 {
      color: $darkBrownText;
      
      margin: 10px 0px;
      font-size: 13px;
    }

    .label-h2 {
      color: $darkBrownText;
      text-transform: capitalize;
      font-size: 12px;
      margin: 0px 0px 5px 9px;
    }

    @media (max-width: $sm) {
      padding: 0px 4px;
    }
  }

  .drg-row:last-child {
    .eq-std-row {
      border-bottom: none;
    }
  }

  .eq-std-th {
    @include Inter-14-regular;
    padding: 17px 16px;
    color: $darkBrownText;

    font-weight: 500;

    border-bottom: 2px solid $rowBottomBorder;
    align-items: center;
    justify-content: space-between;
  }
  .isDragging {
    border: 2px solid $orangeColor !important; 
  }

  .eq-std-row {
    @include Inter-14-regular;
    padding: 17px 16px;
    color: $darkBrownText;

    font-weight: 500;

    border-bottom: 2px solid $rowBottomBorder;
    align-items: center;
    justify-content: space-between;

   
    .eq-std-td {
      font-size: 12px;
      font-weight: normal;
      padding: 0 5px 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;

      .eq-up-img {
        img {
          height: 20px;
          width: 20px;
        }
      }

      .eq-up-name {
        font-weight: 500;
        color: $blackColor;
      }
    }
    // :last-child{
    // border-bottom: 10px solid $rowBottomBorder;

    // }

    .action-col {
      .actions-btn {
        cursor: pointer;

        img {
          object-fit: cover;
          transition: background 0.2s, color 0.2s;

          &:hover {
            transform: scale(1.2);
            box-shadow: 0.1em 0.1em 1.2em #bbb;
          }
        }
      }
    }
  }

  // not in use

  .ant-tabs {
    .ant-tabs-nav-list {
        .ant-tabs-tab-btn {
          @include poppins-14-regular-btn;
            transition: color 0.3s;           
            &:hover {
              color: #FF8838; 
            }
          }
        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #FF8838;
            }
        }
        .ant-tabs-ink-bar {
            background: #FF8838;
        }
    }
}


  .tab-container {
    padding: 0px 16px 16px 16px;

    @media (max-width: $sm) {
      padding: 0px;
    }
  }

  .name-number-input {
    .eqInput-number {
      width: 100%;
      .ant-input-number {
        padding-left: 3px;
      }
    }

    .eqInput {
      width: 100%;
    }
  }

  .eq-green-header {
    background-color: $lightGreenBgClr;
  }

  .eq-sel-date {
    &.ant-col {
      padding-right: 5px;
    }
  }

  .ant-select-selection-overflow-item {
    .ant-tag-has-color {
      color: $darkBrownText;
    }
  }

  .eq-btn-icon {
    position: absolute;
    bottom: 0;
    right: -50px;
    display: flex;
    z-index: 9;
    overflow: visible;
    transition: all ease-out 0.3s;
    border-radius: 50%;
    background-color: #e5e5e5;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media (max-width: $sm) {
      bottom: 63px;
      right: -16px;
    }

    img {
      text-align: center;
      display: flex;
      justify-content: center;
      align-content: center;
      cursor: pointer;
      height: 20px;
      width: 20px;
      padding: 5px;

      @media (max-width: $sm) {
        height: 22px;
        width: 22px;
        // padding: 6.5px;
      }
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  @media (max-width: $sm) {
    // margin-bottom: 45px;
  }
  /*    .tab-container {
        padding: 0px 16px 16px 16px;
    }*/
}
