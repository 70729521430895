@import '../../shared/Vars.scss';

// .input-row {
//     margin-bottom: 16px;
// }

// .ant-modal-content {
//     border-radius: $borderRadiusBase;
// }

// .ant-modal-header {
//     border-radius: $borderRadiusBase;
// }

// .eqInput {
//     padding: 0px;
//     margin: 0px;


//     .ant-input {
//         line-height: 1.75;
//         padding-right: 6px !important;
//         background: #FFFFFF;
//         border: 1px solid #D9D9D9;
//         box-sizing: border-box;
//         border-radius: $inputBorderRadius;
//         padding-left: 16px;
//     }

//     .eq-label {
//         @include Inter-14-regular ;

//         color: $darkBrownText;
//         margin-bottom: 0.1rem;
//     }
// }


.eq-book-session {
    .error-message {
        color: $red;
    }

    .eq-book-session-header {
    }

    eq-book-session-header-divider {
        background-color: $orange;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .divider {
        margin: 10px 0px !important;
        border-bottom: 1px solid #808080 !important;
    }

    .eq-book-session-body {
        .eq-section-title {
            margin: 0;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            word-wrap: break-word;
        }

        .eq-input-group {
            margin-bottom: 9px;

            .input-label {
                @include Inter-14-regular;
                color: $darkBrownText;
                margin-bottom: 0.1rem;
                display: block;
            }

            .name-input {
                border-radius: $inputBorderRadius;
                padding-left: 16px;
            }
        }

        .section-title {
            @include Inter-14-regular;
            font-weight: 300;
            color: $darkBrownBar;
        }


        .eq-is-textarea {
            margin-top: 10px;
            padding: 16px;
            min-height: 142px;
            border: 1px solid #D9D9D9;
            box-sizing: border-box;
            border-radius: $inputBorderRadius;
        }
    }

    .eq-book-session-button {
        width: 100%;
        float: right !important;
    }

    .eq-pl-discipline-list {
        margin: 15px 5px 10px 0px;
        padding: 2px 12px;
        border: 1px solid $disciplineBorder;
        border-radius: $tagBorderRadius;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: $blackMenu;

        @media (max-width:$sm) {
            margin: 5px 5px 5px 0px;
        }

        .eq-pl-discipline-list-active {
            color: $blueLink;
        }

        .tag-desc {
            font-size: 11px;
            line-height: 11px;
            display: block;
            color: $darkBrownText;
            font-weight: 400;
        }
    }
}