.eq-logs {
    padding: 16px;
    background-color: #f9f9f9; // Light background for the page
    border-radius: 8px;
    overflow: auto;
    .logs-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 8px;
      background: #fff; // White background for the header
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for elevation
  
      .logs-controls {
        display: flex;
        align-items: center;
        gap: 12px; // Space between date picker and button
      }
  
      .logs-search {
        width: 300px;
        border-radius: 8px; // Smooth edges
      }
    }
  
    .ant-table {
      background-color: #fff;
      border-radius: 8px;
  
      .ant-table-thead > tr > th {
        background: #fafafa;
        font-weight: 600;
        text-align: left;
      }
  
      .ant-table-tbody > tr:hover {
        background-color: #f5f7fa; // Light hover effect for rows
      }
    }
  }
  