@import '../../shared/Vars.scss';
.eq-card {
    max-width: 380px;
    height: 100%;

    .skeleton-type{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .event-img {
        object-fit: cover;
        border-radius: 12px;
        max-width: 100%;
        width: 400px;
        height: 200px;
        object-fit: cover;

    }

    .event-detail {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .event-type {
            color: #FF8838;
        }

        .event-price {

            font-size: 12px;
            line-height: 12px;

            text-align: center;
            border-radius: 50px;
            background: #FF97512B;
            padding: 6px 15px;
        }

        .event-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;

        }

        .icon {
            transform: rotate(45deg);
            padding-right: 15px;
            scale: 1.5;
            color: #667085;
        }

        .event-trainer {
            .trainer {
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                margin-right: 2px;
                font-weight: 900;
            }
            .trainer-name {
        color: #667085;
                
            }
        }

        .event-student {
            .students {
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                margin-right: 2px;
                font-weight: 900;
            }
            .no-students {
        color: #667085;

            }
        }


    }

    .event-description {
        // font-size: 14px;
        @include Inter-16-regular;
        height: 63px;
        // font-weight: 400;
        // line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #667085;

        .description-text {
            
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 15px;
          }

    }

    .event-time {
        color: #667085; 
        @include Inter-14-regular;
        // font-size: 12px;
            // line-height: 12px;

            .time{
                @include poppins-14-regular;
                margin-left: 4px;
                // font-size: 12px;
            }
    }
    :hover {
        .icon {
            
            color: #FF8838;
        }
    }


}