@import '../../shared//Vars.scss';
.eq-message {
    .eq-message-container {


        .eq-message-item{

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .eq-message-read {
            @include Inter-14-regular;
            padding: 17px 16px;
            color: $darkBlack;
            border-bottom: 2px solid $rowBottomBorder;

            @include responsive(xs) {
                font-size: 10px;
                padding: 12px 12px;
                .eq-message-item-type {
                    font-weight: 700;
                }
            }

     

            .eq-message-item-action {
                cursor: pointer;

                .eq-message-action {
                    padding-left: 5px;

                    img {
                        transition: background 0.2s, color 0.2s;

                        &:hover {
                            transform: scale(1.2);
                            box-shadow: 0.1em 0.1em 1.2em #bbb;
                        }
                    }

                    .eq-message-action-reply {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-flag {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-delete {
                        flex: 1;
                        max-width: 32px;
                    }
                }
            }
        }

        .eq-message-unread {
            @include Inter-14-regular;
            padding: 17px 16px;
            font-weight: bold;
            color: $darkBlack;
            border-bottom: 2px solid $rowBottomBorder;

            @include responsive(xs) {
                font-size: 10px;
                padding: 12px 12px;
                .eq-message-item-type {
                    font-weight: 700;
                }
            }

            .eq-message-item-action {
                cursor: pointer;

                .eq-message-action {
                    // display: flex;
                    // flex-direction: row;
                    padding-left: 5px;

                    img {
                        transition: background 0.2s, color 0.2s;

                        &:hover {
                            transform: scale(1.2);
                            box-shadow: 0.1em 0.1em 1.2em #bbb;
                        }
                    }

                    .eq-message-action-reply {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-flag {
                        flex: 1;
                        max-width: 32px;
                    }

                    .eq-message-action-delete {
                        flex: 1;
                        max-width: 32px;
                    }
                }
            }
        }
    }
}
