.student-checkout{
    
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999; /* Ensures the overlay is on top */
    }
    
    .overlay * {
        pointer-events: none; /* Blocks interaction with all elements inside the overlay */
    }
    
    .overlay a, .overlay button {
        pointer-events: auto; /* Enables interaction with buttons and links */
    }
}