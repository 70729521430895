@import "../../shared/Vars.scss";
.eq-training-board {
  background-color: $whiteColor;
  /* padding: 10px 35px 35px 35px;

    @media (max-width:$sm) {
        padding: 10px;
    }*/

  .ant-row {
    display: flex;
    @include responsive(lg) {
    //   flex-wrap: nowrap;
    }
  }

  .eq-event-heading-row {
    padding: 4px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    border-bottom: 1px solid $orangeBorder;

    .eq-event-heading-col {
      padding: 0px;
      margin: 0px;

      .eq-event-heading {
        @include poppins-24-regular;
        padding: 0px 0px 0px 0px;
        color: $makoColor;

        @media (max-width: $sm) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    .eq-event-buttons {
      margin: 0px 0px 0px 5px;

      @media (max-width: $sm) {
        margin: 0px 0px 5px 5px;
      }
    }
  }

  .eq-event-header-icon {
    cursor: pointer;

    img {
      padding: 5px 0px 5px 10px;
    }
  }

  .filters {
    background-color: #FAFAFA;
    padding: 16px;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 25px;

    .picker-label {
      @include Inter-14-regular-btn;
      font-weight: 500;
    }

    .filter-type {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .eq-input-info {
        width: 100%;
      }

      .eq-filter-radius {
        border-radius: 5px;

        .ant-select-selector {
          border-radius: 5px;
        }
      }

      .search-col {
        margin: auto;
      }
    }

  }
}
.customCollapse {
  overflow: hidden;
  transition: 0.4s max-height;
}

.customCollapse > div {
  overflow: auto;
}

.sample-content {
  background: #ccc;
  padding: 10px;

  h1,
  p {
    margin: 0;
    text-align: center;
  }
}

.collapseHeader {
  padding: 10px;
  display: block;
  background: #333;
  color: white;
  width: 100%;
  border: 0;
  cursor: pointer;
}
