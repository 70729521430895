@import '../shared/Vars.scss';
.eq-features {
    margin: 0 auto;
    .page-banner {
        @include responsive(sm) {
            max-height: 100%;
        }
        padding: 0;
        margin-top: 32px;
        z-index: 99;
        opacity: 1;
        text-shadow: 0 0 0px #000;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        @media (max-width: 450px) {
            margin-top: 16px;
        }

        .hero-image-banner {
            margin-top: -20px;
            width: 100%;
            height: 100%;
            @media (min-width: 1320px) {
                // width: 100%;
            }
        }


        .overlay {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: center;
            flex-direction: column;
            // padding-bottom: 60px;
            .feature-banner-text {
                @include poppins;
                font-weight: 400;
                font-style: normal;
                color: #000;
                // font-size: 64px;
                text-align: center;
                // text-transform: capitalize;
                .text {
                    padding-top: 10px;
                    font-size: 18px
                }
                .heading {
                    font-size: 52px;
                    font-weight: 800;
                    .gradient {
                        background: linear-gradient(317deg, rgba(225, 255, 57, 1) 0%, rgba(255, 246, 27, 1) 25%, rgba(255, 136, 57, 1) 50%, rgba(250, 255, 13, 1) 75%, rgba(255, 136, 56, 1) 100%);
                        position: absolute;
                        width: 10%;
                        height: 7%;
                        left: 43%;
                        top: 16%;
                        filter: blur(60px);
                        z-index: -1;
                    }
                }
                @media (max-width: 977px) {
                    .text {
                        font-size: 14px
                    }
                    .heading {
                        font-size: 30px;
                    }
                }
                @media (max-width: 760px) {
                    .text {
                        font-size: 12px
                    }
                    .heading {
                        font-size: 20px;
                    }
                }
                @media (max-width: 375px) {
                    .text {
                        font-size: 10px
                    }
                    .heading {
                        font-size: 14px;
                    }
                }
            }
            .create-profile-btn {
                .eqButton {
                    text-align: center;
                    .ant-btn {
                        font-family: Poppins;
                        // color: #FFF;
                        height: auto;
                        text-align: center;
                        padding: 8px 80px;
                        font-size: 24px;
                        font-weight: 600;
                        // text-transform: capitalize;
                        border-radius: 30px;
                    }
                }
                @media (max-width: 977px) {
                    scale: 0.8;
                }
                @media (max-width: 760px) {
                    scale: 0.5;
                }
            }
            @media (max-width: 375px) {
                display: inline;
            }
        }
    }
    .features-section {
        background: white;
        .feature-sub-section {
            width: 80%;
            margin: auto;
            padding: 7px 40px;
            @media (max-width: 768px) {
                padding: 7px 0;
            }
            @media (max-width: 400px) {
                width: 100%;
            }
            .feature-img-section {
                position: relative;
                height: 100%;
                .feature-img {
                    position: absolute;
                    @media (max-width: 768px) {
                        scale: 0.8;
                    }
                    @media (max-width: 450px) {
                        scale: 0.6;
                    }
                    @media (max-width: 340px) {
                        scale: 0.5;
                        // top: 0 !important;
                    }
                }
                .feature-img-1 {
                    top: 10px;
                    left: 11px;
                    @media (max-width: 450px) {
                        top: 0;
                        left: 1%;
                    }
                }
                .feature-img-2 {
                    top: 10px;
                    left: 145px;
                    @media (max-width: 450px) {
                        top: 0;
                        left: 31%;
                    }
                }

                .feature-img-3 {
                    top: 87px;
                    left: 72px;
                    @media (max-width: 450px) {
                        top: 35px;
                        left: 17%;
                    }
                }
                .feature-img-4 {
                    top: 71px;
                    right: 113px;
                    @media (max-width: 450px) {
                        top: 35px;
                        right: 31%;
                    }
                }
                .feature-img-5 {
                    top: 9px;
                    right: 41px;
                    @media (max-width: 450px) {
                        top: 0px;
                        right: 17%
                    }
                }
                .feature-img-6 {
                    top: 96px;
                    right: 7px;
                    @media (max-width: 450px) {
                        top: 35px;
                        right: 2%;
                    }
                }
            }

            .basic-feature {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 90px;
                flex-direction: column;
                .heading {
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 78px;
                    // text-transform: capitalize;
                }
                .feature-row {
                    margin: 40px auto;
                    display: flex;
                    justify-content: center;
                    .feature-col {
                        display: flex;
                        justify-content: center;
                        .basic-content {
                            background: #F8F7F5;
                            border: 1px solid #DFDFDF;
                            max-width: 380px;
                            height: 100%;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: space-evenly;
                            .basic-icon {
                                font-size: 75px;
                            }
                            .heading {
                                margin: 12px 0;
                                color: #000;
                                font-size: 28px;
                                font-weight: 600;
                                line-height: 38px;
                            }
                            p {
                                font-size: 15px;
                                text-align: justify;
                            }
                        }
                        .basic-content:hover {
                            scale: 1.1;
                            z-index: 10000;
                            background: white;
                            transition: all ease-out 0.5s;
                            box-shadow: 0px 0px 105px -65px rgba(0, 0, 0, 1);

                            .basic-icon {
                                color: $orangeBorder;
                            }
                        }
                    }
                }
            }
        }

    }
    .offer-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 100px;
        flex-direction: column;
        .heading {
            font-size: 40px;
            font-weight: 700;
            line-height: 78px;
            // text-transform: capitalize;
        }
        .register-block {
            width: 80%;
            @media (max-width: 740px) {
                margin-top: 50px;
            }


            .container {
                // width: 73%;
                margin-right: auto;
                margin-left: auto;
                @media (max-width: $sm) {
                    width: 100%;
                    padding-right: 12px;
                    padding-left: 12px;
                }

                .more {
                    @include poppins-14-regular;
                    color: $blueLink;
                    margin-bottom: 18px;
                    display: inline-block;
                }
                .rider {
                    margin-top: 100px;
                    display: flex;
                    justify-content: space-around;
                    @media (max-width: 425px) {
                        border-bottom: 1px solid #fecba896;
                    }


                    .rider-image {
                        width: 100%;
                        background: #F4D0B8;
                        flex-direction: row-reverse;
                        .image-block {
                            background: transparent;
                            .image {
                                background: transparent;
                            }
                        }
                    }

                    .rider-register {
                        @media (max-width: 740px) {
                            margin-top: 50px;
                        }
                        .rider-desc {
                            .heading {
                                @include Inter-18-semibold;
                                font-weight: 700;
                                margin-bottom: 18px;
                            }
                            .explanation {
                                @include poppins-14-regular;
                                color: $darkBrownText;
                                margin-bottom: 18px;
                                p {
                                    font-size: 15px;
                                    line-height: 1.7;
                                    text-align: justify;
                                    @media (max-width: 425px) {
                                        font-size: 14px;
                                    }
                                }
                                .text1 {
                                    margin-bottom: 18px
                                }
                            }
                            .rigester-points {
                                ul {
                                    li {
                                        @include poppins-14-regular;
                                        display: flex;
                                        align-items: flex-start;
                                        line-height: 30px;
                                        font-size: 15px;
                                        @media (max-width: 425px) {
                                            font-size: 14px;
                                        }


                                        .icon {
                                            color: rgb(255, 136, 56);
                                            margin-right: 7px;
                                        }

                                    }
                                    @media (max-width: 580px) {
                                        padding-left: 0;
                                    }
                                }
                            }
                        }
                        .ant-btn {
                            margin-left: 40px;
                            margin-top: 30px;
                        }

                    }
                }
                .event-holder {
                    @media (max-width: 425px) {
                        margin-top: 60px;
                    }
                    .event-holder-image {
                        .image-block {
                            margin-top: -85px;
                            margin-bottom: 20px;
                            background: transparent;
                            .image {
                                display: flex;
                                margin: auto;
                                height: auto;
                                max-width: 300px;
                                background: transparent;
                                @media (max-width: 768px) {
                                    max-width: 250px;
                                }
                                @media (max-width: 425px) {
                                    max-width: 160px;
                                }

                            }
                        }
                    }
                }

                .riding-schools {
                    @media (max-width: 768px) {
                        .rider-register {
                            order: 2;
                        }
                    }
                    .riding-school-image {
                        display: flex;
                        align-items: flex-end;
                        flex-wrap: nowrap;
                        @media (max-width: 1024px) {
                            flex-direction: column;
                            align-items: center;
                        }
                        @media (max-width: 800px) {
                            flex-direction: column;
                            align-items: center;
                        }
                        @media (max-width: 425px) {
                            margin-top: 60px;
                        }
                        .image-block-1 {
                            margin-top: 20px;
                            margin-bottom: 100px;
                            .image {
                                @media (max-width: 668px) {
                                    max-width: 250px;
                                }
                                @media (max-width: 375px) {
                                    max-width: 160px;
                                }
                            }
                            @media (max-width: 1024px) {
                                margin-top: -80px;
                                margin-bottom: 20px;
                            }
                        }
                        .image-block-2 {
                            .image {
                                max-width: 350px;
                                @media (max-width: 1024px) {
                                    max-width: 400px;
                                }
                                @media (max-width: 6768px) {
                                    margin-top: 80px;
                                }
                                @media (max-width: 668px) {
                                    max-width: 300px;
                                }


                            }
                        }
                    }
                }
                .trainer {
                    display: flex;
                    align-items: center;
                    @media (max-width: 425px) {
                        margin-top: 100px;
                    }
                    .trainer-image {
                        height: 600px;

                        @media (max-width: 768px) {
                            height: 450px;
                        }
                        @media (max-width: 425px) {
                            height: 300px;
                            margin-bottom: 10px;
                        }
                        .image-block {
                            margin-top: -45px;
                            margin-bottom: 20px;
                            // background: #F4D0B8;
                            .image {
                                display: flex;
                                margin: auto;
                                height: auto;
                                max-width: 350px;
                                background: transparent;
                                @media (max-width: 1024px) {
                                    max-width: 300px;
                                }
                                @media (max-width: 768px) {
                                    max-width: 230px;
                                }
                                @media (max-width: 425px) {
                                    max-width: 200px;
                                }

                            }
                        }
                    }
                    .rider-register {
                        @media (max-width: 740px) {
                            margin-top: 70px;
                        }
                    }
                }
                .student {

                    @media (max-width: 768px) {
                        .rider-register {
                            order: 2;
                        }
                    }
                    @media (max-width: 425px) {
                        margin-top: 60px;
                    }
                    .student-image {
                        .image-block {
                            margin-top: 20px;
                            margin-bottom: -50px !important;
                            margin-bottom: 20px;
                            background: transparent;
                            .image {
                                display: flex;
                                margin: auto;
                                height: auto;
                                max-width: 250px;
                                background: transparent;
                                @media (max-width: 768px) {
                                    max-width: 200px;
                                }
                                @media (max-width: 425px) {
                                    max-width: 160px;
                                }

                            }
                        }
                    }
                    .rider-register {
                        @media (max-width: 740px) {
                            margin-top: 75px;
                        }
                    }
                }
            }
        }
    }
    .reviews {
        margin-top: 20px;
        margin-bottom: 100px;
        background: #F8F7F5;
        .heading {
            font-size: 40px;
            font-weight: 700;
            line-height: 78px;
            // text-transform: capitalize;
            text-align: center;
        }
    }
}