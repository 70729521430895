
    
    .ant-row {
        
        .parent-sb {
            display: inline-block;
            margin-top: 20px;
                .eq-sb-parent{
                display: flex;
                justify-content:space-between;
                // width: 80vw;

                
                    .heading{
                        margin-right: 50px;
                    }
                    .btn-info-parent {
                        
                        margin: 0;
                        padding: 10;
                    }
                }

                .parent-btn{
                    margin: 1.5px;
                }

                .parents-list {
                    .parent-row {
                     display: flex;
                     justify-content: space-between;
                     border-bottom: 1px solid #f0f0f0;
                     padding: 12px 0;
                     .parent-info {
                     margin-left: 0;
                     text-align: left;
                     justify-content: space-around;
                     }
                    }
                     
                 }
 
                 .parent-email, .parent-number{
                     
                     padding: 5px 5px;
                         
                 }
            
        }
}


