@import "../../../shared/Vars.scss";

.custom-card {
    border-left: 5px solid #FF8838;
    min-width: 300px;
    .ant-card-body {
      padding: 11px 18px;
    }
    .card-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h3 {
        margin: 0;
        font-size: 1.25rem;
      }
    
      div{
        margin: 2px 0px 10px;
        @include poppins-12-semibold;
        color: $lightTagGrey;
      }
      p {
        margin: 0;
        // @include poppins-12-semibold;
        // color: $lightTagGrey;

      }
      button {
        align-self: flex-start;
      }
    }
  }
  