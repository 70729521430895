@import '../../shared/Vars.scss';
.eq-autocomplete {
    .eq-search-input {
        width: 100%;
        border-radius: $inputBorderRadius;
        line-height: 2.2715;
        padding-left: 6px !important;
        border: 1px solid $borderColor;
        margin-bottom: 10px;

        &input {
            border: none;

            :hover {
                border: 1px solid $borderColor;
            }

            :focus {
                border: 1px solid $borderColor;
            }
        }
    }

    .eq-search-results {
        margin: 0px;
        margin: -10px 0px 0px 0px;
        width: 100%;
        position: absolute;
        z-index: 1;
        background-color: $whiteBackground;
        border-radius: $inputBorderRadius;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        ul {
            list-style-type: none;

            li {
                margin-left: -25px;
            }
        }
    }
}
