@import "../../shared/Vars.scss";

.eq-view-event {
  margin: 0px;
  padding: 0px;
  @include poppins;
  background-color: #f0f2f5;

  .ant-row {
    display: flex;
  }

  @media (max-width: $sm) {
    padding: 0px;
  }

  .container {
    width: 73%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    padding-left: 12px;
    padding-right: 12px;

    @media (max-width: $sm) {
      width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }

    .event-decs {
      background-color: #F8F8F9CC;
      margin-top: 10px;
      margin-bottom: 15px;

      .section {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .section {
    margin: 20px 0px 10px 0px;
  }

  .section-heading {
    // flex:auto;
    @include poppins-16-semibold;
    
    color: #000000;
    margin-bottom: 5px;
    border-bottom: 1px dashed #c4c4c4;
  }

  .section-label {
    @include Inter-14-regular
  }



  .question {
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;

    .q-heading {
      font-weight: 600;
      margin-bottom: 6px;
      background-color: #F0F2F5;
    }




  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #ff9751;
      box-shadow: 0 0 0 3px #ffede1
    }

    .ant-radio-inner::after {
      background-color: #ff9751;
    }

  }
}



.student-joined-event-class {
  // border: 1px solid $orangeBorder;
  border-radius: 5px;



  .heading {
    // border-bottom: 1px solid $blackColor;
    border-bottom: 1px solid #c4c4c4;
    padding: 0 5px;
    margin-bottom: 5px;
    // background-color: $orangeBorder;
    @include poppins-16-semibold;
    
    color: #000000;


  }

  .tb-heading {
    @include Inter-14-semibold;
    border-bottom: 1px solid #d9d9d9;
    padding: 0 5px;
    background-color: #F0F2F5;


    @media(max-width:$sm) {
      font-size: 14px;

    }

  }

  .tr-odd {
    @include Inter-14-regular;
    border-bottom: 1px solid #d9d9d9;
    padding: 5px 5px;

  }

  .tr-even {
    @include Inter-14-regular;

    border-bottom: 1px solid #d9d9d9;
    padding: 5px 5px;

  }

  .table-col {
    @media(max-width:$sm) {
      font-size: 14px;
      line-height: 23px;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .qa-Col {
    border: 1px solid #d9d9d9;


  }
}

.event-top {
  background-color: $whiteBackground;
  position: relative;
  padding: 20px 35px 12px 35px;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(rgba(205, 202, 197, 0.65),
      rgba(205, 202, 197, 0));

  @media (max-width: $sm) {
    padding: 0px 10px 10px 10px;
  }

  .event-img {
    object-fit: cover;
    border-radius: 0px 0px 12px 12px;
    overflow-y: hidden;
    overflow-x: hidden;
    max-width: 665px;
    width: 100%;
    // margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;

    @media (max-width: $sm) {
      /*margin-left: -10px;
                margin-right: -10px;*/
    }

    .eq-img {
      object-fit: cover;
      width: calc(100% + 20px);
      height: 400px;
    }
  }

  .important-info {
    // padding-top: 24px;

    position: relative;

    .calender-icon {
      position: absolute;
      left: 0%;
      top: -70px;
      height: 80px;
      border-radius: 12px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);

      .icon-header {
        background-color: #f3425f;
        height: 20px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      .icon-body {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        height: 60px;
        padding: 0px 24px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .day {
          @include Inter;
          font-weight: 600;
          font-size: 30px;
          line-height: 30px;
          color: rgb(5, 5, 5);
        }

        .month {
          @include Inter;
          text-overflow: ellipsis;
          color: #65676b;
          font-weight: 400;
          font-size: 1.0625rem;
        }
      }
    }

    .date {
      @include Inter;
      color: rgb(240, 40, 74);
      font-size: 15px;
      font-weight: 700;
    }

    .title {
      @include poppins-32-bold;
      color: rgb(5, 5, 5);
      // font-size: 28px;
      // font-weight: 700;
    }

    .area {
      @include Inter;
      text-overflow: ellipsis;
      color: #65676b;
      font-weight: 400;
      font-size: 1.0625rem;
      padding: 3px 0px 12px;
    }

    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: rgba(28, 30, 33, 0.2);
    }
  }

  .actions {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
  }
}

.event-middle {
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 12px;

  @media (max-width: $sm) {
    margin: 0px;
    margin-top: 12px;
    width: 100%;
  }

  .event-middle-details {
    .detail-item {
      display: flex;
      margin-bottom: 16px;

      &::last-child {
        margin-bottom: 0px;
      }

      .eq-icon {
        padding-right: 12px;
        font-size: 20px;
        font-weight: 600;
        color: #6e6e6e;
      }

      .eq-value {
        color: rgb(5, 5, 5);
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;

        .eq-value-key {
          margin-right: 3px;
        }

        .organizer {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }

  .label {
    margin-right: 3px;
    font-weight: 700;
  }

  .eq-view-card {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 9px;
    padding: 16px;
    margin-bottom: 12px;

    .section-title {
      color: rgb(5, 5, 5);
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &::last-child {
      margin-bottom: 0px;
    }
  }

  .eq-pl-discipline-list {
    margin: 15px 5px 10px 0px;
    padding: 2px 12px;
    border: 1px solid $disciplineBorder;
    border-radius: $tagBorderRadius;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: $blackMenu;

    @media (max-width: $sm) {
      margin: 5px 5px 5px 0px;
    }

    .eq-pl-discipline-list-active {
      color: $blueLink;
    }

    .tag-desc {
      font-size: 11px;
      line-height: 11px;
      display: block;
      color: $darkBrownText;
      font-weight: 400;
    }
  }
}

.partners {
  /*background: url('/images/about/about-bg.svg');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.969);
    padding: 48px 24px;

    @media (max-width: $sm) {
      padding: 0px;
    }
  }
}

.create-title {
  padding-bottom: 9px;
  border-bottom: 1px solid $navTxtColor;

  .title-text {

    font-weight: 300;
    color: $darkBrownBar;
  }

  .action-group {
    display: flex;

    >div {
      :first-child {
        margin-right: 6px;
      }
    }

    @media (max-width: $sm) {
      justify-content: space-between;
      margin: 5px 0px 0px 0px;
    }
  }
}

.event-info-input {
  .ant-col {
    margin: 0px;
    padding: 0px;
  }

  .event-type {}

  .label {
    margin-top: 25px;
    @include poppins-16-semibold;
    color: $darkBlackZero;
    margin-bottom: 16px;
    
  }

  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 15px;
  }

  .eq-input-group {
    margin-bottom: 9px;

    .input-label {
      @include Inter-14-regular;
      color: $darkBrownText;
      margin-bottom: 0.1rem;
      display: block;
    }

    .name-input {
      border-radius: $inputBorderRadius;
      padding-left: 16px;
    }
  }

  /*.event-date-time {
            @media(min-width:$sm) {
                padding-right: 30px;
            }*/

  .col-1 {
    @media (min-width: $sm) {
      padding-right: 25px;
    }
  }

  .col-2 {
    @media (min-width: $sm) {
      padding-left: 25px;
    }

    .total-price {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #626262;
    }

    .total-price-value {
      text-align: right;
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      align-items: center;
      text-indent: 20px;
      text-transform: capitalize;
      color: #000000;
      opacity: 0.4;

      .value {
        font-weight: normal;
      }
    }

    .divider {
      margin: 20px 0px;
      border-bottom: 1px solid #c4c4c4;
    }

    .paid-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .event-payment {
    @media (min-width: $sm) {
      padding-left: 50px;
    }

    .eq-radio-group {
      &.ant-radio-group {
        width: 100%;
      }
    }
  }

  .eq-is-input-heading {
    @include Inter-14-regular;
    // font-weight: normal;
    // font-size: 14px;
    // line-height: 163.19%;
    margin: 10px 0px 10px 0px;
    color: $darkBrownText;
    padding-left: 5px;
  }

  .eq-is-textarea {
    margin-top: 10px;
    padding: 16px;
    min-height: 142px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: $inputBorderRadius;
  }

  .eq-is-textarea:focus {
    box-shadow: none;
  }

  .repeat-time {
    display: flex;
    align-content: center;

    button {
      align-self: center;
      margin-right: 6px;
    }
  }

  .eq-cg-members-header {
    padding: 10px 0px 0px 0px;
    margin: 0;

    @media (max-width: $sm) {
      .ant-row-end {
        justify-content: center;
      }
    }

    .eq-cg-members-heading {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      
      color: $blackColor;

      @media (max-width: $sm) {
        text-align: center;
      }
    }

    .eq-cg-right {
      justify-content: space-between;

      .eq-cg-sort {}

      @media (max-width: $sm) {
        justify-content: space-between;

        .eq-cg-sort {
          margin-left: 0px;
          padding-left: 0px;

          .ant-select-selector {
            margin-left: 0px;
            padding-left: 0px;

            .ant-select-selection-item {
              margin-left: 0px;
              padding-left: 0px;
            }
          }
        }
      }

      .eq-cg-search {}

      .eq-cg-add {
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .eq-cg-search-header {
    padding: 0px 0px 10px 0px;
  }

  .eq-attendees-list-container {
    margin: 0%;
    padding: 0%;

    .eq-list-header {
      background-color: #fafafa;
    }
  }

  .eq-cg-btn {
    margin: 20px 0px 5px 0px;

    .eq-cg-buttons {
      margin-left: 8px;
    }
  }
}