
@import '../../shared/Vars.scss';

.eq-pbo-container{
    margin-top: 2rem;

    .eq-pbo-h-row {
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        @include Inter-14-500;
        border-bottom: 2px solid $rowBottomBorder;
        margin: 0px;
        // display: flex;
        // justify-content: space-between;
        
    
        .eq-th-checkbox{
            padding:0px 20px 0px 0px;
        } 
        .eq-sl-th {
            
            padding: 0px;
            margin: 0px;
            text-align: left;
        }

        .eq-sl-th-status{
            // flex: inherit;
        }
    }



    .eq-pbo-row {
        @include Inter-14-regular;
        padding: 17px 16px;
        color: $darkBlack;
        border-bottom: 2px solid $rowBottomBorder;
        margin: 0px;
        display: flex;
        // justify-content: space-between;
        // .eq-s-groups {
        //     overflow-x: auto;
        //     overflow-y: hidden;
        //     // white-space: nowrap;
        // }
        .eq-sl-th {
            padding: 0px;
            margin: 0px;
            text-align: left;
        }
        .eq-sl-th-status{
            // flex: inherit;
        }
    }
}