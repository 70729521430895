@import "../../../src/components/shared/Vars.scss";

.eq-image-selector {
    .ant-row {
        margin-bottom: 20px;
    }

    .ant-btn {
        height: 100px !important;
        width: 100px !important;
        padding: 0 !important;
        border: 2px dashed !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: none;
        border: none;
        cursor: pointer;
        // font-size: 24px;

        &:hover {
            background-color: #f0f0f0;
        }

        .icon {
            font-size: 24px;
        }
    }

    .selected-image {
        max-height: 150px;
        margin-left: 20px;
        min-height: 150px;
        min-width: 200px;
        object-fit: cover;
        margin-top: 18px;
        border-radius: 8px;
    }

    .selector-3d {
        position: relative;
        width: 200px !important;
        height: 150px !important;
        perspective: 1000px !important;
        margin: 20px;
        transform: rotateX(0deg) rotateY(0deg);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f0f0f0;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3) !important;
        border-radius: 10px;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            border: 2px dashed #333;
            border-radius: 10px;
            /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);*/
            transform: rotateX(20deg) translateZ(20px);
            pointer-events: none;
        }
    }
}

.eq-image-selector-modal {
    .ant-modal-close-x {
        justify-content: normal;
    }

    .ant-modal-close {
        top: 15px;
        inset-inline-end: 5px;
    }

    .upload-library {
        text-align: center;
    }

    .upload-to-library-button {
        background-color: #1890ff;
        border-color: #1890ff;
        color: #fff;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .image-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;

        .image-item {
            position: relative; // Required for positioning the delete icon

            border: 1px solid #ccc;
            cursor: pointer;
            display: inline-block;

            &.selected {
                img {
                    border: 3px solid $orangeColor;
                }
            }

            img {
                width: 100px;
                height: 100px;
                // display: block;
                object-fit: cover;
            }

            .delete-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                background-color: rgba(0, 0, 0, 0.5);
                color: white;
                border-radius: 50%;
                font-size: 16px;
                padding: 4px;
                opacity: 0; // Initially hidden

                &:hover {
                    background-color: rgba(255, 0, 0, 0.8); // Change color on hover
                }
            }

            &:hover .delete-icon {
                opacity: 1; // Show the icon on hover
            }
        }
    }

    .ant-modal-footer {
        text-align: center;
    }
}
