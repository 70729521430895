@import "../../shared/Vars.scss";

.eq-HorseTask-container {
    background-color: white;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        padding: 10px;
    }

    .eq-HorseTask-row {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 20px 0px;
        border-bottom: 1px solid $orangeBorder;
        display: flex;
        justify-content: space-between;

        .eq-HorseTask-col {
            .eq-HorseTask-heading {
                margin-bottom: 10px;
                padding: 0px;
                font-family: IBM Plex Serif;
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 31px;
                color: $makoColor;

                @media (max-width:$sm) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }

    .task-details {
        margin: 20px auto 10px 10px;

        .task-options {
            margin-bottom: 20px;

            .options-heading {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                text-transform: capitalize;
                margin-bottom: 10px;
            }

            .options {
                margin-top: 10px;

                .options_1 {
                    margin-right: 40px;
                }
            }
        }

        .horse-taskList {
            width: 100%;
            margin: auto;
        }


        .eq-add-horse-btn {
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .btn-background-white {
                :hover {
                    color: white;
                }
            }
        }
    }

    .eqInput {
        .ant-input {
            height: 40px;
        }
    }

    .eq-fl-row {
        margin-top: 22px;
        background: $grayHeading;
        padding: 17px 16px;
        color: $darkBlack;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        border-bottom: 2px solid $graylightborder;

        .eq-fl-th {
            padding: 0px;
        }
    }

    .eq-list-checkAll {
        padding: 17px 16px;
        color: $darkBlack;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }

    position: relative;

    .add-task {
        display: block;
        position: absolute;
        top: -48px;
        right: 15px;
    }
}
.horse-management {

    .ant-tabs {
        .ant-tabs-nav-list {

            .ant-tabs-tab-btn {
                @include poppins-14-regular-btn;
                transition: color 0.3s;

                &:hover {
                    color: #FF8838;
                }
            }

            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: #FF8838;
                }
            }

            .ant-tabs-ink-bar {
                background: #FF8838;
            }
        }
    }
}

.filters {
    background-color: #F1EDEC;
    margin-top: 20px;
    padding: 16px;
    padding-left: 30px;
    border-radius: 12px;

    .picker-label {
        font-weight: 500;
        @include Inter-14-regular-btn;
    }

    .filter-type {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .eq-input-info {
            width: 100%;
        }

        .eq-filter-radius {
            border-radius: 5px;

            .ant-select-selector {
                border-radius: 5px;
            }
        }
    }
}