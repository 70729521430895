@import '../../shared/Vars.scss';

.eq-p-row {
    border-bottom: 1px dashed $dashedBorder;
    padding: 0px 10px 5px 10px;
    margin-bottom: 15px;
    @include poppins;
    font-style: normal;

    .ant-row {
        display: flex;
    }

    @media (max-width:$sm) {
        border: none;
        margin: 0px;
        padding: 0px;

        .eq-pl-img img {
            height: 32px;
            width: 32px;
        }

        .eq-pl-msg {
            margin: 0px;
        }
    }

    .eq-pl-info {
        padding: 0px 10px 20px 20px;

        @media (max-width:$sm) {
            margin: 0px;
            padding: 17px 16px;
        }

        .eq-pl-img {
            width: 61.54px;
            height: 64px;
            border-radius: 15px;
        }

        .eq-at-name {
            @include Inter-14-regular;
            font-style: normal;
            // font-weight: 500;
            // font-size: 14px;
            text-transform: capitalize;
            color: $blackColor;
            margin-bottom: 5px;

            @media (max-width:$sm) {
                text-transform: capitalize;
                font-size: 16px;
            }
        }

        .eq-pl-address {
            padding: 2px 0px 0px 10px;
            font-weight: normal;
            font-size: 14px;
            text-transform: capitalize;
            color: #000000;

            .eq-pl-address-icon {
                @media (max-width:$sm) {
                    margin-right: 10px;
                }
            }

            @media (max-width:$sm) {
                margin: 0px 0px 15px 0px;
                padding: 0px;
            }
        }

        .eq-at-discipline {
            margin: 0px 0px 0px 0px;
            padding: 2px 10px 0px 0px;
            font-weight: 600;
            font-size: 13px;
            
            color: $blackColor;

            @media (max-width:$sm) {
                margin: 0px;
                margin: 0px 0px 5px 0px;
            }
        }

        .eq-at-seperator {
            margin: 15px 0px 0px 0px;
            padding: 2px 10px 0px 0px;
            font-weight: 600;
            font-size: 13px;
            
            color: $blackColor;

            @media (max-width:$sm) {
                margin: 0px;
                margin: 0px 0px 5px 0px;
            }
        }

        .eq-at-discipline-list-active {
            padding: 2px 12px;
            border: 1px solid $disciplineBorder;
            border-radius: 37px;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: $blueLink;
        }
    }

    .eq-tl-icons {
        /*border: 1px solid $orange;*/
        box-sizing: border-box;
        border-radius: 37px;
        height: 32px;
        width: 32px;
        padding: 4px 8.5px 0px 8.5px;
        cursor: pointer;

        .eq-tl-icon {
            height: 14px;

            .eq-tl-icon img {
                height: 12px;
                align-content: center;
            }
        }
    }

    .eq-p-profile-link {
        font-weight: normal;
        font-size: 13px;
        line-height: 31px;
        text-transform: capitalize;
        color: $blueLink;

        @media (max-width:$sm) {
            margin: 10px 0px 0px 0px;
        }
    }
}
