@import '../../shared/Vars.scss';

.eq-pf-board {
    background-color: $whiteColor;
    padding: 10px 35px 35px 35px;

    .ant-row {
        display: flex;
    }

    @media(max-width:$sm) {
        padding: 10px;
    }

    .eq-pf-heading-row {
        @include poppins-24-regular;

        color: $makoColor;
        padding: 0px 0px 0px 0px;
        margin: 10px 0px;
        border-bottom: 1px solid $orangeBorder;


        .eq-pf-heading-col {
            padding: 0px;
            margin: 0px;

            .eq-pf-heading {
                @include poppins-24-regular;
                display: flex;
                flex-direction: row;
                padding: 10px 0px 0px 0px;

                color: $makoColor;

                @media(max-width:$sm) {
                    padding: 5px 0px 0px 0px;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }

    .eq-pb-left {
        padding: 10px 0px;

        @media (max-width:$sm) {
            padding: 0px;
        }
    }
}
