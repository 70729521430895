@import '../../shared/Vars.scss';

.eq-collapse-panel {
    margin: 1px 0px 0 0;
    padding: 0px;
    border-radius: 2px;
    width: 100%;
    // border: 2px solid #F0F0F0;
    // border-top: 2px solid #F0F0F0;
    // border-bottom: 2px solid #F0F0F0;
    box-shadow: 1px 2px 4px #c6c6c6;

    .panel-header {
        @include poppins-14-semibold;
        border-bottom: 2px solid #F0F0F0;
        background: #FAFAFA;
        padding: 8px 16px;
        color: $darkBlack;
        font-style: normal;

        margin: 0px;
        align-content: center;
        align-items: center;

        @media(max-width:$sm) {
            line-height: 16px;
        }

        .arrow {
            // margin: 8px 0px;
            // text-align: right;
            // width: 5px;
            .icon {
                font-size: .95rem;
            }
        }
    }

    .panel-content {
        
    }
}



.eq-transparent-header {
    background: none;
    border-bottom: none;
}





.panel-inner {
    overflow: hidden;
    will-change: height;
    transition: .4s cubic-bezier(.65, .05, .36, 1);
    overflow-y: auto;
    // overflow: hidden;
    // transition: 0.8s height;
    transition-duration: 0.5s, 1s;
  transition-delay: 0, 1s;
//   max-height: 900px;
}


.active {
    height: 55rem;
}

.inactive {
    height: 0;
}

.panel__content {
    margin: 5px 25px 25px;
    font-size: 14px;
    color: grey;
    transition: .3s linear .18s;
}

.panel__content:not(:last-child) {
    margin-bottom: 3px
}
